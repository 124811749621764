import { debounce } from 'lodash';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';

import { defaultCarrotSearchFoamTreeParams } from '../../components/Dashboard/Explorer/consts';
import { highlightClusters } from '../../components/Dashboard/Explorer/helpers';
import { calculateMinMax, countClusters } from '../../components/Dashboard/helpers';
import eventLogger from '../../eventLogger';
import { getClusterGroups } from '../../helper/clusters';
import generateModel from '../../helper/generateModel';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { getClusterMeta } from '../../services/mixpanel/utils';
import {
    ExplorerLayout,
    IMosaicStore,
    LeafMosaicRecord,
    MosaicRecord,
    SideMenuType,
    WeightedLeafMosaicRecordWithParent,
} from '../../types';

import { ISetSelectedLayoutParams } from './types';

export class FoamTreeStore {
    rootStore: IMosaicStore;

    foamTreeChart: any | null = null;

    loader: any | null = null;

    @observable
    isLoaderVisible: boolean = false;

    @observable
    data: MosaicRecord[] | undefined = undefined;

    @observable
    selectedLayout: ExplorerLayout = ExplorerLayout.rectangular;

    @computed
    get clustersCount() {
        return countClusters(this.data);
    }

    @computed
    private get minMax(): { min: number; max: number } {
        let { min, max } = calculateMinMax(this.rootStore.config?.colorBy.attribute, this.data);
        const fieldsMetadata = this.rootStore.mosaicMetadata?.dataset?.fields_metadata;
        const colorByAttribute = this.rootStore.config?.colorBy.attribute;

        if (colorByAttribute && fieldsMetadata) {
            const constant_color_scale = fieldsMetadata[colorByAttribute]?.constant_color_scale;
            if (constant_color_scale) {
                min = constant_color_scale.min;
                max = constant_color_scale.max;
            }
        }

        return { min, max };
    }

    @computed
    get min(): number {
        const { min } = this.minMax;

        return min;
    }

    @computed
    get max(): number {
        const { max } = this.minMax;

        return max;
    }

    @computed
    get allLeafs(): LeafMosaicRecord[] {
        if (!this.data) {
            return [];
        }

        const leafs = this.data.reduce((result, record) => {
            result.push(...getClusterGroups(record));

            return result;
        }, [] as LeafMosaicRecord[]);

        return leafs;
    }

    @computed
    get generatedModel() {
        if (!this.data || !this.rootStore.config) {
            return [];
        }

        return generateModel(this.data, this.rootStore.config, this.min, this.max, this.selectedLayout);
    }

    @action.bound
    initChart() {
        this.foamTreeChart = new CarrotSearchFoamTree(
            defaultCarrotSearchFoamTreeParams(this.rootStore.clearSearchKeyword),
        );
        this.loader = new CarrotSearchFoamTree.loader(
            this.foamTreeChart,
            '<div id="treemapLoader" style="height: 100%;width: 100%;"></div>',
        );

        // Resize FoamTree on orientation change
        window.addEventListener('orientationchange', this.foamTreeChart.resize);

        // Resize on window size changes
        window.addEventListener(
            'resize',
            debounce(() => {
                this.foamTreeChart.set('pixelRatio', window.devicePixelRatio || 1);
                this.foamTreeChart.resize();
            }, 300),
        );

        this.foamTreeChart.on('groupClick', (e: any) => {
            if (!e.group || !e.group.cluster) {
                e.preventDefault();
            }
        });

        this.foamTreeChart.on('groupSelectionChanging', (e: any) => {
            if (!e.group || !e.group.cluster) {
                this.handleLeafClick(e.group);
            }

            if (e.selected) {
                this.handleLeafClick(e.group);
            }
        });

        // Highlight clusters when filters change
        reaction(
            () => this.rootStore.highlightedClusters,
            (clusters) => {
                highlightClusters(this.foamTreeChart, clusters);
            },
        );

        this.setIsDataLoading(true);
    }

    @action
    handleChartResize() {
        this.foamTreeChart.set('pixelRatio', window.devicePixelRatio || 1);
        this.foamTreeChart.resize();
    }

    @action.bound
    setIsDataLoading(isLoading: boolean) {
        if (isLoading) {
            this.showLoader();
        } else {
            this.hideLoader();
        }
    }

    @action.bound
    setData(data: MosaicRecord[]) {
        this.data = data;
    }

    @action.bound
    public setSelectedLayout(params: ISetSelectedLayoutParams) {
        const { layout, skipMetrics = false } = params;
        this.selectedLayout = layout;

        if (!this.data || !this.rootStore.config) {
            return;
        }

        switch (layout) {
            case ExplorerLayout.rectangular:
                this.foamTreeChart.set({
                    descriptionGroupSize: 0.0,
                    descriptionGroupMinHeight: 18,
                    layout: 'squarified',
                });
                break;
            case ExplorerLayout.polygonal:
                this.foamTreeChart.set({
                    descriptionGroupMinHeight: 30,
                    descriptionGroupSize: 0.15,
                    groupLabelColorThreshold: 0.6,
                    groupLabelLineHeight: 1,
                    groupLabelMaxTotalHeight: 1,
                    layoutByWeightOrder: false,
                    rainbowLightnessShift: 19,
                    layout: 'relaxed',
                });
                break;
        }

        if (layout === ExplorerLayout.rectangular) {
            this.foamTreeChart.set('dataObject', {
                groups: this.generatedModel,
            });

            this.hideLoader();
        } else {
            this.loader.started();

            if (this.rootStore.config) {
                setTimeout(() => {
                    if (!this.data || !this.rootStore.config) {
                        return;
                    }

                    this.loader.complete({
                        groups: this.generatedModel,
                    });
                    this.hideLoader();
                }, 20);
            }
        }

        if (!skipMetrics) {
            eventLogger.log('Changed Layout', { Name: layout, 'View Name': this.rootStore.currentView?.name });
            mixpanelService.track({
                event: MixpanelEvent.LAYOUT_CHANGE,
                meta: {
                    layout,
                },
            });
        }
    }

    @action.bound
    handleLeafClick(leaf: WeightedLeafMosaicRecordWithParent) {
        if (this.rootStore.sideMenuType !== SideMenuType.DetailsPane) {
            mixpanelService.track({
                event: MixpanelEvent.CLUSTER_CLICK,
                meta: getClusterMeta(
                    leaf,
                    toJS(this.rootStore.config?.groupBy ?? []),
                    toJS(this.rootStore.currentView?.id),
                ),
            });
        }

        this.rootStore.clustersStore.setCurrentLeaf(leaf);
        this.rootStore.setSideMenuType(SideMenuType.DetailsPane, leaf);
    }

    @action.bound
    showLoader() {
        this.isLoaderVisible = true;
    }

    @action.bound
    hideLoader() {
        this.isLoaderVisible = false;
    }

    constructor(rootStore: IMosaicStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }
}
