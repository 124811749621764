const DEFAULT_SIZE = 256;

/**
 * Get colors from a give palette
 *
 * @param paletteName
 * @returns color codes
 */
const getColors = (paletteName: string): string[] => {
    if (paletteName === 'tol-rainbow' || paletteName === 'rainbow') {
        return palette(paletteName, DEFAULT_SIZE).reverse();
    }

    const maxAllowed = palette.listSchemes(paletteName)[0].max;
    return palette(paletteName, Math.min(maxAllowed, DEFAULT_SIZE));
};

export default getColors;
