import React, { createContext } from 'react';
import isEqual from 'react-fast-compare';

import { debounce } from 'lodash';
import { action, computed, makeAutoObservable, observable, toJS } from 'mobx';

import { CUSTOM_OPERATORS } from '../components/Dashboard/filtersHelper';
import { trackSideMenuState } from '../components/Dashboard/helpers';
import eventLogger from '../eventLogger';
import { getClusterGroups } from '../helper/clusters';
import {
    CLUSTER_SIZE_FILTER_NAME,
    DEFAULT_MAX_CLUSTER_SIZE_INCREMENT,
    DEFAULT_MIN_CLUSTER_SIZE,
} from '../helper/consts';
import { getMarkedClustersFilter } from '../helper/getMarkedClustersFilter';
import getSearchInGroupsFilter from '../helper/getSearchInGroupsFilter';
import { mixpanelService } from '../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../services/mixpanel/types';
import {
    AllowedFilter,
    AppliedFilter,
    ClustersDateRangeResponse,
    CustomerView,
    ExplorerConfig,
    FilterOperators,
    IHighlightClustersFilter,
    IMosaicStore,
    LogicalOperator,
    MinClusterSizeResponse,
    MosaicMetadata,
    SideMenuType,
    UserDetails,
    WeightedLeafMosaicRecord,
    WeightedLeafMosaicRecordWithParent,
} from '../types';

import { ChangesOverTimeStore } from './changesOverTime/changes-over-time-store';
import { ClustersStore } from './clusters/clusters-store';
import { DirectAnswerStore } from './direct-answer/direct-answer-store';
import { FoamTreeStore } from './foamTree/foam-tree-store';

class MosaicStore implements IMosaicStore {
    foamTreeStore: FoamTreeStore;

    clustersStore: ClustersStore;

    changesOverTimeStore: ChangesOverTimeStore;

    directAnswerStore: DirectAnswerStore;

    @observable.ref
    foamTree: any | null = null;

    @observable
    filters: AppliedFilter[] | undefined = undefined;

    @observable
    currentView: CustomerView | undefined = undefined;

    @observable
    availableViews: CustomerView[] | undefined = undefined;

    @observable
    filterOperators: FilterOperators | undefined = undefined;

    @observable
    userDetails: UserDetails | undefined = undefined;

    @observable
    mosaicMetadata: MosaicMetadata | undefined = undefined;

    @observable
    config: ExplorerConfig | undefined = undefined;

    @observable
    filterOperatorsLoaded: boolean = false;

    @observable
    filterOperatorsIsLoading: boolean = false;

    @observable
    filtersLogicalOperator: LogicalOperator | undefined = undefined;

    @observable
    isDataLoading: boolean = true;

    @observable
    isFilterPopoverOpen: boolean = false;

    @observable
    minClusterSizeResponse: MinClusterSizeResponse | undefined = undefined;

    @observable
    selectedMinClusterSize: number | undefined = undefined;

    @observable
    clustersDateRange: ClustersDateRangeResponse | undefined = undefined;

    @observable
    sideMenuType: SideMenuType | undefined = undefined;

    @observable
    showInsufficientData: boolean = false;

    @observable
    viewItemsCount: number | undefined = undefined;

    @observable
    searchKeyword: string = '';

    @observable
    filterMarkedClustersEnabled: boolean = false;

    @action
    public setFoamTree(foamTree: any | null) {
        this.foamTree = foamTree;
    }

    @action
    public setSelectedMinClusterSize(newSelectedMinClusterSize: number | undefined) {
        this.selectedMinClusterSize = newSelectedMinClusterSize;
        mixpanelService.track({
            event: MixpanelEvent.GRANULARITY_CHANGE,
            meta: {
                min: this.minClusterSize,
                max: this.maxClusterSize,
                value: newSelectedMinClusterSize,
            },
        });
    }

    @action
    public setMinClusterSizeResponse(newMinClusterSizeResponse: MinClusterSizeResponse | undefined) {
        const isPreloaded =
            newMinClusterSizeResponse &&
            this.minClusterSizeResponse === undefined &&
            this.selectedMinClusterSize !== undefined &&
            this.selectedMinClusterSize >= newMinClusterSizeResponse.min &&
            (newMinClusterSizeResponse.max === undefined ||
                this.selectedMinClusterSize <= newMinClusterSizeResponse.max);

        if (
            !isPreloaded &&
            newMinClusterSizeResponse &&
            !isEqual(newMinClusterSizeResponse, this.minClusterSizeResponse)
        ) {
            // Updating the selected if the min cluster sizes were changed
            this.selectedMinClusterSize = newMinClusterSizeResponse.default;
        }

        this.minClusterSizeResponse = newMinClusterSizeResponse;
    }

    @action
    public setClustersDateRange(range?: ClustersDateRangeResponse) {
        this.clustersDateRange = range;
    }

    @action
    public setIsFilterPopoverOpen(newIsFilterPopoverOpen: boolean) {
        this.isFilterPopoverOpen = newIsFilterPopoverOpen;
        if (newIsFilterPopoverOpen) {
            mixpanelService.track({
                event: MixpanelEvent.FILTERS_OPEN,
            });
        } else {
            mixpanelService.track({
                event: MixpanelEvent.FILTERS_CLOSE_FILTERS,
                meta: {
                    filters: this.filters,
                },
            });
        }
    }

    @action
    public setFiltersLogicalOperator(newFiltersLogicalOperator: LogicalOperator | undefined) {
        this.filtersLogicalOperator = newFiltersLogicalOperator;
    }

    @action
    public applyFilters(
        updatedFilters: AppliedFilter[] | undefined,
        newFiltersLogicalOperator: LogicalOperator | undefined,
    ) {
        this.filters = updatedFilters;
        this.filtersLogicalOperator = newFiltersLogicalOperator;
    }

    @action
    public setIsDataLoading(isLoading: boolean) {
        this.isDataLoading = isLoading;
        this.foamTreeStore.setIsDataLoading(isLoading);
    }

    @action
    public resetConfig() {
        if (!this.mosaicMetadata || !this.config) {
            return;
        }

        const groupByValues =
            this.mosaicMetadata?.view?.group_by?.default ||
            [this.mosaicMetadata?.view?.group_by?.attributes[0]].filter(Boolean);

        this.setConfig({
            groupBy: groupByValues,
            sizeBy: {
                attribute: this.mosaicMetadata.view.size_by.attributes[0],
                fn: this.mosaicMetadata.view.size_by.fn,
            },
            colorBy: {
                attribute: this.mosaicMetadata.view.color_by.attributes[0],
                fn: this.mosaicMetadata.view.color_by.fn,
                palette: this.mosaicMetadata.view.color_by.palette,
            },
        });

        mixpanelService.track({
            event: MixpanelEvent.SETTINGS_RESET_CHANGES_CLICK,
        });
    }

    @action
    public setConfigWithFunction(
        newConfigFn: (config: ExplorerConfig | undefined) => ExplorerConfig | undefined,
        afterUpdateCallback: () => void = () => {},
    ) {
        const newConfig = newConfigFn(this.config);
        this.setConfig(newConfig);
        afterUpdateCallback();
    }

    @action
    public setConfig(newConfig: ExplorerConfig | undefined) {
        this.config = newConfig;
    }

    @action
    public setMosaicMetadata(newMosaicMetadata: MosaicMetadata | undefined) {
        this.mosaicMetadata = newMosaicMetadata;
    }

    @action
    public setFilters(updatedFilters: AppliedFilter[] | undefined) {
        this.filters = updatedFilters;
    }

    @action
    public setCurrentView(newCustomerView: CustomerView) {
        this.currentView = newCustomerView;
        this.directAnswerStore.resetCache();
    }

    @action
    public setAvailableViews(newAvailableViews: CustomerView[]) {
        this.availableViews = newAvailableViews;
    }

    @action
    public loadFilterOperators(newFilterOperators: FilterOperators) {
        this.filterOperators = { ...newFilterOperators, ...CUSTOM_OPERATORS };
        this.filterOperatorsLoaded = true;
        this.filterOperatorsIsLoading = false;
    }

    @action
    public startFilterOperatorsLoading() {
        this.filterOperatorsLoaded = false;
        this.filterOperatorsIsLoading = true;
    }

    @action
    public setUserDetails(newUserDetails: UserDetails) {
        this.userDetails = newUserDetails;
    }

    @action
    public logout() {
        this.userDetails = undefined;
        this.filters = undefined;
        this.mosaicMetadata = undefined;
        this.config = undefined;
        this.filterOperators = undefined;
        this.selectedMinClusterSize = undefined;
        this.clustersDateRange = undefined;
        this.filterOperatorsLoaded = false;
        this.filterOperatorsIsLoading = false;
    }

    @action
    public reset(cleanCurrentView: boolean = false) {
        this.filters = undefined;
        this.mosaicMetadata = undefined;
        this.config = undefined;
        this.selectedMinClusterSize = undefined;
        this.clustersDateRange = undefined;
        this.directAnswerStore.resetCache();
        this.clustersStore.reset();

        if (cleanCurrentView) {
            this.currentView = undefined;
        }
    }

    @action
    public switchMenuType = (type: SideMenuType) => {
        trackSideMenuState({
            item: this.clustersStore.currentLeaf,
            prevState: type,
            nextState: this.sideMenuType,
            selectedGroupByFields: toJS(this.config?.groupBy ?? []),
        });

        if (this.sideMenuType === type) {
            this.sideMenuType = undefined;
        } else {
            this.sideMenuType = type;
        }
    };

    @action
    public setSideMenuType = (type: SideMenuType | undefined, item?: WeightedLeafMosaicRecordWithParent) => {
        const prevType = this.sideMenuType;

        this.sideMenuType = type;

        trackSideMenuState({
            item: item ?? this.clustersStore.currentLeaf,
            prevState: prevType,
            nextState: this.sideMenuType,
            selectedGroupByFields: toJS(this.config?.groupBy ?? []),
        });
    };

    @action
    public setShowInsufficientData(show: boolean) {
        this.showInsufficientData = show;

        if (show) {
            mixpanelService.track({
                event: MixpanelEvent.DISPLAY_INSUFFICIENT_DATA_TO_DISPLAY,
                meta: { filters: this.filters },
            });
        }
    }

    @action
    public setViewItemsCount(viewItemsCount: number | undefined) {
        this.viewItemsCount = viewItemsCount;
    }

    public setSearchKeyword = debounce(
        action((keyword: string) => {
            const value = keyword.trim().toLowerCase();
            eventLogger.log('Searched Keyword', { Value: value, 'View Name': this.currentView?.name });
            mixpanelService.track({
                event: MixpanelEvent.SEARCH_BAR_SEARCH,
                meta: {
                    search_term: value,
                },
            });
            this.searchKeyword = keyword;
        }),
        300,
    );

    @action
    setFilterMarkedClustersEnabled = (enabled: boolean) => {
        this.filterMarkedClustersEnabled = enabled;
    };

    @action.bound
    public clearSearchKeyword() {
        this.searchKeyword = '';
    }

    @computed
    get allowedFilters(): AllowedFilter[] | undefined {
        return this.mosaicMetadata?.dataset.filter_fields;
    }

    @computed
    get shouldDisplayChangeOverTime(): boolean {
        return Boolean(this.mosaicMetadata?.dataset.created_date_field_name) && this.clustersDateRange !== undefined;
    }

    @computed
    get currentAppliedFiltersWithoutClusterSize(): AppliedFilter[] {
        return (this.filters || this.mosaicMetadata?.view.filters || []).filter(
            (f) => f.field !== CLUSTER_SIZE_FILTER_NAME,
        );
    }

    @computed
    get minClusterSize(): number {
        return this.minClusterSizeResponse?.min || DEFAULT_MIN_CLUSTER_SIZE;
    }

    @computed
    get maxClusterSize(): number {
        return this.minClusterSizeResponse?.max || this.minClusterSize * DEFAULT_MAX_CLUSTER_SIZE_INCREMENT;
    }

    @computed
    get highlightedClusters(): WeightedLeafMosaicRecord[] {
        if (!this.filterMarkedClustersEnabled && this.searchKeyword === '') {
            return [];
        }

        const filters: IHighlightClustersFilter[] = [
            getSearchInGroupsFilter(this.searchKeyword, this.mosaicMetadata?.dataset.search_fields ?? []),
            getMarkedClustersFilter(this.filterMarkedClustersEnabled, this.foamTreeStore.allLeafs),
        ];

        const leafs: WeightedLeafMosaicRecord[] = getClusterGroups(this.foamTreeStore.foamTreeChart.get('dataObject'));

        return filters.reduce((prevFilterResult, filter) => {
            return filter(prevFilterResult);
        }, leafs);
    }

    constructor() {
        this.foamTreeStore = new FoamTreeStore(this);
        this.clustersStore = new ClustersStore(this);
        this.changesOverTimeStore = new ChangesOverTimeStore(this);
        this.directAnswerStore = new DirectAnswerStore();

        makeAutoObservable(this);
    }
}

export default MosaicStore;

export const MosaicStoreContext = createContext<MosaicStore | null>(null);

interface UnknownComponentProps extends React.HTMLAttributes<unknown> {}

const store = new MosaicStore();

export const MosaicStoreContextProvider: React.FC<UnknownComponentProps> = (props) => {
    return <MosaicStoreContext.Provider value={store}>{props.children}</MosaicStoreContext.Provider>;
};
