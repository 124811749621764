import { redirect } from 'react-router-dom';

import axios from 'axios';
import { action, computed, flow, makeObservable, observable } from 'mobx';

import api from '../../api';
import { generateFiltersClusterHierarchy } from '../../components/Dashboard/SideMenu/DetailsPane/helper';
import { REVISION_URL_PARAM_NAME, routes } from '../../components/consts';
import eventLogger from '../../eventLogger';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { getClusterMeta } from '../../services/mixpanel/utils';
import {
    ClusterItemsResponse,
    ClusterItemsSortBy,
    IMosaicStore,
    isDefined,
    WeightedLeafMosaicRecordWithParent,
} from '../../types';

import { getSortByProviders } from './filters/cluster-sort-type-filters';

export class ClustersStore {
    rootStore: IMosaicStore;

    @observable.ref
    currentLeaf: WeightedLeafMosaicRecordWithParent | null = null;

    @observable
    itemsLoading: boolean = false;

    @observable
    itemsSortOrder: ClusterItemsSortBy = ClusterItemsSortBy.RELEVANCE;

    @observable
    itemsCollapsed: boolean = false;

    @computed
    get isSortAvailable(): boolean {
        return Boolean(this.rootStore.mosaicMetadata?.dataset.created_date_field_name);
    }

    @computed
    get availableItemsSortTypes(): ClusterItemsSortBy[] {
        return getSortByProviders()
            .map((provider) => provider(this.rootStore.mosaicMetadata))
            .filter(isDefined);
    }

    @computed
    get selectedClusterName(): string {
        return this.currentLeaf?.label ?? '';
    }

    @computed
    get selectedClusterItemsCount(): number {
        return this.currentLeaf?.cluster.itemsCount ?? 0;
    }

    @action.bound
    setCurrentLeaf(leaf: WeightedLeafMosaicRecordWithParent | null) {
        this.currentLeaf = leaf;

        if (leaf) {
            this.loadClusterItems();
            const clusterName = leaf.cluster;
            const sizeByVal = leaf.cluster.dynamicAttributes[this.rootStore.config?.sizeBy.attribute || ''];
            const extraData = { Name: clusterName, Size: sizeByVal, 'View Name': this.rootStore.currentView?.name };
            eventLogger.log('Clicked Cluster', extraData);
        }
    }

    @action.bound
    setItemsSortOrder(order: ClusterItemsSortBy) {
        const orderChanged = this.itemsSortOrder !== order;
        this.itemsSortOrder = order;

        if (orderChanged) {
            this.loadClusterItems();

            if (this.currentLeaf) {
                mixpanelService.track({
                    event: MixpanelEvent.DETAIL_PANEL_CHANGE_SORT_BY,
                    meta: {
                        ...getClusterMeta(this.currentLeaf, this.rootStore.config?.groupBy ?? []),
                        sort_by: order,
                    },
                });
            }
        }
    }

    @action.bound
    toggleItemsCollapsed() {
        this.itemsCollapsed = !this.itemsCollapsed;

        if (this.currentLeaf) {
            mixpanelService.track({
                event: this.itemsCollapsed
                    ? MixpanelEvent.DETAIL_PANEL_COLLAPSE_ALL_ITEMS
                    : MixpanelEvent.DETAIL_PANEL_EXPAND_ALL_ITEMS,
                meta: getClusterMeta(this.currentLeaf, this.rootStore.config?.groupBy ?? []),
            });
        }
    }

    @action
    setCurrentLeafMarked(marked: boolean) {
        if (!this.currentLeaf || !this.rootStore.foamTreeStore.data) {
            return;
        }

        const leafToUpdate = this.rootStore.foamTreeStore.allLeafs.filter(
            (leaf) => leaf.label === this.currentLeaf?.label,
        );

        leafToUpdate.forEach((leaf) => (leaf.is_marked = marked));
    }

    loadClusterItems = flow(function* (this: ClustersStore) {
        if (!this.rootStore.currentView || !this.currentLeaf) {
            return;
        }

        const { itemsCount, detailPaneItems, sortedBy } = this.currentLeaf.cluster;

        // Nothing to load we have the items for the current cluster
        if (itemsCount && detailPaneItems && this.itemsSortOrder === sortedBy) {
            return;
        }

        this.itemsLoading = true;

        const searchParams = new URLSearchParams(document.location.search);

        try {
            const response: ClusterItemsResponse = yield api.getClusterItems({
                viewId: this.rootStore.currentView.id,
                itemIds: this.currentLeaf.cluster.detailPaneItemIds,
                leftoverItemIds: this.currentLeaf.cluster.leftoverItemIds,
                filtersClusterHierarchy: generateFiltersClusterHierarchy(
                    this.currentLeaf,
                    this.rootStore.config?.groupBy ?? [],
                ),
                filters: this.rootStore.filters ?? [],
                revision: searchParams.get(REVISION_URL_PARAM_NAME),
                sortBy: this.itemsSortOrder,
            });

            // Store the loaded info within the chart data for cache purposes
            this.currentLeaf.cluster.detailPaneItems = response.items;
            this.currentLeaf.cluster.executiveSummaryCustomerName = response.executive_summary_customer_name;
            this.currentLeaf.cluster.executiveSummaryRequestId = response.executive_summary_request_id;
            this.currentLeaf.cluster.sortedBy = this.itemsSortOrder;

            // Re-trigger observables since `currentLeaf` is tracked by ref
            this.currentLeaf = {
                ...this.currentLeaf,
            };
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                redirect(routes.home);
            }

            throw error;
        } finally {
            this.itemsLoading = false;
        }
    });

    @action.bound
    reset() {
        this.currentLeaf = null;
        this.itemsSortOrder = ClusterItemsSortBy.RELEVANCE;
        this.itemsLoading = false;
        this.itemsCollapsed = false;
    }

    constructor(rootStore: IMosaicStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }
}
