import React from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';

import { SKELETON_COLOR } from '../../../../consts';
import { ClustersDateRangeResponse } from '../../../../types';

import { DAY_AND_MONTH_FORMAT, DAY_MONTH_AND_YEAR_FORMAT } from './consts';

import style from './DateRangeLabel.module.scss';

interface DateRangeLabelProps {
    isDataLoading: boolean;
    dateRange?: ClustersDateRangeResponse;
}

const DateRangeLabel = (props: DateRangeLabelProps) => {
    const { dateRange, isDataLoading } = props;
    const { t } = useTranslation();

    if (isDataLoading) {
        return <Skeleton animation="wave" variant="text" sx={{ backgroundColor: SKELETON_COLOR }} />;
    }

    if (!dateRange) {
        return <span className={style.text}>{t('common.na')}</span>;
    }

    const start = moment(dateRange.start);
    const end = moment(dateRange.end);
    const startDateFormat = start.year() !== end.year() ? DAY_MONTH_AND_YEAR_FORMAT : DAY_AND_MONTH_FORMAT;

    return (
        <span className={style.text}>
            {start.format(startDateFormat)} - {end.format(DAY_MONTH_AND_YEAR_FORMAT)}
        </span>
    );
};

export default DateRangeLabel;
