import { GetMarkedEventDescriptionCallback } from './stores/changesOverTime/helpers/formatters';
import { AggregatedData, AggregatedMarkedPoints } from './stores/changesOverTime/types';
import type { ClustersStore } from './stores/clusters/clusters-store';
import { FoamTreeStore } from './stores/foamTree/foam-tree-store';

export const isDefined = <T>(v: T): v is NonNullable<T> => v != null;

export enum FieldType {
    continuous = 'continuous',
    discrete = 'discrete',
}

export type Formatters = 'numberToStringWithUnit' | 'identity';
export type Preprocessors = 'stringToNumber';

interface ConstantColorScale {
    min: number;
    max: number;
}

export interface Field {
    type: FieldType;
    formatter: Formatters;
    ordering?: string[];
    preprocessing?: Preprocessors;
    unit?: string;
    decimals?: number;
}

export interface ExplorerConfig {
    groupBy: string[];
    sizeBy: {
        attribute: string;
        fn: string; // TODO change to enum
    };
    colorBy: {
        attribute: string;
        fn: string; // TODO change to enum
        palette: string; // TODO change to enum
    };
}

export enum ExplorerLayout {
    polygonal = 'polygonal',
    rectangular = 'rectangular',
}

export interface CustomerView {
    id: string;
    name: string;
    logo: string;
}

export enum SemanticType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    ARRAY = 'ARRAY',
    BOOL = 'BOOL',
    LONG_STRING = 'LONG_STRING',
}

export enum FieldDisplay {
    PERCENTAGE = 'PERCENTAGE',
}

export interface FieldsMetadata {
    [fieldName: string]: {
        semantic_type: SemanticType;
        display?: FieldDisplay;
        display_name?: string;
        constant_color_scale?: ConstantColorScale;
    };
}

export interface AppliedFilter {
    field: string;
    operator: string;
    value: string | number | string[];
    should_intersect_on_multiple_values?: boolean;
}

export enum LogicalOperator {
    intersection = 'intersection',
    union = 'union',
}

export interface AllowedFilter {
    field: string;
    min?: number;
    max?: number;
}

export interface ClusterFieldNamesMapping {
    [name: string]: string;
}

export interface MosaicMetadata {
    dataset: {
        fields_metadata: FieldsMetadata;
        size_by_fields: string[];
        group_by_fields: string[];
        color_by_fields: string[];
        filter_fields: AllowedFilter[];
        search_fields: string[];
        cluster_field_names?: ClusterFieldNamesMapping;
        created_date_field_name?: string;
    };

    view: {
        default_layout?: ExplorerLayout;
        color_by: {
            attributes: string[];
            fn: string; // TODO: Change to enum
            palette: string; // TODO: Change to enum
        };
        size_by: {
            attributes: string[];
            fn: string; // TODO: Change to enum
        };
        group_by: {
            attributes: string[];
            levels: 1;
            default?: string[];
        };
        filters: AppliedFilter[];
        filters_logical_operator?: LogicalOperator;
    };
}

export interface MosaicRecord {
    label: string;
    groups: (MosaicRecord | LeafMosaicRecord)[] | null;
}

export enum RecordHeaderType {
    KeyValue = 'key-value',
}

export interface RecordHeader {
    type: RecordHeaderType;
    values: {
        key: string;
        value: string | number;
        description?: string;
    };
}

export interface DetailPaneItem {
    _id: string;
    [key: string]: string;
}

export interface LeafMosaicRecord extends Omit<MosaicRecord, 'groups'> {
    cluster: {
        dynamicAttributes: { [key: string]: string | number };
        headers: RecordHeader[];
        detailPaneItems?: DetailPaneItem[];
        itemsCount?: number;
        detailPaneItemIds: string[];
        leftoverItemIds: string[];
        executiveSummaryCustomerName?: string | null;
        executiveSummaryRequestId?: string | null;
        sortedBy?: ClusterItemsSortBy;
    };
    is_marked: boolean;
}

export interface WeightedMosaicRecord extends MosaicRecord {
    weight: number;
    groups: (WeightedMosaicRecord | WeightedLeafMosaicRecord)[] | null;
}

export interface WeightedLeafMosaicRecord extends LeafMosaicRecord {
    weight: number;
}

export interface ChangesOverTimeRecord {
    date: string;
    count: number;
}

export interface WeightedMosaicRecordWithParent extends WeightedMosaicRecord {
    parent?: WeightedMosaicRecordWithParent;
}

export interface WeightedLeafMosaicRecordWithParent extends WeightedLeafMosaicRecord {
    parent?: WeightedMosaicRecordWithParent;
}

export interface FilterOperators {
    [operatorName: string]: {
        semantic_types: SemanticType[];
    };
}

export function isLeafMosaicRecord(object: any): object is LeafMosaicRecord {
    return 'cluster' in object && object.cluster;
}

export function isWeightedLeafMosaicRecord(object: any): object is WeightedLeafMosaicRecord {
    return 'cluster' in object && object.cluster && 'weight' in object;
}

export function isWeightedMosaicRecord(object: any): object is WeightedMosaicRecord {
    return 'groups' in object && object.groups && Array.from(object.groups) && 'weight' in object;
}

export function isMosaicRecord(object: any): object is MosaicRecord {
    return 'groups' in object && object.groups && Array.from(object.groups);
}

export function isWeightedMosaicRecordWithParent(object: any): object is WeightedMosaicRecordWithParent {
    return 'groups' in object && object.groups && Array.from(object.groups) && 'weight' in object;
}

export function isWeightedLeafMosaicRecordWithParent(object: any): object is WeightedLeafMosaicRecordWithParent {
    return 'cluster' in object && object.cluster && 'weight' in object;
}

export enum SideMenuType {
    Config = 'Config',
    DetailsPane = 'DetailsPane',
}

export interface UserDetails {
    uid: string;
    name: string;
    email: string;
    user_org: string;
}

export interface FiltersClusterHierarchy {
    [key: string]: string;
    cluster: string;
}

export enum ClusterItemsSortBy {
    RELEVANCE = 'relevance',
    DATE = 'date',
}

export interface ClusterItemsRequestParams {
    viewId: string;
    itemIds: string[];
    leftoverItemIds: string[];
    filtersClusterHierarchy: FiltersClusterHierarchy;
    filters: AppliedFilter[];
    revision: string | null;
    sortBy: ClusterItemsSortBy;
}

export interface ClusterItemsResponse {
    items: DetailPaneItem[];
    executive_summary_customer_name: string | null;
    executive_summary_request_id: string | null;
}

export interface MappedAllowedFilters {
    [field: string]: AllowedFilter;
}

export type OperatorsPerSemanticType = Record<SemanticType, string[]>;

export interface AutocompleteResponse {
    values: [{ value: string }];
}

export interface MinClusterSizeResponse {
    min: number;
    max?: number;
    default: number;
}

export interface ClustersResponse {
    clusters: MosaicRecord[];
    min_cluster_size?: MinClusterSizeResponse;
    date_range?: ClustersDateRangeResponse;
    view_tickets_count: number;
}

export interface ClustersDateRangeResponse {
    start: string;
    end: string;
}

export interface ChangesOverTimeResponse {
    dates: ChangesOverTimeRecord[];
    markers: IAPIMarkedEvent[];
}

export enum MetricType {
    LOGIN_STARTED = 'login_started',
    LOGIN_COMPLETED = 'login_completed',
    LOGIN_FAILED = 'login_failed',
}

export interface UsageMetricBody {
    client_type: string;
    client_version: string;
    event_type: MetricType;
    data?: MetricExtraData;
}
export interface MetricExtraData {
    [key: string]: string;
}

export enum DirectAnnotationType {
    ThumbsUp = 'ThumbsUp',
    ThumbsDown = 'ThumbsDown',
    CopyAnswer = 'CopyAnswer',
    ShowMore = 'ShowMore',
}

export interface AnnotateDirectAnswerRequestParams {
    customerProjectId: string;
    questionId: string;
    annotationType: DirectAnnotationType;
    feedback?: string | null;
}

export interface GetDirectAnswerRequestParams {
    customer_project_id: string;
    question_id: string;
}

export enum DirectAnswerStatus {
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export enum ActionType {
    SEARCH = 'SEARCH',
    SUMMARIZE = 'SUMMARIZE',
    REPHRASE = 'REPHRASE',
}

export interface DirectAnswer {
    status: DirectAnswerStatus;
    answer: string;
    is_answerable: boolean | null;
    action_type: ActionType | null;
}

export interface IMosaicStore {
    isDataLoading: boolean;
    config: ExplorerConfig | undefined;
    currentView: CustomerView | undefined;
    filters: AppliedFilter[] | undefined;
    mosaicMetadata: MosaicMetadata | undefined;
    clustersStore: ClustersStore;
    foamTreeStore: FoamTreeStore;
    sideMenuType: SideMenuType | undefined;
    highlightedClusters: WeightedLeafMosaicRecord[];

    setSideMenuType: (type: SideMenuType, leaf?: WeightedLeafMosaicRecordWithParent) => void;
    clearSearchKeyword: () => void;
}

export type ClusterItemsSortTypeProvider = (metadata: MosaicMetadata | undefined) => ClusterItemsSortBy | null;

export interface IGetChartSeriesParams {
    data: AggregatedData;
    markedEvents: AggregatedMarkedPoints;
    getMarkedEventDescription: GetMarkedEventDescriptionCallback;
}

export interface IAPIMarkedEventDate {
    year: number;
    month: number;
    day: number;
}

export interface IAPIMarkedEvent extends IAPIMarkedEventDate {
    description: string;
}

export interface IEditMarkedEventPointParams {
    viewId: string;
    cluster: string;
    marker: IAPIMarkedEvent;
}

export interface IDeleteMarkedEventPointParams {
    viewId: string;
    cluster: string;
    marker: Omit<IAPIMarkedEvent, 'description'>;
}

export type IHighlightClustersFilter = (leafs: WeightedLeafMosaicRecord[]) => WeightedLeafMosaicRecord[];
