import React from 'react';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
    return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #D4D4D8',
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            border: '1px solid #D4D4D8',
        },

        color: theme.palette.common.white,
    },
}));

export default LightTooltip;
