import React from 'react';

import CodeIcon from '@mui/icons-material/Code';
import EventIcon from '@mui/icons-material/Event';
import FlakyIcon from '@mui/icons-material/Flaky';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import SubjectIcon from '@mui/icons-material/Subject';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

import { SemanticType } from '../../../../../types';

import style from './TypeIcon.module.scss';

interface TypeIconProps {
    semanticType: SemanticType | undefined;
}

const TypeIcon = (props: TypeIconProps) => {
    const { semanticType } = props;

    let Icon = CodeIcon;
    if (semanticType === SemanticType.STRING) {
        Icon = StickyNote2OutlinedIcon;
    } else if (semanticType === SemanticType.DATE) {
        Icon = EventIcon;
    } else if (semanticType === SemanticType.BOOL) {
        Icon = FlakyIcon;
    } else if (semanticType === SemanticType.NUMBER) {
        Icon = PinOutlinedIcon;
    } else if (semanticType === SemanticType.ARRAY) {
        Icon = ViewListOutlinedIcon;
    } else if (semanticType === SemanticType.LONG_STRING) {
        Icon = SubjectIcon;
    }

    return (
        <div className={style.icon}>
            <Icon sx={{ color: '#61A6FA', fontSize: 20, margin: '2px' }} />
        </div>
    );
};

export default TypeIcon;
