import React from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { SKELETON_COLOR } from '../../../../../../../consts';
import { useRootStore } from '../../../../../../../hooks/root-store';

import styles from './ChangesOverTimeTitle.module.scss';

const ChangesOverTimeTitleComponent: React.FC = () => {
    const { changesOverTimeStore: store } = useRootStore();
    const { t } = useTranslation();

    const renderTitle = () => {
        if (store.loading) {
            return <Skeleton animation="wave" variant="text" sx={{ width: 150, backgroundColor: SKELETON_COLOR }} />;
        }

        return <Typography variant="widget-header">{t('changesOverTime.widgetTitle')}</Typography>;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{renderTitle()}</div>
        </div>
    );
};

export const ChangesOverTimeTitle = observer(ChangesOverTimeTitleComponent);
