import { isMosaicRecord, LeafMosaicRecord, MosaicRecord } from '../types';

/**
 * Flattening the clusters
 *
 * @param data
 * @returns
 */
const flatClusters = (data: (LeafMosaicRecord | MosaicRecord)[]) => {
    let flatten = data.flatMap((r) => (isMosaicRecord(r) ? r.groups : r));
    let haveMoreLayers = flatten.some((r) => (r as MosaicRecord).groups);

    while (haveMoreLayers) {
        flatten = flatten.flatMap((r) => (isMosaicRecord(r) ? r.groups : r));
        haveMoreLayers = flatten.some((r) => (r as MosaicRecord).groups);
    }
    return flatten;
};

export default flatClusters;
