export enum MixpanelEvent {
    LOG_IN_MOSAIC = 'log_in_mosaic',
    LOG_OUT_MOSAIC = 'log_out_mosaic',
    VIEW_CHANGE = 'view_change',
    CLUSTER_FOCUS = 'cluster_focus',
    CLUSTER_UNFOCUS = 'cluster_unfocus',
    CLUSTER_CLICK = 'cluster_click',
    FILTERS_OPEN = 'filters_open',
    FILTERS_APPLY_FILTER = 'filters_apply_filter',
    FILTERS_CLEAR_ALL_FILTERS = 'filters_clear_all_filters',
    FILTERS_CLOSE_FILTERS = 'filters_close_filters',
    DISPLAY_NO_DATA_TO_DISPLAY = 'display_no_data_to_display',
    DISPLAY_INSUFFICIENT_DATA_TO_DISPLAY = 'display_insufficient_data_to_display',
    GROUP_BY_CHANGE_ATTRIBUTE = 'group_by_change_attribute',
    SIZE_BY_CHANGE_FUNCTION = 'size_by_change_function',
    SIZE_BY_CHANGE_ATTRIBUTE = 'size_by_change_attribute',
    COLOR_BY_CHANGE_FUNCTION = 'color_by_change_function',
    COLOR_BY_CHANGE_PALETTE = 'color_by_change_palette',
    COLOR_BY_CHANGE_ATTRIBUTE = 'color_by_change_attribute',
    LAYOUT_CHANGE = 'layout_change',
    GRANULARITY_CHANGE = 'granularity_change',
    SETTINGS_RESET_CHANGES_CLICK = 'settings_reset_changes_click',
    DETAIL_PANEL_OPEN = 'detail_panel_open',
    DETAIL_PANEL_CLOSE = 'detail_panel_close',
    DETAIL_PANEL_CHANGE_OVER_TIME_EXPEND = 'detail_panel_change_over_time_expend',
    DETAIL_PANEL_CHANGE_OVER_TIME_COLLAPSE = 'detail_panel_change_over_time_collapse',
    DETAIL_PANEL_EXECUTIVE_SUMMARY_EXPEND = 'detail_panel_executive_summary_expend',
    DETAIL_PANEL_EXECUTIVE_SUMMARY_COLLAPSE = 'detail_panel_executive_summary_collapse',
    DETAIL_PANEL_EXPAND_ALL_ITEMS = 'detail_panel_expand_all_items',
    DETAIL_PANEL_COLLAPSE_ALL_ITEMS = 'detail_panel_collapse_all_items',
    DETAIL_PANEL_EXPAND_ITEM = 'detail_panel_expand_item',
    DETAIL_PANEL_COLLAPSE_ITEM = 'detail_panel_collapse_item',
    DETAIL_PANEL_CHANGE_SORT_BY = 'detail_panel_change_sort_by',
    SETTINGS_PANEL_OPEN = 'settings_panel_open',
    SETTINGS_PANEL_CLOSE = 'SETTINGS_PANEL_CLOSE',
    SEARCH_BAR_SEARCH = 'search_bar_search',
    CHANGE_OVER_TIME_HOVER = 'change_over_time_hover',
    SHOW_MORE_IN_DIRECT_ANSWER = 'show_more_in_direct_answer',
    SHOW_LESS_IN_DIRECT_ANSWER = 'show_less_in_direct_answer',
    DIRECT_ANSWER_COPY = 'direct_answer_copy',
    DIRECT_ANSWER_THUMB_UP = 'direct_answer_thumb_up',
    DIRECT_ANSWER_THUMB_DOWN = 'direct_answer_thumb_down',
}

export interface MixpanelEventProperties {
    [key: string]: any;
}

export interface ITrackEventData {
    event: MixpanelEvent;
    meta?: MixpanelEventProperties;
}
