import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import LightTooltip from '../../common/LightTooltip';

interface MenuItemButtonProps {
    Icon: React.ComponentType<any>;
    isSelected: boolean;
    tooltipText: string;
    onClick: () => void;
    disabled?: boolean;
}
const MenuItemButton = (props: MenuItemButtonProps) => {
    const { Icon, isSelected, onClick, disabled, tooltipText } = props;

    return (
        <LightTooltip title={tooltipText} arrow placement="left">
            <Box sx={{ ml: '15px', mt: '20px' }}>
                <Button
                    color={isSelected ? 'neutralSelected' : 'neutral'}
                    variant="outlined"
                    sx={{ p: '12px', minWidth: 'unset' }}
                    disabled={disabled}
                    onClick={onClick}
                >
                    <Icon xs={{ fontSize: 24 }} />
                </Button>
            </Box>
        </LightTooltip>
    );
};

export default MenuItemButton;
