import EChartsReactCore from 'echarts-for-react/lib/core';

import { AggregatedMarkedPoints, ChartOption } from '../types';

const GUIDE_LINE_ID = 'guid-line';

interface IGetGuideLineOptionParams {
    chart: EChartsReactCore | null;
    coordinates: [number, number] | undefined;
    markedEvents: AggregatedMarkedPoints;
}

export const getGuideLineOption = (params: IGetGuideLineOptionParams): { graphic: ChartOption['graphic'] } => {
    const { chart, coordinates, markedEvents } = params;

    if (!chart || !coordinates) {
        return {
            graphic: {
                id: GUIDE_LINE_ID,
                type: 'line',
                invisible: true,
            },
        };
    }

    const [gridX] = coordinates;

    if (gridX > markedEvents.length - 1) {
        return { graphic: [] };
    }

    const [x, maxY] = chart.getEchartsInstance().convertToPixel('grid', [gridX, 100]);
    const [, minY] = chart.getEchartsInstance().convertToPixel('grid', [gridX, 0]);

    const lineOption: ChartOption['graphic'] = [];
    const solid = Boolean(markedEvents[gridX].length);

    if (solid) {
        lineOption.push({
            id: `${GUIDE_LINE_ID}-stroke`,
            type: 'line',
            invisible: false,
            shape: {
                x1: x,
                y1: minY,
                x2: x,
                y2: maxY,
            },
            style: {
                lineWidth: 7,
                stroke: 'rgba(97, 166, 250, 0.10)',
            },
            silent: true,
            zlevel: 0,
        });
    }

    lineOption.push({
        id: `${GUIDE_LINE_ID}`,
        type: 'line',
        invisible: false,
        shape: {
            x1: x,
            y1: minY,
            x2: x,
            y2: maxY,
        },
        style: {
            lineWidth: 1,
            stroke: '#61A6FA',
            lineDash: solid ? undefined : [4, 4],
        },
        silent: true,
        zlevel: 0,
    });

    return {
        graphic: lineOption,
    };
};
