import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import { stripHtml } from 'string-strip-html';

import './Cards/BaseCard/BaseCard';

import style from './DetailValue.module.scss';

interface DetailValueProps {
    value?: string;
}

const sanitizeValue = (value: string): string => {
    let strippedValue = stripHtml(`${value}`.trim()).result.trim().replaceAll('--', '\n');

    if (strippedValue[0] === '.') {
        // Remove the first dot
        strippedValue = strippedValue.substring(1).trim();
    }

    return strippedValue;
};

const DetailValue: React.FC<DetailValueProps> = (props) => {
    const { value } = props;
    const { t } = useTranslation();

    if (!value) {
        return <span className={style.naValue}>{t('common.na')}</span>;
    }

    return (
        <span className={style.detailValue}>
            <ReactMarkdown children={sanitizeValue(value)} remarkPlugins={[remarkGfm]} />
        </span>
    );
};

export default DetailValue;
