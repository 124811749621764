import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const fontFamily = 'DM Sans,Roboto,Helvetica,Arial,sans-serif';

const theme = createTheme({
    typography: { fontFamily },
    palette: {
        base: {
            50: '#1A1A1A',
            700: alpha('#1A1A1A', 0.7),
        },
        neutral: {
            main: '#5E6A7D',
            contrastText: '#fff',
        },
        neutralSelected: {
            main: '#43A8E1',
            contrastText: '#fff',
        },
        activeBlue: { main: '#0393E4' },
        mainBlue: { main: '#2463EB', light: '#61A6FA', contrastText: '#fff' },
        buttonGray: { main: '#FAFAF9', contrastText: '#404B5C' },
        lightGray: {
            main: '#f6f6f6',
            contrastText: '#fff',
        },
        darkGray: { main: '#404B5C', contrastText: '#fff' },
        midGray: { main: '#5E6A7D', contrastText: '#fff' },
        red: {
            A100: '#FF8A80',
            A200: '#FF5252',
        },
        widgetTitle: {
            main: 'darkGray.main',
            light: '#8999B1',
        },
    },
    components: {
        MuiCard: {
            variants: [
                {
                    props: { variant: 'widget' },
                    style: {
                        backgroundColor: '#FFF',
                        borderRadius: '0',
                        padding: '10px',
                    },
                },
            ],
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => {
                    return {
                        transition: theme.transitions.create(['transform', 'color'], {
                            duration: theme.transitions.duration.shortest,
                        }),
                    };
                },
            },
            variants: [
                {
                    props: { size: 'collapse' },
                    style: {
                        height: '20px',
                        width: '20px',
                    },
                },
            ],
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'widget' },
                    style: {
                        marginTop: '10px',
                    },
                },
                {
                    props: { variant: 'chart-button' },
                    style: {
                        height: '20px',
                        borderColor: '#D0D0D0',
                        borderRightWidth: '2px',
                        margin: '0 5px',
                    },
                },
            ],
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'widget-header' },
                    style: {
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                    },
                },
                {
                    props: { variant: 'success-header' },
                    style: {
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '22px',
                        color: '#566466',
                    },
                },
            ],
        },
        MuiSvgIcon: {
            variants: [
                {
                    props: { fontSize: 'chart-button' },
                    style: {
                        height: '16px',
                        width: '16px',
                        color: 'inherit',
                    },
                },
            ],
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'mosaic-primary' },
                    style: ({ theme }) => ({
                        minHeight: '30px',
                        minWidth: '80px',
                        backgroundColor: theme.palette.mainBlue.main,
                        color: theme.palette.mainBlue.contrastText,
                        fontWeight: 700,
                        padding: '8px 16px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: theme.palette.mainBlue.light,
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.mainBlue.contrastText,
                        },
                    }),
                },
                {
                    props: { variant: 'mosaic-secondary' },
                    style: ({ theme }) => ({
                        minHeight: '30px',
                        minWidth: '80px',
                        backgroundColor: alpha(theme.palette.base['50'] ?? '', 0.05),
                        color: theme.palette.neutral.main,
                        fontWeight: 700,
                        padding: '8px 16px',
                        textTransform: 'none',

                        '&:hover': {
                            backgroundColor: alpha(theme.palette.base['50'] ?? '', 0.1),
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.mainBlue.contrastText,
                        },
                    }),
                },
                {
                    props: { variant: 'mosaic-transparent' },
                    style: ({ theme }) => ({
                        minHeight: '30px',
                        minWidth: '80px',
                        backgroundColor: 'transparent',
                        color: theme.palette.neutral.main,
                        fontWeight: 700,
                        padding: '8px 16px',
                        textTransform: 'none',

                        '&:hover': {
                            backgroundColor: alpha(theme.palette.base['50'] ?? '', 0.02),
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.mainBlue.contrastText,
                        },
                    }),
                },
                {
                    props: { variant: 'mosaic-warning' },
                    style: ({ theme }) => ({
                        minHeight: '30px',
                        minWidth: '80px',
                        backgroundColor: theme.palette.red.A200,
                        color: theme.palette.neutral.contrastText,
                        fontWeight: 700,
                        padding: '8px 16px',
                        textTransform: 'none',

                        '&:hover': {
                            backgroundColor: theme.palette.red.A100,
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.mainBlue.contrastText,
                        },
                    }),
                },
                {
                    props: { variant: 'feedback-primary' },
                    style: ({ theme }) => ({
                        minHeight: '40px',
                        minWidth: '128px',
                        backgroundColor: theme.palette.mainBlue.main,
                        color: theme.palette.mainBlue.contrastText,
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: theme.palette.mainBlue.light,
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.mainBlue.contrastText,
                        },
                    }),
                },
                {
                    props: { variant: 'feedback-secondary' },
                    style: ({ theme }) => ({
                        minHeight: '40px',
                        minWidth: '128px',
                        backgroundColor: theme.palette.buttonGray.main,
                        color: theme.palette.buttonGray.contrastText,
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: theme.palette.buttonGray.light,
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.buttonGray.contrastText,
                        },
                    }),
                },
            ],
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px',
                },
            },
        },
    },
});

export default theme;
