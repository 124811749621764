import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../../hooks/root-store';
import { ClusterItemsSortBy } from '../../../../../../types';
import LightTooltip from '../../../../../common/LightTooltip';

import { ReactComponent as CheckIcon } from './CheckIcon.svg';
import { ReactComponent as FilterIcon } from './FilterIcon.svg';
import { ReactComponent as SortByDateIcon } from './SortByDateIcon.svg';
import { ReactComponent as SortByRelevanceIcon } from './SortByRelevanceIcon.svg';

import styles from './SortByFilter.module.scss';

interface IMenuItem {
    icon: React.FunctionComponent;
    text: string;
    tooltip: string;
}

const allMenuItems: Record<ClusterItemsSortBy, IMenuItem> = {
    [ClusterItemsSortBy.RELEVANCE]: {
        icon: SortByRelevanceIcon,
        text: 'detailsPanel.sortBy_relevance.text',
        tooltip: 'detailsPanel.sortBy_relevance.tooltip',
    },
    [ClusterItemsSortBy.DATE]: {
        icon: SortByDateIcon,
        text: 'detailsPanel.sortBy_date.text',
        tooltip: 'detailsPanel.sortBy_date.tooltip',
    },
};

export const SortByFilter: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        clustersStore: { itemsSortOrder, setItemsSortOrder, availableItemsSortTypes },
    } = useRootStore();
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const [popoverOpened, setPopoverOpened] = useState<boolean>(false);

    const handleWrapperClick = () => {
        setPopoverOpened((prevOpened) => !prevOpened);
    };

    const sortOrderTranslated = t(`detailsPanel.sortBy_${itemsSortOrder}.text`);

    return (
        <div
            ref={anchorRef}
            className={cx(styles.wrapper, { [styles.active]: popoverOpened })}
            onClick={handleWrapperClick}
        >
            <FilterIcon />
            <Typography color="activeBlue.main" component="div" className={styles.text}>
                {t('detailsPanel.sortBy', { order: sortOrderTranslated })}
            </Typography>
            <Popover
                open={popoverOpened}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {availableItemsSortTypes.map((type) => {
                        const Icon = allMenuItems[type].icon;
                        const menuItemText = allMenuItems[type].text;
                        const menuItemTooltip = allMenuItems[type].tooltip;
                        const isActive = type === itemsSortOrder;
                        const color = isActive ? 'activeBlue.main' : 'darkGray.main';

                        return (
                            <LightTooltip key={type} title={t(menuItemTooltip)} arrow placement="left">
                                <ListItemButton key={type} onClick={() => setItemsSortOrder(type)}>
                                    <ListItemIcon sx={{ width: 20, minWidth: 25, color: color }}>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Typography color={color} component="div" className={styles.filterItem}>
                                            {t(menuItemText)}
                                        </Typography>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{ width: 20, minWidth: 25, marginInlineStart: '15px', color: color }}
                                    >
                                        {isActive ? <CheckIcon /> : null}
                                    </ListItemIcon>
                                </ListItemButton>
                            </LightTooltip>
                        );
                    })}
                </List>
            </Popover>
        </div>
    );
});
