import { ECElementEvent } from 'echarts';
import EChartsReactCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { throttle } from 'lodash';
import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import api from '../../api';
import { generateFiltersClusterHierarchy } from '../../components/Dashboard/SideMenu/DetailsPane/helper';
import { applyCustomOperators } from '../../components/Dashboard/filtersHelper';
import { hashData } from '../../helper/stableQueryArgs';
import {
    AppliedFilter,
    ChangesOverTimeRecord,
    ChangesOverTimeResponse,
    ClustersDateRangeResponse,
    CustomerView,
    IMosaicStore,
    WeightedLeafMosaicRecordWithParent,
} from '../../types';

import { aggregateByMonths, getAggregationFunction, getAggregationLevel } from './helpers/aggregations';
import { getCursorGridCoordinates } from './helpers/cursor';
import { getAddMarkedEventsSeriesOption } from './helpers/custom-series/marked-events-series';
import { getGuideLineOption } from './helpers/guide-line';
import { prepareChartOption } from './helpers/helpers';
import { normalizeRange } from './helpers/range';
import { getChartSeries } from './helpers/series';
import { injectTooltipOptions } from './helpers/tooltip';
import { MarkedEventsStore } from './marked-events-store';
import { AggregatedMarkedPoints, AggregationLevel, ChartOption, MarkedEventChartData } from './types';

interface ILoadDataParams {
    currentView: CustomerView;
    detailsPanelItem: WeightedLeafMosaicRecordWithParent;
    selectedGroupByFields: string[];
    filters: AppliedFilter[] | null;
    revision: string | null;
    dateRange?: ClustersDateRangeResponse;
}

type OnPointHoverCallback = () => void;

export class ChangesOverTimeStore {
    rootStore: IMosaicStore;

    markedEventsStore: MarkedEventsStore;

    @observable
    chart: EChartsReactCore | null = null;

    @observable
    visible: boolean = false;

    @observable
    data: ChangesOverTimeRecord[] = [];

    @observable
    loading: boolean = true;

    @observable
    mouseGridCoordinates: [number, number] | undefined = undefined;

    @observable
    isMouseOverGrid: boolean = false;

    private onPointHoverCallback: OnPointHoverCallback[] = [];

    private previousDataHash: string | undefined = undefined;

    @computed
    get aggregationLevel(): AggregationLevel {
        return getAggregationLevel(this.data);
    }

    @computed
    get aggregatedData() {
        const aggregationFunction = getAggregationFunction(this.aggregationLevel);

        return aggregationFunction(this.data);
    }

    @computed
    get markedEventPointsAlignedToAggregatedData(): AggregatedMarkedPoints {
        const aggregatedPoints = aggregateByMonths(
            this.markedEventsStore.markedEventPoints.map((point) => ({
                date: point.date,
                count: 0,
            })),
            { skipCutoff: true },
        );

        return this.aggregatedData.map(([date, count]) => {
            const markedPoint = aggregatedPoints.find((point) => point[0] === date);

            return markedPoint ? [date, count] : [];
        });
    }

    @computed
    get chartSeries() {
        return getChartSeries({
            data: this.aggregatedData,
            markedEvents: this.markedEventPointsAlignedToAggregatedData,
            getMarkedEventDescription: this.markedEventsStore.getMarkedEventDescription,
        });
    }

    @computed
    get chartOption(): ChartOption {
        const baseOption = prepareChartOption(this.aggregationLevel);
        const optionWithTooltip = injectTooltipOptions(baseOption);

        return {
            ...optionWithTooltip,
            series: this.chartSeries,
        };
    }

    @action
    setVisible(visible: boolean) {
        this.visible = visible;
    }

    @action
    setChart(chart: EChartsReactCore | null) {
        this.chart = chart;
    }

    @action
    setData(data: ChangesOverTimeRecord[], dateRange?: ILoadDataParams['dateRange']) {
        const dataNormalized = normalizeRange(data, dateRange);
        const entireTicketsAmount = dataNormalized.reduce((result, item) => result + item.count, 0);

        this.data = dataNormalized.map((item) => ({
            ...item,
            count: (item.count / entireTicketsAmount) * 100,
        }));
    }

    loadData = flow(function* (this: ChangesOverTimeStore, params: ILoadDataParams) {
        const { currentView, detailsPanelItem, selectedGroupByFields, filters, revision } = params;

        const args = {
            viewId: currentView.id,
            filtersClusterHierarchy: generateFiltersClusterHierarchy(detailsPanelItem, selectedGroupByFields),
            filters: applyCustomOperators(filters ?? []),
            revision,
        };

        const dataHash = hashData(args);

        if (dataHash === this.previousDataHash) {
            return;
        }

        this.loading = true;
        this.markedEventsStore.closeModal();
        this.data = [];

        try {
            const response: ChangesOverTimeResponse = yield api.getChangesOverTime(
                currentView.id,
                generateFiltersClusterHierarchy(detailsPanelItem, selectedGroupByFields),
                applyCustomOperators(filters ?? []),
                revision ?? '',
            );

            if (response === undefined) {
                // api call has been canceled
                return;
            }

            this.setData(response.dates, params.dateRange);
            this.markedEventsStore.setData(response.markers);
            this.previousDataHash = dataHash;
        } catch (e) {
            this.previousDataHash = undefined;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    handleChartMouseMove = throttle(
        action((params: echarts.ElementEvent) => {
            if (!this.chart) {
                return;
            }

            const chartInstance = this.chart.getEchartsInstance();
            const isCursorOverGrid = chartInstance.containPixel('grid', [params.offsetX, params.offsetY]);
            const isCursorOverItems = ['Sub', 'ZRImage'].includes(params.target?.constructor.name);

            this.isMouseOverGrid = isCursorOverGrid || isCursorOverItems;

            this.mouseGridCoordinates = this.isMouseOverGrid ? getCursorGridCoordinates(this.chart, params) : undefined;
        }),
        100,
    );

    @action.bound
    handleAddMarkedEventClick(params: ECElementEvent) {
        const data = params.data as MarkedEventChartData;
        this.markedEventsStore.createNewEvent(data[0]);
    }

    @action.bound
    handleMarkedEventClick(params: ECElementEvent) {
        const data = params.data as MarkedEventChartData;
        this.markedEventsStore.editEvent(data[0]);
    }

    addOnPointHoverCallback = (callback: OnPointHoverCallback) => {
        this.onPointHoverCallback.push(callback);
    };

    removeOnPointHoverCallback = (callback: OnPointHoverCallback) => {
        this.onPointHoverCallback = this.onPointHoverCallback.filter((cb) => cb !== callback);
    };

    drawGuidLine() {
        const lineOption = getGuideLineOption({
            chart: this.chart,
            coordinates: this.mouseGridCoordinates,
            markedEvents: this.markedEventPointsAlignedToAggregatedData,
        });

        this.chart?.getEchartsInstance().setOption(lineOption, {
            replaceMerge: ['graphic'],
        });
    }

    drawAddMarkedEventButton() {
        const emulatedSeriesData: AggregatedMarkedPoints = this.aggregatedData.map(([date, count], index) => {
            // hide the buttons if mouse cursor is out of the chart
            if (!this.mouseGridCoordinates) {
                return [];
            }

            // do not display the add button for the existing marked event points
            if (this.markedEventPointsAlignedToAggregatedData[index].length > 0) {
                return [];
            }

            return index === this.mouseGridCoordinates[0] ? [date, count] : [];
        });

        this.chart?.getEchartsInstance().setOption({
            series: [...this.chartSeries, getAddMarkedEventsSeriesOption(emulatedSeriesData, this.aggregationLevel)],
        });
    }

    constructor(rootStore: IMosaicStore) {
        this.rootStore = rootStore;
        this.markedEventsStore = new MarkedEventsStore(rootStore);

        makeObservable(this);

        reaction(
            () => this.mouseGridCoordinates && this.mouseGridCoordinates[0],
            (value, previousValue) => {
                if (value !== undefined && value !== previousValue) {
                    this.onPointHoverCallback.forEach((callback) => callback());
                }
            },
        );

        reaction(
            () => this.isMouseOverGrid && this.mouseGridCoordinates,
            () => {
                this.drawGuidLine();
                this.drawAddMarkedEventButton();
            },
        );
    }
}
