import React from 'react';

import { DirectAnswer } from '../../../../../../../types';

import { CopyAction } from './CopyAction';
import { ThumbAction } from './ThumbAction';

import style from './Actions.module.scss';

export type ActionsProps = {
    answer?: DirectAnswer;
    questionId: string;
    customerProjectId: string;
};

export const Actions: React.FC<ActionsProps> = ({ answer, questionId, customerProjectId }) => {
    if (!answer) {
        return null;
    }

    return (
        <div className={style.wrapper}>
            <CopyAction answer={answer} questionId={questionId} customerProjectId={customerProjectId} />
            <ThumbAction variant="up" answer={answer} questionId={questionId} customerProjectId={customerProjectId} />
            <ThumbAction variant="down" answer={answer} questionId={questionId} customerProjectId={customerProjectId} />
        </div>
    );
};
