import React from 'react';
import { useTranslation } from 'react-i18next';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { useRootStore } from '../../../hooks/root-store';
import { ExplorerLayout } from '../../../types';

import GroupTitle from './GroupTitle';

import style from './LayoutSelector.module.scss';

interface LayoutSelectorProps {
    disabled: boolean;
}
const LayoutSelector = (props: LayoutSelectorProps) => {
    const { disabled } = props;
    const { t } = useTranslation();
    const { foamTreeStore } = useRootStore();

    return (
        <div className={style.layoutWrapper}>
            <GroupTitle>{t('explorerConfigPanel.configHeaders.layout')}</GroupTitle>
            <Box>
                <ToggleButtonGroup
                    value={foamTreeStore.selectedLayout}
                    disabled={disabled}
                    exclusive
                    onChange={(_e, newLayout: string | undefined) => {
                        if (newLayout) {
                            foamTreeStore.setSelectedLayout({ layout: newLayout as ExplorerLayout });
                        }
                    }}
                    aria-label="layout"
                    color="primary"
                    size="small"
                >
                    <ToggleButton value={ExplorerLayout.polygonal}>
                        <PentagonOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography className={style.layoutName}>
                            {t(`explorerConfigPanel.layouts.${ExplorerLayout.polygonal}`)}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value={ExplorerLayout.rectangular}>
                        <DashboardOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography className={style.layoutName}>
                            {t(`explorerConfigPanel.layouts.${ExplorerLayout.rectangular}`)}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </div>
    );
};

export default LayoutSelector;
