import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PortalProps } from '@mui/base/Portal';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

type ConfirmResolve = { resolve: (result: boolean) => void };

export interface IUseConfirmHookParams {
    message: string;
    container?: PortalProps['container'];
}

export const useConfirm = ({ message, container }: IUseConfirmHookParams) => {
    const { t } = useTranslation();
    const [confirmResolve, setConfirmResolve] = useState<ConfirmResolve | undefined>(undefined);

    const confirm = () =>
        new Promise((resolve) => {
            setConfirmResolve({ resolve });
        });

    const handleConfirmButtonClick = () => {
        confirmResolve?.resolve(true);
        setConfirmResolve(undefined);
    };

    const handleCancelButtonClick = () => {
        confirmResolve?.resolve(false);
        setConfirmResolve(undefined);
    };

    const ConfirmDialog = () => (
        <Dialog
            open={Boolean(confirmResolve)}
            container={container}
            slotProps={{
                backdrop: {
                    style: {
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 0.10)',
                    },
                },
                root: {
                    style: {
                        position: 'absolute',
                    },
                },
            }}
        >
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant="mosaic-secondary" onClick={handleCancelButtonClick}>
                    {t('confirmations.noButton')}
                </Button>
                <Button variant="mosaic-warning" onClick={handleConfirmButtonClick}>
                    {t('confirmations.yesButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [ConfirmDialog, confirm] as const;
};
