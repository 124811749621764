import moment from 'moment/moment';

import { ChangesOverTimeRecord } from '../../../types';
import { AggregationFunction, AggregationLevel } from '../types';

import { cutOffIncompleteMonths } from './filters';

export const DAYS_TOKEN = 'YYYY-MM-DD';
export const WEEKS_TOKEN = 'YYYY-W';
export const MONTHS_TOKEN = 'YYYY-MM';

export const getAggregationLevel = (data: ChangesOverTimeRecord[]): AggregationLevel => {
    return AggregationLevel.MONTHS;
};

export const aggregateByToken = (data: ChangesOverTimeRecord[], token: string): ReturnType<AggregationFunction> => {
    const dataByToken = data.reduce((result, item) => {
        const { date, count } = item;
        const weekKey = moment(date).format(token);

        const sum = result.get(weekKey);

        if (sum === undefined) {
            result.set(weekKey, count);
        } else {
            result.set(weekKey, sum + count);
        }

        return result;
    }, new Map<string, number>());

    return Array.from(dataByToken.entries());
};

const aggregateByDays: AggregationFunction = (data) => {
    return data.map((item) => [item.date, item.count]);
};

const aggregateByWeeks: AggregationFunction = (data) => aggregateByToken(data, WEEKS_TOKEN);

export const aggregateByMonths: AggregationFunction = (data, params = {}) => {
    const { skipCutoff = false } = params;
    const cleanData = skipCutoff ? data : cutOffIncompleteMonths(data);

    return aggregateByToken(cleanData, MONTHS_TOKEN);
};

const AggregationFunctionsMap: Record<AggregationLevel, AggregationFunction> = {
    [AggregationLevel.DAYS]: aggregateByDays,
    [AggregationLevel.WEEKS]: aggregateByWeeks,
    [AggregationLevel.MONTHS]: aggregateByMonths,
};

export const getAggregationFunction = (level: AggregationLevel): AggregationFunction => AggregationFunctionsMap[level];
