import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/lab';
import { Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';

import { convertItemsToCSV } from '../../../../../../helper/convertItemsToCSV';
import { useRootStore } from '../../../../../../hooks/root-store';
import errorLogger from '../../../../../ErrorBoundary/errorLogger';
import LightTooltip from '../../../../../common/LightTooltip';
import { DETAIL_PANEL_ITEM_ID_KEY } from '../../consts';

import ExportIcon from './ExportIcon.svg';

import styles from './CSVExportButton.module.scss';

const TOAST_AUTO_HIDE_DURATION = 10000;

interface IToastData {
    opened: boolean;
    message: string;
}

export const CSVExportButton: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        clustersStore: { currentLeaf, itemsLoading },
    } = useRootStore();
    const [loading, setLoading] = useState(false);
    const [toastInfo, setToastInfo] = useState<IToastData>({ opened: false, message: '' });

    const items = currentLeaf?.cluster.detailPaneItems ?? [];

    const handleClick = async () => {
        setLoading(true);

        try {
            const exportResult = await convertItemsToCSV(items, {
                keysToSkip: [DETAIL_PANEL_ITEM_ID_KEY],
            });

            const blob = new Blob([exportResult], { type: 'text/csv;charset=utf-8,' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'sample-items.csv');
            link.click();
        } catch (e) {
            const message = t('detailsPanel.exportToCSVError');

            setToastInfo({
                opened: true,
                message,
            });

            const error = e as Error;
            errorLogger.captureException(error);
        } finally {
            setLoading(false);
        }
    };

    const closeToast = () => {
        setToastInfo({
            opened: false,
            message: '',
        });
    };

    const handleToastClose: SnackbarProps['onClose'] = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        closeToast();
    };

    const disabled = items.length === 0 || itemsLoading;

    const renderSpinner = () => {
        return (
            <div className={styles.spinner}>
                <CircularProgress size={20} />
            </div>
        );
    };

    const renderButton = () => {
        const tooltipText = t('detailsPanel.exportToCSVButton');

        return (
            <>
                <LightTooltip title={tooltipText} arrow placement="left">
                    <IconButton onClick={handleClick} className={cx({ [styles.disabled]: disabled })}>
                        <img src={ExportIcon} alt={tooltipText} />
                    </IconButton>
                </LightTooltip>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoHideDuration={TOAST_AUTO_HIDE_DURATION}
                    open={toastInfo.opened}
                    onClose={handleToastClose}
                >
                    <Alert onClose={closeToast} severity="error" variant="filled">
                        {toastInfo.message}
                    </Alert>
                </Snackbar>
            </>
        );
    };

    return loading ? renderSpinner() : renderButton();
});
