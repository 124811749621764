export const copyAction = 'Copy answer';
export const feedbackOnCopy = 'Answer Copied!';
export const thumbUpAction = 'Thumb up';
export const thumbDownAction = 'Thumb down';
export const feedbackHeader = 'Send feedback';
export const feedbackSuccessHeader = 'Thank you!';
export const feedbackSuccessText = ' Your feedback has been recieved.';
export const feedbackHint = 'Your feedback helps us better understand your needs, and provide more accurate results.';
export const feedbackInputPlaceholder = 'Please share your feedback';
export const feedbackSend = 'Send';
export const feedbackCancel = 'Close';
export const feedbackSavedThumbUp = 'Successfully saved your answer!';
export const feedbackSavedThumbDown = 'Successfully saved thumbs down feedback!';
export const feedbackSaveError = 'An error occurred while sending the annotation';
export const descriptionMore = 'Show more';
export const descriptionLess = 'Show less';
export const statusFailed = 'Failed to generate high level summary - please try again later';
