import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import api from '../../../../../../../../api';
import { AnnotateDirectAnswerRequestParams } from '../../../../../../../../types';
import { StyledInput } from '../../../../../../../common/Input/StyledInput';
import { StyledSnackbar } from '../../../../../../../common/StyledSnackbar/StyledSnackbar';
import { AlertMessageType } from '../types';

import { SuccessDialog } from './SuccessDialog';

import styles from './FeedbackForm.module.scss';

const anchorOrigin = { vertical: 'bottom' as const, horizontal: 'center' as const };

export type FormDialogProps = {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    annotatePayload: AnnotateDirectAnswerRequestParams;
};

export const FeedbackForm: React.FC<FormDialogProps> = ({
    open,
    onClose,
    annotatePayload,
    onSuccess: onSuccessSubmit = (feedbackMessage?: string) => false,
}) => {
    const { t } = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [showSuccessContent, setShowSuccessContent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState<AlertMessageType | null>(null);

    const onSuccess = React.useCallback(() => {
        setMessage(null);
        setShowSuccessContent(true);
        onSuccessSubmit(inputRef?.current?.value);
    }, [setShowSuccessContent, setMessage, onSuccessSubmit]);

    const onFail = React.useCallback(() => {
        setShowSuccessContent(false);
        setMessage({ type: 'error', text: t('directAnswer.feedbackSaveError') });
    }, [t, setMessage, setShowSuccessContent]);

    const annotate = React.useCallback(
        (payload: AnnotateDirectAnswerRequestParams) => {
            api.annotateDirectAnswer(payload)
                .then(onSuccess)
                .catch(onFail)
                .finally(() => {
                    setLoading(false);
                });
        },
        [onSuccess, onFail, setLoading],
    );

    const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);

            const feedback = inputRef?.current?.value || undefined;

            annotate({ ...annotatePayload, feedback });
        },
        [annotate, annotatePayload],
    );

    const renderFormDialog = () => {
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle className={styles.title} id="form-dialog-title">
                    {t('directAnswer.feedbackHeader')}
                </DialogTitle>
                <Divider className={styles.divider} />
                <DialogContent className={styles.content}>
                    <DialogContentText className={styles.hint}>{t('directAnswer.feedbackHint')}</DialogContentText>
                    <StyledInput
                        fullWidth
                        autoFocus
                        multiline
                        minRows={6}
                        id="feedback"
                        variant="standard"
                        placeholder={t('directAnswer.feedbackInputPlaceholder')}
                        inputRef={inputRef}
                    />
                </DialogContent>
                <Divider className={styles.divider} />
                <DialogActions className={styles.actions}>
                    <Button variant="feedback-secondary" onClick={onClose} disabled={loading}>
                        {t('directAnswer.feedbackCancel')}
                    </Button>
                    <Button variant="feedback-primary" onClick={handleSubmit} loading={loading}>
                        {t('directAnswer.feedbackSend')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            {showSuccessContent ? <SuccessDialog open={open} onClose={onClose} /> : renderFormDialog()}
            {!!message && (
                <StyledSnackbar
                    open
                    autoHideDuration={2500}
                    anchorOrigin={anchorOrigin}
                    onClose={() => setMessage(null)}
                    message={message.text}
                    severity={message.type}
                />
            )}
        </>
    );
};
