import { Moment } from 'moment';

import { IAPIMarkedEvent } from '../../../types';

export const convertToAPIMarkedEvent = (date: Moment, description: string): IAPIMarkedEvent => ({
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
    description,
});
