import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import logo from '../common/logo.png';
import { routes } from '../consts';

import style from './Forbidden.module.scss';

const Forbidden = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className={style.forbiddenWrapper}>
                <img src={logo} alt="Logo" width="195" />
                <Typography variant="h4" color="error.main">
                    {t('forbidden.title')}
                </Typography>
                <Typography variant="h6">
                    {t('forbidden.subTitle')}
                    <Typography variant="body2" align="center">
                        <RouterLink to={routes.home}>{t('forbidden.subTitleLink')}</RouterLink>
                    </Typography>
                </Typography>
                <Typography variant="h5" pt="35px">
                    {t('forbidden.linkText')}
                    <Link href="mailto:support@ask-ai.com?subject=Please%20assist%20me%20using%20Ask-AI">
                        {t('forbidden.contactSupportLinkText')}
                    </Link>
                </Typography>
            </div>
        </div>
    );
};

export default Forbidden;
