import { TFunction } from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';

import {
    CLUSTER_SIZE_FILTER_NAME,
    DEFAULT_MAX_CLUSTER_SIZE,
    DEFAULT_MAX_FILTER,
    DEFAULT_MIN_CLUSTER_SIZE,
    DEFAULT_MIN_FILTER,
} from '../../helper/consts';
import {
    AllowedFilter,
    AppliedFilter,
    FieldsMetadata,
    FilterOperators,
    MappedAllowedFilters,
    SemanticType,
} from '../../types';

export const AUTOCOMPLETE_OPERATORS_FOR_STRING = new Set<string>(['is', 'is_not']);

export const CUSTOM_OPERATORS: FilterOperators = {
    last7Days: {
        semantic_types: [SemanticType.DATE],
    },
    last30Days: {
        semantic_types: [SemanticType.DATE],
    },
};
export const CUSTOM_OPERATORS_KEYS = new Set<string>(Object.keys(CUSTOM_OPERATORS));

export const CUSTOM_OPERATORS_FN: {
    [key: string]: { generateFilters?: (field: string) => AppliedFilter[]; generateView: () => string };
} = {
    last7Days: {
        generateFilters: (field: string) => [
            { field, operator: 'gte', value: moment().subtract(7, 'days').toISOString() },
            { field, operator: 'lte', value: moment().toISOString() },
        ],
        generateView: () => moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM'),
    },
    last30Days: {
        generateFilters: (field: string) => [
            { field, operator: 'gte', value: moment().subtract(30, 'days').toISOString() },
            { field, operator: 'lte', value: moment().toISOString() },
        ],
        generateView: () => moment().subtract(30, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM'),
    },
    is_empty: {
        generateView: () => 'N/A',
    },
    is_not_empty: {
        generateView: () => 'N/A',
    },
};

/**
 * Apply custom operators (if needed)
 *
 * @param filters
 * @returns filters after applying the custom operators
 */
export const applyCustomOperators = (filters: AppliedFilter[]): AppliedFilter[] => {
    return filters.flatMap((filter) => {
        if (CUSTOM_OPERATORS_KEYS.has(filter.operator) && CUSTOM_OPERATORS_FN[filter.operator]) {
            const generateFilters = CUSTOM_OPERATORS_FN[filter.operator].generateFilters;
            if (generateFilters) {
                return generateFilters(filter.field);
            }
        }
        return filter;
    });
};

/**
 * Generate Yup validation schema dynamically by the given metadata
 *
 * @param fieldsMetadata
 * @param t
 * @param allowedFilters
 * @returns
 */
export const generateFilterValidations = (
    fieldsMetadata: FieldsMetadata | undefined,
    t: TFunction,
    allowedFilters: AllowedFilter[] | undefined,
) => {
    const mappedAllowedFilters: MappedAllowedFilters = (allowedFilters || []).reduce((prev, f) => {
        prev[f.field] = f;
        return prev;
    }, {} as MappedAllowedFilters);

    return Yup.object().shape({
        filters: Yup.array(
            Yup.object().shape({
                field: Yup.string().required(),
                operator: Yup.string().required(),
                value: Yup.mixed()
                    .when('field', (field, _schema) => {
                        const type = (fieldsMetadata || {})[field]?.semantic_type;

                        // If a number - will verify as a number with min and max
                        if (type === SemanticType.NUMBER) {
                            // Getting the min/max values from the filter metadata OR default size (if cluster size - will use a dedicated defaults)
                            const min =
                                mappedAllowedFilters[field]?.min ||
                                (field === CLUSTER_SIZE_FILTER_NAME ? DEFAULT_MIN_CLUSTER_SIZE : DEFAULT_MIN_FILTER);
                            const max =
                                mappedAllowedFilters[field]?.max ||
                                (field === CLUSTER_SIZE_FILTER_NAME ? DEFAULT_MAX_CLUSTER_SIZE : DEFAULT_MAX_FILTER);

                            return Yup.number()
                                .required('filters.validationErrors.required')
                                .min(min, t('filters.validationErrors.min', { value: min }))
                                .max(max, t('filters.validationErrors.max', { value: max }))
                                .typeError('filters.validationErrors.number');
                        } else if (type === SemanticType.ARRAY) {
                            return Yup.array(
                                Yup.string()
                                    .required('filters.validationErrors.required')
                                    .typeError('filters.validationErrors.string'),
                            ).test({
                                message: 'filters.validationErrors.required',
                                test: (val) => Boolean(val && val.length > 0),
                            });
                        } else if (type === SemanticType.BOOL) {
                            return Yup.bool().required('filters.validationErrors.required');
                        } else if (type === SemanticType.DATE) {
                            return Yup.string()
                                .required('filters.validationErrors.required')
                                .matches(
                                    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
                                    'filters.validationErrors.date',
                                );
                        }

                        return Yup.lazy((val) => {
                            if (Array.isArray(val)) {
                                return Yup.array()
                                    .of(
                                        Yup.string()
                                            .required('filters.validationErrors.required')
                                            .typeError('filters.validationErrors.array'),
                                    )
                                    .test({
                                        message: 'filters.validationErrors.required',
                                        test: (val) => Boolean(val && val.length > 0),
                                    });
                            }
                            return Yup.string().required('filters.validationErrors.required');
                        });
                    })
                    .when('operator', (operator, _schema) => {
                        if (CUSTOM_OPERATORS_KEYS.has(operator) || operator in CUSTOM_OPERATORS_FN) {
                            return Yup.string().optional();
                        }

                        return _schema;
                    }),
            }),
        ),
    });
};
