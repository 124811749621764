import { TooltipComponentOption } from 'echarts/components';
import round from 'lodash/round';
import moment from 'moment/moment';

import styles from '../../../components/Dashboard/SideMenu/DetailsPane/Widgets/ChangesOverTime/ChangesOverTimeWidget.module.scss';
import i18n from '../../../i18n';
import { AggregationLevel } from '../types';

import { MONTHS_TOKEN, WEEKS_TOKEN } from './aggregations';

type AxisFormatterFunction = () => (value: string, index: number) => string;
type TooltipFormatterFunction = TooltipComponentOption['formatter'];

const formatAxisDays: AxisFormatterFunction = () => {
    const monthsDisplayed: string[] = [];

    return (value: string, index: number): string => {
        if (value === '') {
            return '';
        }

        if (index === 0) {
            monthsDisplayed.splice(0);
        }

        const date = moment(value);

        const dateMonth = date.clone().startOf('month').format('MMM');
        const isMonthDisplayed = monthsDisplayed.find((month) => month === dateMonth);

        if (!isMonthDisplayed) {
            monthsDisplayed.push(dateMonth);
        }

        return isMonthDisplayed ? date.format('D') : `${date.format('D')}\n${dateMonth}`;
    };
};
const formatAxisWeeks: AxisFormatterFunction = () => {
    const monthsDisplayed: string[] = [];

    return (value: string, index: number): string => {
        if (value === '') {
            return '';
        }

        if (index === 0) {
            monthsDisplayed.splice(0);
        }

        const date = moment(value, WEEKS_TOKEN);
        const dateMonth = date.weekday(0).clone().format('MMM');

        const isMonthDisplayed = monthsDisplayed.find((month) => month === dateMonth);
        if (!isMonthDisplayed) {
            monthsDisplayed.push(dateMonth);
        }

        const weekStart = date.weekday(0).clone();

        if (isMonthDisplayed) {
            return `${weekStart.format('D')}`;
        }

        return `${weekStart.format('D MMM')}`;
    };
};
const formatAxisMonths: AxisFormatterFunction = () => {
    return (value: string): string => {
        if (value === '') {
            return '';
        }

        const date = moment(value, MONTHS_TOKEN);

        return date.format('MMM');
    };
};

const AxisFormattersMap: Record<AggregationLevel, AxisFormatterFunction> = {
    [AggregationLevel.DAYS]: formatAxisDays,
    [AggregationLevel.WEEKS]: formatAxisWeeks,
    [AggregationLevel.MONTHS]: formatAxisMonths,
};

export const getAxisFormatter = (level: AggregationLevel): AxisFormatterFunction => AxisFormattersMap[level];

const tooltipHTML = (_: TemplateStringsArray, xValue: string, yValue: number, description?: string) => {
    const value = round(yValue, 1).toFixed(1);

    const rows: string[] = [
        `<span>${xValue}</span>`,
        `<span class=${styles.eventCount}>${i18n.t('changesOverTime.chartTooltip', { value })}</span>`,
    ];

    if (description) {
        rows.push(`<span class=${styles.eventDescription}>${description}</span>`);
    }

    return `<div class=${styles.tooltipContent}>${rows.join('')}</div>`;
};

interface TooltipParams {
    data: [string, number];
}

const formatTooltipDays: TooltipFormatterFunction = (params) => {
    const { data } = params as unknown as TooltipParams;

    const date = moment(data[0]);

    return tooltipHTML`${date.format('D MMM YYYY')}${data[1]}`;
};

const formatTooltipWeeks: TooltipFormatterFunction = (params) => {
    const { data } = params as unknown as TooltipParams;

    const date = moment(data[0], WEEKS_TOKEN);

    const getDateToRender = () => {
        const weekStart = date.weekday(0).clone();

        return `${weekStart.format('D MMM YYYY')}`;
    };

    return tooltipHTML`${getDateToRender()}${data[1]}`;
};

const formatTooltipMonths: TooltipFormatterFunction = (params) => {
    const { data } = params as unknown as TooltipParams;

    const date = moment(data[0], MONTHS_TOKEN);

    return tooltipHTML`${date.format('MMM YYYY')}${data[1]}`;
};

const TooltipFormatterMap: Record<AggregationLevel, TooltipFormatterFunction> = {
    [AggregationLevel.DAYS]: formatTooltipDays,
    [AggregationLevel.WEEKS]: formatTooltipWeeks,
    [AggregationLevel.MONTHS]: formatTooltipMonths,
};

export const getTooltipFormatter = (level: AggregationLevel): TooltipFormatterFunction => {
    const formatter = TooltipFormatterMap[level];

    return formatter;
};

export type GetMarkedEventDescriptionCallback = (date: string) => string;

export const getMarkedEventTooltipFormatter =
    (getDescription: GetMarkedEventDescriptionCallback): TooltipFormatterFunction =>
    (params) => {
        const { data } = params as unknown as TooltipParams;
        const [xValue, yValue] = data;
        const date = moment(xValue, MONTHS_TOKEN);

        return tooltipHTML`${date.format('MMM YYYY')}${yValue}${getDescription(xValue)}`;
    };
