import { FieldsMetadata } from '../../../../../types';

export const ADDITIONAL_FIELDS_PREFIX = 'additionalFields.';
export const CLUSTER_FIELDS_PREFIX = 'clusterFields.';

/**
 * Generate field filter display name
 *
 * Will use the display name if exists, if not, will use the name, but without the additionalFields prefix
 *
 * @param field
 * @param fieldsMetadata
 * @returns
 */
export const generateFilterDisplayName = (field: string, fieldsMetadata: FieldsMetadata | undefined): string => {
    const metadata = fieldsMetadata && fieldsMetadata[field];
    if (metadata && metadata.display_name) {
        return metadata.display_name;
    }

    field = field.replace(CLUSTER_FIELDS_PREFIX, '');

    return field.replace(ADDITIONAL_FIELDS_PREFIX, '');
};
