import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../hooks/root-store';

import { LoadingExplorerAnimationPortal } from './LoadingExplorerAnimation';
import { generateLastRenderInfo } from './helpers';

import style from './Explorer.module.scss';

const Explorer = observer(() => {
    const rootStore = useRootStore();
    const { foamTreeStore, config, mosaicMetadata } = rootStore;
    const { foamTreeChart, loader, data, isLoaderVisible, min, max, selectedLayout } = foamTreeStore;
    const [prevDataLoading, setPrevDataLoading] = useState<boolean>(isLoaderVisible);
    const [lastRenderInfo, setLastRenderInfo] = useState<string>();

    // Initial mounting effect
    useEffect(() => {
        foamTreeStore.initChart();
    }, [foamTreeStore]);

    // Opening/closing side menu effect
    useEffect(() => {
        foamTreeStore.handleChartResize();
    }, [foamTreeStore, rootStore.sideMenuType]);

    // Update data/config effect
    useEffect(() => {
        if (!foamTreeChart || !loader) {
            return;
        }

        if (isLoaderVisible) {
            // We're loading data, resetting the view and showing the loader
            foamTreeChart.set('dataObject', {});
            loader.started();
            setPrevDataLoading(isLoaderVisible);
        } else if (data && config && mosaicMetadata) {
            // The data was loaded! - we're verging the data was changed, if the data was changed, re-render
            generateLastRenderInfo(data, config, mosaicMetadata).then((renderInfo) => {
                // Displaying the data
                if (renderInfo !== lastRenderInfo || prevDataLoading) {
                    setLastRenderInfo(renderInfo);

                    foamTreeChart.set('dataObject', {});
                    loader.complete({
                        groups: foamTreeStore.generatedModel,
                    });
                    foamTreeStore.hideLoader();
                }

                setPrevDataLoading(isLoaderVisible);
            });
        }
    }, [
        foamTreeStore,
        foamTreeChart,
        loader,
        data,
        config,
        mosaicMetadata,
        min,
        max,
        isLoaderVisible,
        selectedLayout,
        lastRenderInfo,
        prevDataLoading,
    ]);

    return (
        <>
            <div
                id="container"
                className={classNames(style.container, { [style.containerOpenMenu]: Boolean(rootStore.sideMenuType) })}
            >
                <div id="visualization" className={style.visualization}></div>
            </div>
            {foamTreeStore.isLoaderVisible && <LoadingExplorerAnimationPortal elementId="treemapLoader" />}
        </>
    );
});

export default Explorer;
