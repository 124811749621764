import amplitude from 'amplitude-js';

class EventLogger {
    private apiKeys = {
        prod: 'ba647ac471c6dd5cf8a216d4a92ea7da',
        dev: '622f1401e4559868ca832b03f6091991',
    };
    private instance: amplitude.AmplitudeClient;

    constructor() {
        this.instance = amplitude.getInstance();
        const env = window.location.host === 'mosaic.ask-ai.co' ? 'prod' : 'dev';
        this.instance.init(this.apiKeys[env]);
    }

    setUserId(userId: string) {
        this.instance.setUserId(userId);
    }

    setUserExtraDetails(org: string, userName: string) {
        this.instance.setUserProperties({ name: userName, org });
    }

    log(event: string, extraData: any = {}) {
        this.instance.logEvent(event, extraData);
    }
}

const instance = new EventLogger();
export default instance;
