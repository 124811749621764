import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

interface SearchbarProps {
    onSearch: (keyword: string) => void;
    disabled: boolean;
}

const Searchbar = forwardRef((props: SearchbarProps, ref) => {
    const { onSearch, disabled } = props;
    const { t } = useTranslation();
    const [currentSearch, setCurrentSearch] = useState<string>('');

    useImperativeHandle(ref, () => ({
        resetSearch() {
            setCurrentSearch('');
        },
    }));

    return (
        <Box sx={{ pr: 1 }}>
            <OutlinedInput
                placeholder={t('topbar.searchbarPlaceholder')}
                disabled={disabled}
                value={currentSearch}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="search"
                            disabled={disabled}
                            onClick={() => onSearch(currentSearch || '')}
                            edge="end"
                        >
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>
                }
                onChange={(e) => {
                    setCurrentSearch(e.target.value);
                    onSearch(e.target.value);
                }}
                onKeyUp={(e) => {
                    // Call search on enter
                    if (e.key === 'Enter') {
                        onSearch(currentSearch || '');
                    }
                }}
            />
        </Box>
    );
});

export default Searchbar;
