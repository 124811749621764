import moment from 'moment';

import { ChangesOverTimeRecord } from '../../../types';

import { DAYS_TOKEN } from './aggregations';

export const cutOffIncompleteMonths = (data: ChangesOverTimeRecord[]): ChangesOverTimeRecord[] => {
    const result: ChangesOverTimeRecord[] = [...data];

    if (!result.length) {
        return [];
    }

    const startDate = moment(result[0].date, DAYS_TOKEN);
    if (!startDate.isSame(startDate.clone().startOf('month'), 'day')) {
        const endOfMonth = startDate.clone().endOf('month').format(DAYS_TOKEN);
        const endOfMonthIndex = result.findIndex((element) => element.date === endOfMonth);
        if (endOfMonthIndex >= 0) {
            result.splice(0, endOfMonthIndex + 1);
        }
    }

    if (result.length === 0) {
        return [];
    }

    const endDate = moment(result[result.length - 1].date, DAYS_TOKEN);
    if (!endDate.isSame(endDate.clone().endOf('month'), 'day')) {
        const startOfMonth = endDate.clone().startOf('month').format(DAYS_TOKEN);
        const startOfMonthIndex = result.findIndex((element) => element.date === startOfMonth);
        if (startOfMonthIndex >= 0) {
            result.splice(startOfMonthIndex);
        }
    }

    return result;
};
