import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { AppliedFilter, ClusterFieldNamesMapping, CustomerView, FieldsMetadata } from '../../../types';

import ClusterSizeSelector from './ClusterSizeSelector';
import ColorBySelector from './ColorBySelector';
import GroupBySelector from './GroupBySelector';
import LayoutSelector from './LayoutSelector';
import SizeBySelector from './SizeBySelector';

import style from './ExplorerConfigPanel.module.scss';

interface ExplorerConfigPanelProps {
    customerName: string | undefined;
    customerLogoUrl: string | undefined;
    maxLevel: number | undefined;
    groupByFields: string[] | undefined;
    selectedGroupByFields: string[] | undefined;
    selectedSizeByField: string | undefined;
    sizeByFields: string[] | undefined;
    selectedSizeFunction: string | undefined;
    selectedColorByField: string | undefined;
    colorByFields: string[] | undefined;
    selectedPalette: string | undefined;
    selectedColorFunction: string | undefined;
    currentView: CustomerView | undefined;
    fieldsMetadata: FieldsMetadata | undefined;
    initialAppliedFilter: AppliedFilter[] | undefined;
    dataLoading: boolean;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
    setSizeBy: (field: string) => void;
    changeGroupBy: (index: number, value: string) => void;
    setSelectedColorByField: (field: string) => void;
    setSelectedPalette: (palette: string) => void;
    setSelectedColorFunction: (fnName: string) => void;
    setSelectedSizeFunction: (fnName: string) => void;
    resetConfig: () => void;
}

const ExplorerConfigPanel = (props: ExplorerConfigPanelProps) => {
    const {
        customerName,
        customerLogoUrl,
        maxLevel,
        groupByFields,
        selectedGroupByFields,
        selectedSizeByField,
        sizeByFields,
        selectedColorByField,
        colorByFields,
        selectedPalette,
        currentView,
        selectedColorFunction,
        selectedSizeFunction,
        fieldsMetadata,
        initialAppliedFilter,
        dataLoading,
        clusterFieldNamesMapping,
        changeGroupBy,
        setSizeBy,
        setSelectedColorByField,
        setSelectedPalette,
        setSelectedColorFunction,
        setSelectedSizeFunction,
        resetConfig,
    } = props;
    const { t } = useTranslation();
    const isLoading =
        maxLevel === undefined ||
        groupByFields === undefined ||
        selectedGroupByFields === undefined ||
        selectedSizeByField === undefined ||
        sizeByFields === undefined ||
        selectedColorByField === undefined ||
        selectedColorFunction === undefined ||
        selectedSizeFunction === undefined ||
        colorByFields === undefined ||
        selectedPalette === undefined ||
        customerName === undefined ||
        customerLogoUrl === undefined ||
        currentView === undefined ||
        fieldsMetadata === undefined ||
        initialAppliedFilter === undefined;

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant="h5" component="div" color="darkGray.main" className={style.title}>
                {t('explorerConfigPanel.title')}
            </Typography>
            <Divider sx={{ margin: '0 10px' }} />

            <GroupBySelector
                maxLevel={maxLevel}
                fields={groupByFields}
                selectedFields={selectedGroupByFields}
                changeGroupBy={changeGroupBy}
                disabled={dataLoading}
                clusterFieldNamesMapping={clusterFieldNamesMapping}
            />

            <SizeBySelector
                currentField={selectedSizeByField}
                fields={sizeByFields}
                setSizeBy={setSizeBy}
                selectedFunction={selectedSizeFunction}
                setSelectedFunction={setSelectedSizeFunction}
                disabled={dataLoading}
                clusterFieldNamesMapping={clusterFieldNamesMapping}
            />

            <ColorBySelector
                fields={colorByFields}
                selectedField={selectedColorByField}
                selectedPalette={selectedPalette}
                selectedFunction={selectedColorFunction}
                disabled={dataLoading}
                clusterFieldNamesMapping={clusterFieldNamesMapping}
                setSelectedField={setSelectedColorByField}
                setSelectedPalette={setSelectedPalette}
                setSelectedFunction={setSelectedColorFunction}
            />

            <Divider sx={{ margin: '0 10px' }} />

            <LayoutSelector disabled={dataLoading} />

            <ClusterSizeSelector dataLoading={dataLoading} resetConfig={resetConfig} />
        </Box>
    );
};

export default ExplorerConfigPanel;
