import { IHighlightClustersFilter, LeafMosaicRecord } from '../types';

/**
 * Returns filter used to highlight clusters having marked events
 *
 * @param filterEnabled
 */
export const getMarkedClustersFilter =
    (filterEnabled: boolean, allLeafs: LeafMosaicRecord[]): IHighlightClustersFilter =>
    (leafs) => {
        if (!filterEnabled) {
            return leafs;
        }

        const markedLeafsIds = allLeafs.filter((leaf) => leaf.is_marked).map((leaf) => leaf.label);

        return leafs.filter((leaf) => markedLeafsIds.includes(leaf.label));
    };
