import lodash from 'lodash';

import { IHighlightClustersFilter, WeightedLeafMosaicRecord } from '../types';

const searchInLeaf = (leaf: WeightedLeafMosaicRecord, searchFields: string[], searchKeyword: string) => {
    if (!leaf.cluster) {
        return false;
    }

    for (const searchField of searchFields) {
        const value = lodash.get(leaf, searchField);

        if (
            // If string
            (typeof value === 'string' && value.trim().toLowerCase().includes(searchKeyword)) ||
            // If number - convert to string
            (typeof value === 'number' && `${value}` === searchKeyword)
        ) {
            return true;
        }
    }

    return false;
};

const getSearchInGroupsFilter =
    (searchKeyword: string, searchFields: string[]): IHighlightClustersFilter =>
    (leafs) => {
        if (searchKeyword === '') {
            return leafs;
        }

        const foundLeafs = leafs.filter((l) => searchInLeaf(l, searchFields, searchKeyword));

        return foundLeafs;
    };

export default getSearchInGroupsFilter;
