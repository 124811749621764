import { Field, FieldDisplay, FieldType, MosaicMetadata, SemanticType } from '../types';

import getFormatterName from './getFormatterName';

/**
 * Getting field from metadata
 *
 * @param metadata
 * @param fieldName
 * @returns
 */
const getField = (metadata: MosaicMetadata, fieldName: string): Field => {
    const fieldMetadata = metadata.dataset.fields_metadata[fieldName];
    if (!fieldMetadata) {
        throw new Error('Failed to get field metadata: ' + fieldName);
    }

    return {
        type: fieldMetadata.semantic_type === SemanticType.STRING ? FieldType.continuous : FieldType.discrete,
        formatter: getFormatterName(metadata, fieldName),
        unit: fieldMetadata.display === FieldDisplay.PERCENTAGE ? '%' : undefined,
        decimals: fieldMetadata.display === FieldDisplay.PERCENTAGE ? 1 : undefined,
    };
};

export default getField;
