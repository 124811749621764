import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { SKELETON_COLOR } from '../../../../../../../consts';

import styles from './WidgetLoader.module.scss';

export const WidgetLoader: React.FC = () => {
    return (
        <div className={styles.content}>
            <Skeleton animation="wave" variant="text" sx={{ width: 350, backgroundColor: SKELETON_COLOR }} />
            <Skeleton animation="wave" variant="text" sx={{ width: 350, backgroundColor: SKELETON_COLOR }} />
            <Skeleton animation="wave" variant="text" sx={{ width: 205, backgroundColor: SKELETON_COLOR }} />
        </div>
    );
};
