import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NarrowSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        color: theme.palette.midGray.main,
        padding: '1px',

        '&.Mui-checked': {
            color: theme.palette.neutralSelected.main,
            transform: 'translateX(12px)',

            '& + .MuiSwitch-track': {
                border: `2px solid ${theme.palette.neutralSelected.main}`,
                backgroundColor: '#FFF',
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#FFF',
        border: `2px solid #D9D9D9`,
        opacity: 1,
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 10,
        height: 10,
        margin: 2,
    },
}));
