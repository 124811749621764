import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../hooks/root-store';
import { NarrowSwitch } from '../../../common/StyledSwitch/StyledSwitch';

import styles from './MarkedClustersSwitch.module.scss';

export const MarkedClustersSwitch = observer(() => {
    const { t } = useTranslation();
    const { filterMarkedClustersEnabled, setFilterMarkedClustersEnabled } = useRootStore();

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterMarkedClustersEnabled(event.target.checked);
    };

    const color = filterMarkedClustersEnabled ? 'neutralSelected.main' : 'neutral.main';

    return (
        <Box className={styles.wrapper}>
            <NarrowSwitch checked={filterMarkedClustersEnabled} onChange={handleSwitchChange} />
            <Typography color={color} fontSize="16px" fontWeight={500}>
                {t('changesOverTime.markedEventsFilter.title')}
            </Typography>
        </Box>
    );
});
