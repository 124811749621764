import React, { useCallback, useContext } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFlag } from '@unleash/proxy-client-react';
import { toJS } from 'mobx';

import { AppToggle } from '../../../clents/unleash/app-toggle-names';
import { useRootStore } from '../../../hooks/root-store';
import { mixpanelService } from '../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { MosaicStoreContext } from '../../../stores/MosaicStore';
import { CustomerView } from '../../../types';

import DateRangeLabel from './DateRangeLabel';
import FiltersView from './FiltersView';
import { MarkedClustersSwitch } from './MarkedClustersSwitch/MarkedClustersSwitch';
import Searchbar from './Searchbar';
import TopbarSkeleton from './TopbarSkeleton';
import UserAvatarWrapper from './UserAvatarWrapper';
import { BootstrapInput, SideMenuXS, ViewDisplayXS } from './calculatedStyles';

import style from './Topbar.module.scss';

interface TopbarProps {
    setCurrentView: (view: CustomerView) => void;
    onSearch: (keyword: string) => void;
}

const Topbar = (props: TopbarProps) => {
    const { setCurrentView, onSearch } = props;
    const mosaicStore = useContext(MosaicStoreContext);
    const { clustersDateRange, isDataLoading } = useRootStore();
    const displayDateRange = useFlag(AppToggle.DISPLAY_DATE_RANGE);

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            const viewId = event.target.value as string;
            const foundView = (mosaicStore?.availableViews || []).find((v) => v.id === viewId);

            if (!foundView) {
                console.warn('Could not find the selected view');
                mixpanelService.track({
                    event: MixpanelEvent.VIEW_CHANGE,
                    meta: {
                        selected_mosaic: 'unknown',
                    },
                });
                return;
            }
            setCurrentView(foundView);
            mixpanelService.track({
                event: MixpanelEvent.VIEW_CHANGE,
                meta: {
                    selected_mosaic: viewId,
                },
            });
        },
        [setCurrentView, mosaicStore],
    );

    if (!mosaicStore?.currentView || !mosaicStore?.availableViews) {
        return <TopbarSkeleton />;
    }

    return (
        <Box className={style.topbar}>
            <Box sx={ViewDisplayXS}>
                <div style={{ backgroundImage: `url(${mosaicStore.currentView.logo})` }} className={style.logo} />
                <FormControl>
                    <Select
                        value={mosaicStore.currentView.id}
                        variant="standard"
                        autoWidth
                        input={<BootstrapInput />}
                        onChange={handleChange}
                    >
                        {mosaicStore.availableViews.map((view) => (
                            <MenuItem key={view.id} value={view.id}>
                                {view.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {displayDateRange && (
                        <FormHelperText>
                            <DateRangeLabel dateRange={toJS(clustersDateRange)} isDataLoading={isDataLoading} />
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box sx={SideMenuXS}>
                <MarkedClustersSwitch />
                <FiltersView />
                <Searchbar onSearch={onSearch} disabled={mosaicStore?.isDataLoading} />
                <Divider orientation="vertical" flexItem />
                <Box className={style.avatarWrapper}>
                    <UserAvatarWrapper />
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
