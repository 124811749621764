import React from 'react';
import { useTranslation } from 'react-i18next';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { SKELETON_COLOR } from '../../../../../../consts';
import { useRootStore } from '../../../../../../hooks/root-store';

import styles from './CollapseAll.module.scss';

export const CollapseAll: React.FC = observer(() => {
    const { t } = useTranslation();
    const {
        clustersStore: { itemsLoading, itemsCollapsed, toggleItemsCollapsed },
    } = useRootStore();

    if (itemsLoading) {
        return (
            <Skeleton
                animation="wave"
                variant="text"
                sx={{ height: 35, width: 150, backgroundColor: SKELETON_COLOR }}
            />
        );
    }

    return (
        <div className={styles.wrapper}>
            <IconButton size="small" onClick={toggleItemsCollapsed}>
                {itemsCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            </IconButton>
            <Typography color="darkGray.main" component="div" className={styles.text}>
                {t('detailsPanel.totalItems')}
            </Typography>
        </div>
    );
});
