import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import style from './FiltersViewButton.module.scss';

interface FiltersViewButtonProps {
    disabled: boolean;
    isOpen: boolean;
    numOfFilters: number;
    onClick: () => void;
}

const FiltersViewButton = React.forwardRef<HTMLButtonElement, FiltersViewButtonProps>((props, ref) => {
    const { disabled, isOpen, numOfFilters, onClick } = props;
    const { t } = useTranslation();

    return (
        <Box className={style.box}>
            <Button
                ref={ref}
                color={isOpen ? 'neutralSelected' : 'neutral'}
                variant="outlined"
                className={style.button}
                disabled={disabled}
                onClick={onClick}
            >
                <FilterAltOutlinedIcon sx={{ fontSize: 24 }} />
                <Typography
                    variant="subtitle1"
                    component="div"
                    color={isOpen ? 'neutralSelected.main' : 'neutral.main'}
                    className={style.title}
                >
                    {t('filters.title')}
                    {numOfFilters > 0 ? ` (${numOfFilters})` : ''}
                </Typography>
            </Button>
        </Box>
    );
});

export default FiltersViewButton;
