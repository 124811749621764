import { TooltipComponentOption } from 'echarts/components';

import { fontFamily } from '../../../theme';
import { ChartOption } from '../types';

export const injectTooltipOptions = (option: ChartOption): ChartOption => {
    const tooltip: TooltipComponentOption = {
        show: true,
        appendToBody: true,
        trigger: 'item',
        position: 'top',
        borderColor: '#D4D4D8',
        padding: [6, 12],
        shadowColor: 'rgba(0, 0, 0, 0.05)',
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        shadowBlur: 2,
        textStyle: {
            fontFamily,
            fontSize: 10,
            fontWeight: 700,
            lineHeight: 16,
            color: '#A9A9A9',
        },
    };

    return {
        ...option,
        tooltip,
    };
};
