import { MosaicMetadata, Formatters, SemanticType } from '../types';

/**
 * Extracting the formatter name from metadata for a given field
 *
 * @param metadata
 * @param fieldName
 * @returns
 */
const getFormatterName = (metadata: MosaicMetadata | undefined, fieldName: string | undefined): Formatters => {
    if (!metadata || !fieldName) {
        return 'identity';
    }

    const fieldMetadata = metadata.dataset.fields_metadata[fieldName];

    if (!fieldMetadata) {
        return 'identity';
    }

    if (fieldMetadata.semantic_type === SemanticType.NUMBER) {
        return 'numberToStringWithUnit';
    }

    return 'identity';
};

export default getFormatterName;
