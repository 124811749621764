import React, { useEffect, useRef } from 'react';

import { useUnleashClient } from '@unleash/proxy-client-react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../hooks/root-store';
import { UserDetails } from '../../types';

type ContextPayload = {
    userId?: string;
    userOrg?: string;
};

const getContextPayload = (userDetails?: UserDetails): ContextPayload => ({
    userId: userDetails?.uid,
    userOrg: userDetails?.user_org,
});

const UnleashUserContextSupervisor: React.FC = () => {
    const rootStore = useRootStore();
    const userIdRef = useRef<string | null>(null);
    const unleashClient = useUnleashClient();

    useEffect(() => {
        const updateContext = async (contextPayload: ContextPayload) => {
            await unleashClient.updateContext({ userId: contextPayload.userId, properties: contextPayload });
        };

        if (!rootStore?.userDetails?.uid) {
            userIdRef.current = null;
            updateContext({}).catch(() => {
                console.warn('Failed to reset Unleash context.');
            });
        } else if (rootStore.userDetails.uid !== userIdRef.current) {
            userIdRef.current = rootStore.userDetails.uid;
            const newContext = getContextPayload(rootStore.userDetails);

            updateContext(newContext).catch(() => {
                console.warn('Failed to update Unleash context.');
            });
        }
        return () => {
            if (!rootStore?.userDetails?.uid) {
                userIdRef.current = null;
                updateContext({}).catch(() => {
                    console.warn('Failed to reset Unleash context in clear effect.');
                });
            }
        };
    }, [rootStore?.userDetails, unleashClient]);

    return null;
};

export default observer(UnleashUserContextSupervisor);
