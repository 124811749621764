import { SxProps } from '@mui/material/styles';

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name: string, sx?: SxProps) {
    const splitName = name.split(' ');
    let nameToDisplay;
    if (splitName.length < 2) {
        nameToDisplay = `${splitName[0][0]}`;
    } else {
        nameToDisplay = `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
            ...(sx || {}),
        },
        children: nameToDisplay,
    };
}
