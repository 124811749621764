import { LineSeriesOption, CustomSeriesOption } from 'echarts/charts';
import { GridComponentOption, TooltipComponentOption, GraphicComponentOption } from 'echarts/components';
import * as echarts from 'echarts/core';

import { ChangesOverTimeRecord } from '../../types';

export enum AggregationLevel {
    DAYS = 'day',
    WEEKS = 'week',
    MONTHS = 'month',
}

export type AggregatedData = [string, number][];
export type AggregatedMarkedPoints = ([string, number] | [])[];

export interface AggregateParams {
    skipCutoff?: boolean;
}
export type AggregationFunction = (data: ChangesOverTimeRecord[], params?: AggregateParams) => AggregatedData;

export type MarkedEventChartData = [string, number];

export type ChartOption = echarts.ComposeOption<
    GridComponentOption | LineSeriesOption | TooltipComponentOption | GraphicComponentOption | CustomSeriesOption
>;

export interface IUpdateAxisInfo {
    axisDim: string;
    axisIndex: number;
    value: string | number;
}

export interface IUpdateAxisPointerEvent {
    type: 'updateaxispointer';
    axesInfo: IUpdateAxisInfo[];
    dataIndex?: number;
    dataIndexInside?: number;
    seriesIndex?: number;
}

export interface IMarkedEventData {
    date: string;
    description: string;
    isNew: boolean;
}

export interface IMarkedEventFormData {
    viewId: string;
    cluster: string;
    day: number;
    description: string;
}

export interface IDeleteMarkedEventData {
    viewId: string;
    cluster: string;
}
