import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ClusterFieldNamesMapping } from '../../../types';

import GroupTitle from './GroupTitle';
import { BASE_SX } from './consts';

import style from './GroupBySelector.module.scss';

interface GroupBySelectorProps {
    maxLevel: number;
    fields: string[];
    selectedFields: string[];
    disabled: boolean;
    changeGroupBy: (index: number, value: string) => void;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
}

const GroupBySelector = (props: GroupBySelectorProps) => {
    const { maxLevel, fields, selectedFields, disabled, clusterFieldNamesMapping, changeGroupBy } = props;
    const { t } = useTranslation();

    const handleSelectChange = (i: number) => (e: SelectChangeEvent) => changeGroupBy(i, e.target.value);

    return (
        <div className={style.wrapper}>
            <GroupTitle>{t('explorerConfigPanel.configHeaders.groupBy')}</GroupTitle>

            {new Array(maxLevel).fill(null).map((_, i) => (
                <FormControl size="small" key={i}>
                    <Select
                        value={selectedFields[i] || ''}
                        disabled={disabled}
                        onChange={handleSelectChange(i)}
                        sx={{ minWidth: 100, mr: 2, ...BASE_SX }}
                        autoWidth
                    >
                        <MenuItem value="" sx={BASE_SX}>
                            <em>{t('explorerConfigPanel.noneValue')}</em>
                        </MenuItem>

                        {fields.map((f) => {
                            const displayValue = clusterFieldNamesMapping ? clusterFieldNamesMapping[f] || f : f;

                            return (
                                <MenuItem
                                    value={f}
                                    key={f}
                                    sx={BASE_SX}
                                    disabled={selectedFields.findIndex((selectedF) => selectedF === f) in [-1, i]}
                                >
                                    {displayValue}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            ))}

            {maxLevel === 0 && (
                <FormControl size="small">
                    <Select defaultValue="" disabled={disabled} sx={{ minWidth: 100, m: 1, ...BASE_SX }} autoWidth>
                        <MenuItem value="" sx={BASE_SX}>
                            <em>{t('explorerConfigPanel.noneValue')}</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            )}
        </div>
    );
};

export default GroupBySelector;
