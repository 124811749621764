import { useContext } from 'react';

import { MosaicStoreContext } from '../stores/MosaicStore';

export const useRootStore = () => {
    const context = useContext(MosaicStoreContext);

    if (!context) {
        throw new Error('The `useRootStore` hook must be used within MosaicStoreContextProvider');
    }

    return context;
};
