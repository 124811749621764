import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { ModalProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import { Field, FieldProps, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { useRootStore } from '../../../../../../../hooks/root-store';
import { useConfirm } from '../../../../../../../hooks/useConfirm';
import { IMarkedEventFormData } from '../../../../../../../stores/changesOverTime/types';
import { StyledInput } from '../../../../../../common/Input/StyledInput';
import { StyledSelect } from '../../../../../../common/StyledSelect/StyledSelect';

import { ReactComponent as DeleteIcon } from './delete.svg';
import { validationSchema } from './validation-schema';

import styles from './EditMarkedEventsModal.module.scss';

export interface IEditMarkedEventsModalProps extends Pick<ModalProps, 'container'> {}

const EditMarkedEventsModalComponent: React.FC<IEditMarkedEventsModalProps> = ({ ...modalProps }) => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const {
        currentView,
        clustersStore: { currentLeaf },
        changesOverTimeStore: { markedEventsStore: store },
    } = rootStore;
    const [ConfirmationDialog, confirm] = useConfirm({
        container: modalProps.container,
        message: t('changesOverTime.editMarkedEventsModal.deleteConfirmationMessage'),
    });

    if (!store.currentMarkedEvent || !currentView || !currentLeaf) {
        return null;
    }

    const {
        currentMarkedEvent: { date, description, isNew },
    } = store;

    const eventDate = moment(date);
    const initialFormState: IMarkedEventFormData = {
        viewId: currentView.id,
        cluster: currentLeaf.label,
        day: isNew ? 1 : eventDate.date(),
        description,
    };

    const daysOfMonth: string[] = new Array(eventDate.daysInMonth()).fill('').map((_, index) => String(index + 1));

    const handleDeleteButtonClick = async () => {
        const confirmed = await confirm();

        if (confirmed) {
            await store.deleteEventFlow({
                viewId: currentView.id,
                cluster: currentLeaf.label,
            });
        }
    };

    const handleFormSubmit = async (formData: IMarkedEventFormData) => {
        if (isNew) {
            await store.createNewEventFlow(formData);
        } else {
            await store.updateEventFlow(formData);
        }
    };

    return (
        <>
            <Dialog
                {...modalProps}
                open={store.isModalOpen}
                slotProps={{
                    backdrop: {
                        style: {
                            position: 'absolute',
                            backgroundColor: 'rgba(0, 0, 0, 0.10)',
                        },
                    },
                }}
                classes={{ root: styles.dialogRoot, paper: styles.dialogPaperRoot }}
            >
                <DialogContent className={styles.wrapper}>
                    <Typography variant="widget-header" color="widgetTitle.light">
                        {t('changesOverTime.editMarkedEventsModal.title')}
                    </Typography>
                    <Formik
                        initialValues={initialFormState}
                        onSubmit={handleFormSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ submitForm, isSubmitting, errors, touched }) => (
                            <>
                                <Box className={styles.date}>
                                    <Field name="day">
                                        {({ field }: FieldProps<number>) => (
                                            <StyledSelect
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                disabled={!isNew}
                                                MenuProps={{
                                                    classes: {
                                                        paper: styles.dayDropdown,
                                                    },
                                                }}
                                            >
                                                {daysOfMonth.map((day) => (
                                                    <MenuItem key={day} value={day}>
                                                        {day}
                                                    </MenuItem>
                                                ))}
                                            </StyledSelect>
                                        )}
                                    </Field>
                                    <Typography color="base.700" fontWeight={700}>
                                        {t('changesOverTime.editMarkedEventsModal.ofDate', {
                                            date: eventDate.format('MMMM YYYY'),
                                        })}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        color="darkGray.main"
                                        fontWeight={500}
                                        fontSize="14px"
                                        lineHeight="20px"
                                    >
                                        {t('changesOverTime.editMarkedEventsModal.description')}
                                    </Typography>
                                    <Field name="description">
                                        {({ field }: FieldProps<string>) => (
                                            <StyledInput
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder={t(
                                                    'changesOverTime.editMarkedEventsModal.descriptionPlaceholder',
                                                )}
                                                error={!!errors.description && touched.description}
                                            />
                                        )}
                                    </Field>
                                </Box>
                                <Box className={styles.buttons}>
                                    <Box className={styles.buttonsLeft}>
                                        {!isNew && (
                                            <Button
                                                variant="mosaic-transparent"
                                                startIcon={<DeleteIcon />}
                                                onClick={handleDeleteButtonClick}
                                                className={styles.deleteButton}
                                            >
                                                {t('changesOverTime.editMarkedEventsModal.deleteButton')}
                                            </Button>
                                        )}
                                    </Box>
                                    <Box className={styles.buttonsRight}>
                                        <Button variant="mosaic-secondary" onClick={store.closeModal}>
                                            {t('changesOverTime.editMarkedEventsModal.closeButton')}
                                        </Button>
                                        <LoadingButton
                                            variant="mosaic-primary"
                                            onClick={submitForm}
                                            loading={isSubmitting}
                                        >
                                            {t('changesOverTime.editMarkedEventsModal.saveButton')}
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog />
        </>
    );
};

export const EditMarkedEventsModal = observer(EditMarkedEventsModalComponent);
