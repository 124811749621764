/**
 * Credits to RTK Query
 * Returns true if the passed value is "plain" object, i.e. an object whose
 * prototype is the root `Object.prototype`. This includes objects created
 * using object literals, but not for instance for class instances.
 *
 * @param {any} value The value to inspect.
 * @returns {boolean} True if the argument appears to be a plain object.
 *
 */
export const isPlainObject = (value: unknown): value is object => {
    if (typeof value !== 'object' || value === null) return false;

    let proto = Object.getPrototypeOf(value);
    if (proto === null) return true;

    let baseProto = proto;
    while (Object.getPrototypeOf(baseProto) !== null) {
        baseProto = Object.getPrototypeOf(baseProto);
    }

    return proto === baseProto;
};

/**
 * Creates a stable JSON.Stringify for consistent hash results
 *
 * @param queryArgs
 * @returns {string}
 */
export const hashData = (queryArgs: unknown) => {
    return JSON.stringify(queryArgs, (key, value) =>
        isPlainObject(value)
            ? Object.keys(value)
                  .sort()
                  .reduce<any>((acc, key) => {
                      acc[key] = (value as any)[key];
                      return acc;
                  }, {})
            : value,
    );
};
