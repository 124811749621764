import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminPortal, useAuth } from '@frontegg/react';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { ROLE_ADMIN } from '../../../auth/consts';
import { useLogoutUser } from '../../../hooks/useLogoutUser';
import { mixpanelService } from '../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../services/mixpanel/types';

import { stringAvatar } from './helpers';

import style from './UserAvatar.module.scss';

interface UserAvatarProps {
    userName: string;
    email: string;
    sx?: SxProps;
}

const UserAvatar = (props: UserAvatarProps) => {
    const { userName, email, sx } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { user } = useAuth();
    const { t } = useTranslation();
    const open = Boolean(anchorEl);
    const showFronteggAdminPanelItem = user?.roles.find((r) => r.key === ROLE_ADMIN);
    const { logoutUser } = useLogoutUser();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onOpenAdminPanelClick = () => {
        AdminPortal.show();
        handleClose();
    };

    const onSignOut = useCallback(() => {
        mixpanelService.track({ event: MixpanelEvent.LOG_OUT_MOSAIC });

        logoutUser();
    }, [logoutUser]);

    return (
        <div>
            <IconButton onClick={handleClick} size="small">
                <Avatar {...stringAvatar(userName, sx)} />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { minWidth: 270 } }}>
                <Box sx={{ display: 'flex', p: 1 }}>
                    <Avatar {...stringAvatar(userName, sx)} />
                    <Box sx={{ pl: 1 }}>
                        <Typography component="div" className={style.name}>
                            {userName}
                        </Typography>
                        <Typography gutterBottom component="div" className={style.email}>
                            {email}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                {showFronteggAdminPanelItem && (
                    <MenuItem onClick={onOpenAdminPanelClick} className={style.menuItem}>
                        <AdminPanelSettingsOutlinedIcon sx={{ mr: 1 }} />
                        {t('userAvatar.menu.adminPanel')}
                    </MenuItem>
                )}

                <MenuItem onClick={onSignOut} className={style.menuItem}>
                    <LogoutIcon sx={{ mr: 1 }} />
                    {t('userAvatar.menu.signOut')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserAvatar;
