import React from 'react';
import { useTranslation } from 'react-i18next';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { SKELETON_COLOR } from '../../../../consts';
import { CLUSTER_SIZE_FILTER_NAME, DEFAULT_MIN_CLUSTER_SIZE } from '../../../../helper/consts';
import { useRootStore } from '../../../../hooks/root-store';
import GroupTitle from '../GroupTitle';

import SliderSelector from './SliderSelector';

import style from './ClusterSizeSelector.module.scss';

interface FilterFormData {
    selectedMinClusterSize: number;
}

interface ClusterSizeSelectorProps {
    dataLoading: boolean;
    resetConfig: () => void;
}

const ClusterSizeSelector = observer((props: ClusterSizeSelectorProps) => {
    const { dataLoading, resetConfig } = props;
    const mosaicStore = useRootStore();
    const { t } = useTranslation();

    const onSubmit = (data: FilterFormData) => {
        mosaicStore.setSelectedMinClusterSize(data.selectedMinClusterSize);
    };

    if (!mosaicStore.selectedMinClusterSize) {
        const translatedFieldName = t(`filters.commonFilterFields.${CLUSTER_SIZE_FILTER_NAME}`);

        return (
            <div className={style.commonWrapper}>
                <GroupTitle>{translatedFieldName}</GroupTitle>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{ height: 57, width: 327, backgroundColor: SKELETON_COLOR }}
                />
            </div>
        );
    }

    const initialValues: FilterFormData = {
        selectedMinClusterSize: mosaicStore.selectedMinClusterSize || DEFAULT_MIN_CLUSTER_SIZE,
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, submitForm, resetForm }) => (
                <Form className={style.commonWrapper}>
                    <SliderSelector
                        name="selectedMinClusterSize"
                        fieldName={CLUSTER_SIZE_FILTER_NAME}
                        disabled={dataLoading}
                        valueError={errors.selectedMinClusterSize}
                        min={mosaicStore.minClusterSize}
                        max={mosaicStore.maxClusterSize}
                        submitDisabled={dataLoading || Boolean(errors.selectedMinClusterSize)}
                        submitForm={submitForm}
                    />
                    <Divider sx={{ margin: '0 -16px' }} />
                    <div>
                        <Button
                            variant="text"
                            color="neutral"
                            disabled={dataLoading}
                            onClick={() => {
                                resetForm();
                                resetConfig();
                                submitForm();
                            }}
                        >
                            <RestartAltIcon sx={{ fontSize: 20 }} />
                            <span className={style.resetChanges}>{t('filters.resetChanges')}</span>
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
});

export default ClusterSizeSelector;
