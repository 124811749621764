import React from 'react';

import Divider from '@mui/material/Divider';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../../hooks/root-store';
import { mixpanelService } from '../../../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../../../services/mixpanel/types';
import {
    AppliedFilter,
    CustomerView,
    LeafMosaicRecord,
    WeightedLeafMosaicRecordWithParent,
} from '../../../../../../types';
import { BaseCard, IBaseCardProps } from '../../Cards/BaseCard/BaseCard';

import { Actions } from './Actions/Actions';
import { DirectAnswer } from './DirectAnswer/DirectAnswer';
import { WidgetTitle } from './Title/WidgetTitle';
import { WidgetLoader } from './WidgetLoader/WidgetLoader';

import styles from './ExecutiveSummaryWidget.module.scss';

interface ExecutiveSummaryWidgetComponentProps {
    currentView?: CustomerView;
    filters: AppliedFilter[] | null;
    detailsPanelItem: WeightedLeafMosaicRecordWithParent | undefined;
    selectedGroupByFields?: string[];
    executiveSummaryRequestId?: LeafMosaicRecord['cluster']['executiveSummaryRequestId'];
    executiveSummaryCustomerName?: LeafMosaicRecord['cluster']['executiveSummaryCustomerName'];
}

const MIN_CLUSTER_SIZE_TO_DISPLAY_DIRECT_ANSWER = parseInt(
    process.env.REACT_APP_MIN_CLUSTER_SIZE_TO_DISPLAY_DIRECT_ANSWER!,
    10,
);

const ExecutiveSummaryWidgetComponent: React.FC<ExecutiveSummaryWidgetComponentProps> = (props) => {
    const {
        executiveSummaryRequestId: questionId,
        executiveSummaryCustomerName: customerProjectId,
        detailsPanelItem,
        currentView,
        filters,
        selectedGroupByFields,
    } = props;
    const { directAnswerStore, clustersStore } = useRootStore();

    const handleCollapseChange: IBaseCardProps['onCollapseStateChange'] = (collapsed) => {
        if (detailsPanelItem && currentView) {
            mixpanelService.track({
                event: collapsed
                    ? MixpanelEvent.DETAIL_PANEL_EXECUTIVE_SUMMARY_COLLAPSE
                    : MixpanelEvent.DETAIL_PANEL_EXECUTIVE_SUMMARY_EXPEND,
                meta: {
                    executive_summary_request_id: questionId,
                    executive_summary_customer_name: customerProjectId,
                    view_id: currentView.id,
                    cluster_name: clustersStore.selectedClusterName,
                    filters: toJS(filters),
                    group_by: selectedGroupByFields,
                    items_count: clustersStore.selectedClusterItemsCount,
                },
            });
        }
    };

    React.useEffect(() => {
        if (!customerProjectId || !questionId) {
            return;
        }

        const params = {
            customer_project_id: customerProjectId,
            question_id: questionId,
        };

        const stopListenDirectAnswer = directAnswerStore.startListenSSEDirectAnswer(params);

        return () => {
            stopListenDirectAnswer();
        };
    }, [directAnswerStore, customerProjectId, questionId]);

    const canShowSkeleton = (detailsPanelItem?.cluster?.itemsCount || 0) > MIN_CLUSTER_SIZE_TO_DISPLAY_DIRECT_ANSWER;
    const showLoader = !detailsPanelItem?.cluster?.detailPaneItems && canShowSkeleton;
    const showAnswer = questionId && customerProjectId && !showLoader;

    const nothingToDisplay = questionId === null && customerProjectId === null;
    if (nothingToDisplay || (!showLoader && !showAnswer)) {
        return null;
    }

    const directAnswerJS = toJS(directAnswerStore.directAnswer);

    return (
        <>
            <BaseCard
                title={<WidgetTitle />}
                collapsible
                onCollapseStateChange={handleCollapseChange}
                className={styles.wrapper}
            >
                {directAnswerJS && showAnswer ? (
                    <DirectAnswer
                        answer={directAnswerJS}
                        questionId={questionId}
                        customerProjectId={customerProjectId}
                        actions={
                            <Actions
                                questionId={questionId}
                                customerProjectId={customerProjectId}
                                answer={directAnswerJS}
                            />
                        }
                    />
                ) : (
                    <WidgetLoader />
                )}
            </BaseCard>
            <Divider variant="widget" />
        </>
    );
};

export const ExecutiveSummaryWidget = observer(ExecutiveSummaryWidgetComponent);
