export const widgetTitle = 'Percentage of items created over time';
export const editMarkedEventsModal = {
    title: 'Marked Events',
    closeButton: 'Close',
    saveButton: 'Save',
    deleteButton: 'Delete',
    ofDate: 'of {{date}}',
    description: 'Description',
    descriptionPlaceholder: 'e.g. bug fix, product update...',
    deleteConfirmationMessage: 'Are sure you want to delete this event?',
};

export const markedEventsFilter = {
    title: 'Marked Clusters',
};

export const chartTooltip = '% of items: {{value}}%';
