import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Chip, CircularProgress, IconButton, TextField } from '@mui/material';
import { debounce } from 'lodash';

import api from '../../../../../../api';

import { ListboxComponent, StyledPopper } from './AsyncRenderList';

interface FilterAutocompleteProps {
    name: string;
    value: string[];
    field: string;
    viewId: string;
    revision: string | null;
    error: boolean;
    errorMessage: string | undefined;
    setFieldValue: (field: string, value: string[]) => void;
}

const FilterAutocomplete = (props: FilterAutocompleteProps) => {
    const { name, value, field, viewId, revision, error, errorMessage, setFieldValue } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<string[]>([]);
    const getSuggestions = async (typedValue: string, field: string) => {
        setIsLoading(true);

        try {
            const values = await api.getAutocompleteSuggestion(viewId, field, typedValue, revision);
            setOptions(values.values.map((v) => v.value));
        } catch (e) {
            console.warn(e);
        } finally {
            setIsLoading(false);
        }
    };
    const debouncedGetSuggestions = useRef(debounce(getSuggestions, 700)).current;

    return (
        <Autocomplete
            multiple
            limitTags={2}
            fullWidth
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t('filters.valuePlaceholder')}
                    error={error}
                    helperText={error && errorMessage && t(errorMessage, { defaultValue: errorMessage })}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            onOpen={() => {
                setOptions([]);
                getSuggestions('', field);
            }}
            loading={isLoading}
            value={value}
            onInputChange={(_e, typedValue) => {
                debouncedGetSuggestions(typedValue, field);
            }}
            onChange={(_e, newValues) => {
                setFieldValue(name, newValues);
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const props = getTagProps({ index });

                    return (
                        <Chip
                            label={option}
                            {...props}
                            sx={{
                                background: '#DCEBFE',
                                color: '#52749F',
                                fontSize: 12,
                                fontWeight: 500,
                                span: {
                                    paddingLeft: '4px',
                                },
                            }}
                            // Set our own delete button
                            avatar={
                                <IconButton onClick={props.onDelete}>
                                    <CloseIcon sx={{ color: '#52749F', fontSize: 16 }} />
                                </IconButton>
                            }
                            // Remove the delete button
                            onDelete={undefined}
                        />
                    );
                })
            }
            ListboxComponent={ListboxComponent}
            disableListWrap
            renderOption={(props, option, { inputValue }) => [props, option, inputValue] as React.ReactNode}
            PopperComponent={StyledPopper}
            loadingText={t('filters.loadingAutocompleteText')}
        />
    );
};

export default FilterAutocomplete;
