import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { SKELETON_COLOR } from '../../../consts';

import style from './SkeletonTreemapLegend.module.scss';

const numberOfFakeEntries = 15;

const SkeletonTreemapLegend = () => {
    const fakeEntries = new Array(numberOfFakeEntries)
        .fill(null)
        .map((_, i) => (
            <Skeleton key={i} animation="wave" className={style.entry} sx={{ backgroundColor: SKELETON_COLOR }} />
        ));

    return (
        <div className={style.legendWrapper}>
            <Skeleton animation="wave" className={style.entry} sx={{ backgroundColor: SKELETON_COLOR }} />
            <div style={{ padding: '0.3em 0 0 0' }}>{fakeEntries}</div>
        </div>
    );
};

export default SkeletonTreemapLegend;
