import merge from 'lodash/merge';

import { AggregationLevel, ChartOption } from '../types';

import { getAxisFormatter } from './formatters';

const baseOption: ChartOption = {
    grid: {
        left: '3%',
        right: '3%',
        top: '12%',
        bottom: '6%',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            color: '#404B5C',
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#404B5C',
            formatter: (value: string) => `${value}%`,
        },
        splitLine: {
            show: true,
        },
    },
};

export const prepareChartOption = (aggregationLevel: AggregationLevel): ChartOption => {
    const formatter = getAxisFormatter(aggregationLevel);

    const axisOptions: ChartOption['xAxis'] = {
        axisLabel: {
            formatter: formatter(),
        },
    };

    const xAxis = merge(baseOption.xAxis, axisOptions);

    return {
        ...baseOption,
        yAxis: {
            ...baseOption.yAxis,
        },
        xAxis,
    };
};
