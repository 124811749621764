import { CustomSeriesOption, LineSeriesOption } from 'echarts/charts';

import { IGetChartSeriesParams } from '../../../types';

import { getMarkedEventsSeriesOption } from './custom-series/marked-events-series';

export const getChartSeries = (params: IGetChartSeriesParams): (LineSeriesOption | CustomSeriesOption)[] => {
    const { data } = params;

    return [
        {
            data,
            type: 'line',
            name: 'items',
            smooth: 0.3,
            showSymbol: true,
            color: '#ff8286',
            connectNulls: true,
            animationDurationUpdate: 0,
            emphasis: {
                disabled: true,
            },
            animation: false,
            z: 1,
            tooltip: {
                show: false,
            },
        },
        {
            ...getMarkedEventsSeriesOption(params),
        },
    ];
};
