import { CLUSTER_SIZE_FILTER_NAME } from '../../helper/consts';
import { SemanticType } from '../../types';

export const operators = {
    is: 'is',
    is_not: 'is not',
    lt: 'less than',
    lte: 'less than or equal to',
    gt: 'greater than',
    gte: 'greater than or equal to',
    contains: 'contains',
    does_not_contain: 'does not contain',
    last7Days: 'last 7 days',
    last30Days: 'last 30 days',
    is_empty: 'is empty',
    is_not_empty: 'is not empty',
};

export const operatorsPerSemanticType = {
    [SemanticType.DATE]: {
        is: 'is on',
        is_not: 'is not on',
        lt: 'before',
        lte: 'before or on',
        gt: 'after',
        gte: 'after or on',
    },
};

export const commonFilterFields = {
    [CLUSTER_SIZE_FILTER_NAME]: 'Granularity',
};

export const commonFilterFieldsSuffix = {
    [CLUSTER_SIZE_FILTER_NAME]: 'items',
};

export const validationErrors = {
    required: 'Value is required',
    min: 'Min value must be {{value}}',
    max: 'Max value must be {{value}}',
    number: 'Value must be a number',
    string: 'Value must be a string',
    date: 'Value must be a date',
    array: 'At least 1 value must be selected',
};

export const predefinedValues = {
    bool: {
        true: 'True',
        false: 'False',
    },
};

export const logicalOperators = {
    intersection: 'And',
    union: 'Or',
};

export const submit = 'Apply';
export const resetChanges = 'Reset changes';
export const title = 'Filters';
export const addFilter = 'Add filter';
export const valuePlaceholder = 'Value';
export const fieldPlaceholder = 'Field';
export const operatorPlaceholder = 'Operator';
export const booleanPlaceholder = 'Boolean';
export const clearAll = 'Clear All';
export const popoverTitle = 'Filter mosAIc';
export const close = 'Close';
export const loadingAutocompleteText = 'Loading...';
