import { CustomSeriesOption } from 'echarts/charts';

import { IGetChartSeriesParams } from '../../../../types';
import { AggregatedMarkedPoints, AggregationLevel } from '../../types';
import { getMarkedEventTooltipFormatter, getTooltipFormatter } from '../formatters';
import {
    ADD_POINTER_IMAGE_SIZE,
    addPointerImage,
    addPointerImageHovered,
    MARKED_EVENT_POINT_IMAGE_HEIGHT,
    MARKED_EVENT_POINT_IMAGE_WIDTH,
    markedEventPoint,
    markedEventPointHovered,
} from '../images/marked-events-images';

const MARKED_EVENT_POINT_VERTICAL_GAP = 5;
export const MARKED_EVENTS_SERIES_NAME = 'marked-events';
export const ADD_MARKED_EVENTS_SERIES_NAME = 'add-marked-events';

export const getMarkedEventsSeriesOption = (params: IGetChartSeriesParams): CustomSeriesOption => {
    const { markedEvents, getMarkedEventDescription } = params;

    return {
        data: markedEvents,
        type: 'custom',
        name: MARKED_EVENTS_SERIES_NAME,
        renderItem: (params, api) => {
            const gridX = params.dataIndex;
            const gridY = api.value(1);

            if (isNaN(gridY as number)) {
                return undefined;
            }

            const [x, y] = api.coord([gridX, gridY]);

            return {
                type: 'image',
                style: {
                    image: markedEventPoint,
                    x: x - MARKED_EVENT_POINT_IMAGE_WIDTH / 2,
                    y: y - MARKED_EVENT_POINT_IMAGE_HEIGHT - MARKED_EVENT_POINT_VERTICAL_GAP,
                },
                emphasis: {
                    style: {
                        image: markedEventPointHovered,
                    },
                },
                z: 1,
            };
        },
        tooltip: {
            show: true,
            formatter: getMarkedEventTooltipFormatter(getMarkedEventDescription),
        },
    };
};

export const getAddMarkedEventsSeriesOption = (
    data: AggregatedMarkedPoints,
    aggregation: AggregationLevel,
): CustomSeriesOption => ({
    data,
    type: 'custom',
    name: ADD_MARKED_EVENTS_SERIES_NAME,
    renderItem: (params, api) => {
        const gridX = params.dataIndex;
        const gridY = api.value(1);

        if (isNaN(gridY as number)) {
            return undefined;
        }

        const [x, y] = api.coord([gridX, gridY]);

        return {
            type: 'image',
            style: {
                image: addPointerImage,
                x: x - ADD_POINTER_IMAGE_SIZE / 2,
                y: y - ADD_POINTER_IMAGE_SIZE / 2,
            },
            emphasis: {
                style: {
                    image: addPointerImageHovered,
                },
            },
            z: 2,
        };
    },
    tooltip: {
        show: true,
        formatter: getTooltipFormatter(aggregation),
    },
});
