import { api as fronteggApi } from '@frontegg/rest-api';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import api from '../../api';
import { hashData } from '../../helper/stableQueryArgs';
import { DirectAnswer, DirectAnswerStatus, GetDirectAnswerRequestParams } from '../../types';

let timeoutId: NodeJS.Timeout | undefined = undefined;
const MAX_IDLE_TIME = 20000; // 20 seconds

export class DirectAnswerStore {
    private readonly apiUrl: string;
    private readonly stopStreamAnswerStatuses = [DirectAnswerStatus.FAILED, DirectAnswerStatus.DONE];

    private cache: Record<string, DirectAnswer> = {};

    constructor() {
        makeAutoObservable(this);
        const url = new URL(process.env.REACT_APP_DIRECT_ANSWER_API_URL ?? '');

        this.apiUrl = url.origin;
    }

    @observable
    public directAnswer?: DirectAnswer;

    @action
    public startListenSSEDirectAnswer(params: GetDirectAnswerRequestParams) {
        const { customer_project_id, question_id } = params;

        const key = hashData(params);
        const controller = new AbortController();

        const resetTimeout = () => {
            clearTimeout(timeoutId);

            timeoutId = undefined;
        };

        const cleanCallback = (reason?: string) => {
            resetTimeout();
            runInAction(() => {
                this.directAnswer = undefined;

                if (!controller.signal.aborted) {
                    controller.abort(reason ?? `Aborted by 'cleanCallback'`);
                }
            });
        };

        const setTerminateByTimeout = () => {
            // reset prev timeout
            resetTimeout();

            // set a new timeout to terminate stream in case, will clean timeoutId on cleanCallback
            timeoutId = setTimeout(() => {
                cleanCallback('Terminate by timeout.');
            }, MAX_IDLE_TIME);
        };

        if (this.cache[key] !== undefined) {
            // set the cached value
            this.directAnswer = this.cache[key];
        } else {
            // if the answer is not cached, we need to fetch it
            this.directAnswer = undefined;

            const handleDirectAnswer = (directAnswer: DirectAnswer | null) => {
                this.updateDirectAnswerInStore(key, directAnswer ?? undefined);

                if (directAnswer?.status && this.stopStreamAnswerStatuses.includes(directAnswer.status)) {
                    if (!controller.signal.aborted) {
                        controller.abort(`Aborted on '${directAnswer.status}' status.`);
                    }

                    resetTimeout();
                }
            };

            this.fetchDirectAnswer(params).then(async (data) => {
                if (this.stopStreamAnswerStatuses.includes(data.status)) {
                    this.updateDirectAnswerInStore(key, data);

                    // if the answer is already done or failed, we don't need to open a stream
                    return;
                }

                const token = await this.getAuthToken();
                const uri = new URL(
                    `${this.apiUrl}/direct-answer/direct_answers/${customer_project_id}/${question_id}/stream`,
                ).toString();
                const headers = {
                    Authorization: token,
                    Accept: 'text/event-stream; charset=utf-8',
                    Connection: 'keep-alive',
                    'Cache-Control': 'no-cache',
                };

                try {
                    await fetchEventSource(uri, {
                        headers,
                        signal: controller.signal,
                        openWhenHidden: true,
                        async onopen(response) {
                            if (response?.ok) {
                                setTerminateByTimeout();

                                return; // everything's good
                            }

                            console.warn('Invalid response', response, 'will NOT create a stream.');

                            throw new Error('Invalid response');
                        },
                        onerror: (error) => {
                            console.error('Failed to get direct answer stream.', error);
                            cleanCallback();

                            throw error;
                        },
                        onmessage: ({ event, data }) => {
                            setTerminateByTimeout();

                            if (!data || event !== 'generativeAnswer') {
                                return;
                            }

                            let directAnswer: DirectAnswer;

                            try {
                                directAnswer = JSON.parse(data) as DirectAnswer;
                            } catch (error) {
                                console.error('Error while parsing direct answer data', error);
                                return;
                            }

                            handleDirectAnswer(directAnswer);
                        },
                    });
                } catch (e) {
                    console.error('Failed to get direct answer stream.', e);
                    cleanCallback();
                }
            });
        }

        return cleanCallback;
    }

    public async fetchDirectAnswer(params: GetDirectAnswerRequestParams): Promise<DirectAnswer> {
        try {
            return await api.getDirectAnswer(params);
        } catch (e) {
            console.error('Failed to fetch direct answer');

            return {
                status: DirectAnswerStatus.FAILED,
                answer: '',
                is_answerable: null,
                action_type: null,
            };
        }
    }

    @action
    private updateDirectAnswerInStore(cacheKey: string, directAnswer?: DirectAnswer) {
        this.directAnswer = directAnswer;

        if (directAnswer?.status === DirectAnswerStatus.DONE) {
            this.cache[cacheKey] = directAnswer;
        }
    }

    /**
     * Get the auth token.
     *
     * returns value in format  'Bearer {auth_token}'.
     *
     * @private
     *
     * @return {string}
     */
    private async getAuthToken(): Promise<string> {
        let token = '';

        const tokenFromAPI = api.getToken();
        if (tokenFromAPI) {
            token = tokenFromAPI as string;
        } else {
            try {
                const response = await fronteggApi.auth.silentOAuthRefreshToken();

                const accessToken = response?.accessToken ?? '';
                if (!accessToken) {
                    throw new Error('Failed to get auth token');
                }

                token = `Bearer ${accessToken}`;
            } catch (e) {
                console.error('WS: Error in getAuthToken', e);
            }
        }

        return token;
    }

    @action
    public resetCache() {
        this.cache = {};
    }
}
