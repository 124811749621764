import React from 'react';

export type FakeTypewriterProps = {
    text: string;
    delay?: number;
    onDone?: () => void;
};

export const FakeTypewriter: React.FC<FakeTypewriterProps> = ({ text, onDone, delay = 4 }) => {
    const [charToShow, setCharToShow] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCharToShow((prev) => {
                if (prev === text.length) {
                    clearInterval(interval);

                    if (typeof onDone === 'function') {
                        queueMicrotask(onDone);
                    }

                    return prev;
                }
                return prev + 1;
            });
        }, delay);
        return () => clearInterval(interval);
    }, [text, onDone, delay]);

    return <>{text.slice(0, charToShow)}</>;
};
