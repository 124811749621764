
import React, { memo, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { formatters, functions, undefinedColor } from '../../../helper/consts';
import getColors from '../../../helper/getColor';
import log10 from '../../../helper/log10';
import { ClusterFieldNamesMapping, Field } from '../../../types';
import LightTooltip from '../../common/LightTooltip';
import SkeletonTreemapLegend from '../SkeletonTreemapLegend';

import { maxLegendEntries, stepsPerMagnitude } from './consts';

import style from './TreemapLegend.module.scss';


interface TreemapLegendProps {
    min: number;
    max: number;
    hasUndefined: boolean;
    isLoading: boolean;
    palette: string | undefined;
    fnName: string | undefined;
    formatterName: string | undefined;
    field: Field | undefined;
    attributeName: string | undefined;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
}

interface LegendEntry {
    color: string;
    value: any;
}

const TreemapLegend = (props: TreemapLegendProps) => {
    const {
        min,
        max,
        palette,
        hasUndefined,
        fnName,
        formatterName,
        field,
        attributeName,
        clusterFieldNamesMapping,
        isLoading,
    } = props;
    const [legendEntries, setLegendEntries] = useState<LegendEntry[]>([]);

    useEffect(() => {
        if (!palette || !fnName || !formatterName || !field || !attributeName) {
            return;
        }

        const newLegendEntries = [];
        const formatter = formatters[formatterName];
        const colors = getColors(palette);
        const fn = functions[fnName];
        const range = fn(max - min) / (colors.length - 1);

        const rawIncrement = (max - min) / maxLegendEntries;
        const magnitude = Math.pow(10, Math.ceil(log10(rawIncrement)));
        const increment = (magnitude * Math.ceil((stepsPerMagnitude * rawIncrement) / magnitude)) / stepsPerMagnitude;

        const startIndex = colors.length - 1;
        const endIndex = 0;

        newLegendEntries.push({ color: '#' + colors[startIndex], value: formatter(max, field) });
        let value = increment * Math.ceil((max - increment) / increment);

        while (value > min) {
        
            const colorIndex = Math.round(fn(value - min) / range);

            newLegendEntries.push({
                color: '#' + colors[colorIndex],
                value: formatter(value, field),
            });
            value -= increment;
        }
        newLegendEntries.push({ color: '#' + colors[endIndex], value: formatter(min, field) });

        if (hasUndefined) {
            newLegendEntries.push({ color: undefinedColor, value: 'n/a' });
        }

        const uniqEntries: LegendEntry[] = Array.from(
            new Map(newLegendEntries.map((item) => [item.value, item])).values(),
        );
        setLegendEntries(uniqEntries);
    }, [setLegendEntries, min, max, palette, hasUndefined, fnName, formatterName, field, attributeName]);

    if (isLoading) {
        return <SkeletonTreemapLegend />;
    }

    if (!palette || !fnName || !formatterName || !field || !attributeName) {
        return null;
    }

    const displayValue = clusterFieldNamesMapping
        ? clusterFieldNamesMapping[attributeName] || attributeName
        : attributeName;

    return (
        <div className={style.legendWrapper}>
            <Scrollbars autoHide>
                <LightTooltip title={displayValue} arrow placement="right">
                    <div className={style.legendTitle}>{displayValue}</div>
                </LightTooltip>

                <ul className={style.legend}>
                    {legendEntries.map((e) => (
                        <li className={style.legendEntry} key={e.color + '-' + e.value}>
                            {e.value}
                            <span style={{ backgroundColor: e.color }} className={style.colorSpan}></span>
                        </li>
                    ))}
                </ul>
            </Scrollbars>
        </div>
    );
};

export default memo(TreemapLegend);
