import React, { useCallback, useEffect, useRef } from 'react';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { LineChart, CustomChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, GraphicComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../../hooks/root-store';
import { mixpanelService } from '../../../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../../../services/mixpanel/types';
import { getClusterMeta } from '../../../../../../services/mixpanel/utils';
import {
    ADD_MARKED_EVENTS_SERIES_NAME,
    MARKED_EVENTS_SERIES_NAME,
} from '../../../../../../stores/changesOverTime/helpers/custom-series/marked-events-series';
import { AppliedFilter, CustomerView, WeightedLeafMosaicRecordWithParent } from '../../../../../../types';
import { BaseCard, IBaseCardProps } from '../../Cards/BaseCard/BaseCard';

import { EditMarkedEventsModal } from './Modals/EditMarkedEventsModal';
import { ChangesOverTimeTitle } from './Title/ChangesOverTimeTitle';
import loader from './chart-line-loader.gif';

import styles from './ChangesOverTimeWidget.module.scss';

echarts.use([GridComponent, LineChart, CanvasRenderer, TooltipComponent, GraphicComponent, CustomChart]);

interface IChangesOverTimeWidgetProps {
    currentView?: CustomerView;
    detailsPanelItem: WeightedLeafMosaicRecordWithParent | undefined;
    selectedGroupByFields: string[] | undefined;
    filters: AppliedFilter[] | null;
    revision: string | null;
}

const ChangesOverTimeWidgetComponent: React.FC<IChangesOverTimeWidgetProps> = (props: IChangesOverTimeWidgetProps) => {
    const { currentView, detailsPanelItem, selectedGroupByFields, filters, revision } = props;
    const { changesOverTimeStore: store, clustersDateRange } = useRootStore();
    const widgetCardRef = useRef<HTMLDivElement | null>(null);

    const setRef = useCallback(
        (node: EChartsReactCore | null) => {
            if (store.chart?.ele) {
                const chartInstance = store.chart.getEchartsInstance();
                const zr = chartInstance.getZr();

                zr.off('mousemove', store.handleChartMouseMove);
                chartInstance.off('click', store.handleAddMarkedEventClick);
                chartInstance.off('click', store.handleMarkedEventClick);
            }

            if (node) {
                const chartInstance = node.getEchartsInstance();
                const zr = chartInstance.getZr();

                zr.on('mousemove', store.handleChartMouseMove);
                chartInstance.on(
                    'click',
                    { componentType: 'series', seriesName: ADD_MARKED_EVENTS_SERIES_NAME },
                    store.handleAddMarkedEventClick,
                );
                chartInstance.on(
                    'click',
                    { componentType: 'series', seriesName: MARKED_EVENTS_SERIES_NAME },
                    store.handleMarkedEventClick,
                );
            }

            store.setChart(node);
        },
        [store],
    );

    useEffect(() => {
        if (!currentView || !detailsPanelItem || !selectedGroupByFields) {
            return;
        }

        store.loadData({
            currentView,
            detailsPanelItem,
            selectedGroupByFields,
            filters,
            revision,
            dateRange: clustersDateRange,
        });
    }, [currentView, detailsPanelItem, selectedGroupByFields, filters, revision, store, clustersDateRange]);

    const handlePointHover = useCallback(() => {
        if (!detailsPanelItem || !selectedGroupByFields) {
            return;
        }

        mixpanelService.track({
            event: MixpanelEvent.CHANGE_OVER_TIME_HOVER,
            meta: getClusterMeta(detailsPanelItem, selectedGroupByFields),
        });
    }, [detailsPanelItem, selectedGroupByFields]);

    useEffect(() => {
        store.addOnPointHoverCallback(handlePointHover);

        return () => {
            store.removeOnPointHoverCallback(handlePointHover);
        };
    }, [store, handlePointHover]);

    const handleCollapseChange: IBaseCardProps['onCollapseStateChange'] = (collapsed) => {
        if (detailsPanelItem) {
            mixpanelService.track({
                event: collapsed
                    ? MixpanelEvent.DETAIL_PANEL_CHANGE_OVER_TIME_COLLAPSE
                    : MixpanelEvent.DETAIL_PANEL_CHANGE_OVER_TIME_EXPEND,
                meta: getClusterMeta(detailsPanelItem, selectedGroupByFields ?? []),
            });
        }
    };

    const renderLoader = () => {
        return <div style={{ backgroundImage: `url("${loader}")` }} className={styles.loader} />;
    };

    const renderChart = () => {
        if (!store.visible) {
            return null;
        }

        return (
            <ReactEChartsCore
                ref={setRef}
                echarts={echarts}
                option={store.chartOption}
                notMerge={true}
                lazyUpdate={true}
                style={{
                    height: 200,
                }}
            />
        );
    };

    if (store.aggregatedData.length === 0) {
        return null;
    }

    return (
        <>
            <BaseCard
                ref={widgetCardRef}
                title={<ChangesOverTimeTitle />}
                collapsible
                onCollapseStateChange={handleCollapseChange}
                className={styles.wrapper}
            >
                <div className={styles.chart}>{store.loading ? renderLoader() : renderChart()}</div>
            </BaseCard>
            <EditMarkedEventsModal container={widgetCardRef.current} />
        </>
    );
};

export const ChangesOverTimeWidget = observer(ChangesOverTimeWidgetComponent);
