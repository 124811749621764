import { alpha, styled } from '@mui/material';
import Select from '@mui/material/Select';

export const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-input.MuiSelect-select': {
        minHeight: '28px',
        minWidth: '32px',
        padding: '6px 24px 6px 12px',
        lineHeight: '28px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.base['50'] ?? '', 0.2),
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
    },
}));
