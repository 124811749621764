import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import styles from './WidgetTitle.module.scss';

export const WidgetTitle: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.titleWrapper}>
            <Typography variant="widget-header" className={styles.title}>
                {t('detailsPanel.executiveSummaryTitle')}
            </Typography>
        </div>
    );
};
