import mixpanel from 'mixpanel-browser';

import { UserDetails } from '../../types';

import { ITrackEventData } from './types';

const token = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ?? 'c0cf69d24d268fe21c2cd6b0a012e886';
class MixpanelService {
    constructor() {
        mixpanel.init(token, { debug: process.env.NODE_ENV === 'development' });
    }

    public track(data: ITrackEventData) {
        const { event, meta } = data;

        mixpanel.track(event, meta);
    }

    public updateUserProfile(user: UserDetails) {
        const { uid, email, name, user_org } = user;

        mixpanel.identify(uid);
        mixpanel.people.set({
            $name: name,
            $email: email,
            org: user_org,
        });
    }
}

const instance = new MixpanelService();
export const mixpanelService = instance;
