import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Navigate, URLSearchParamsInit, useNavigate, useSearchParams } from 'react-router-dom';

import axios, { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import { debounce } from 'lodash';
import { runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import api from '../../api';
import eventLogger from '../../eventLogger';
import { DEFAULT_FILTERS_LOGICAL_OPERATOR, MIN_NUMBER_OF_CLUSTERS_TO_SHOW } from '../../helper/consts';
import getField from '../../helper/getField';
import getFormatterName from '../../helper/getFormatterName';
import { useRootStore } from '../../hooks/root-store';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { CustomerView, ExplorerLayout, LogicalOperator } from '../../types';
import {
    FILTERS_URL_PARAM_NAME,
    LOGICAL_OPERATOR_URL_PARAM_NAME,
    REVISION_URL_PARAM_NAME,
    routes,
    SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME,
    VIEW_ID_URL_PARAM_NAME,
} from '../consts';

import Explorer from './Explorer';
import ExplorerBlocker from './ExplorerBlocker';
import InsufficientDataBlock from './InsufficientDataBlock';
import SideMenu from './SideMenu';
import Topbar from './Topbar';
import TreemapLegend from './TreemapLegend';
import { applyCustomOperators } from './filtersHelper';
import { countClusters, handleStoreUpdate, haveUndefined } from './helpers';

import style from './Dashboard.module.scss';

const ERROR_PARAMS_LIST = ['samlerrors', 'oidcerrors'];

const Dashboard = observer(() => {
    const mosaicStore = useRootStore();
    const { sideMenuType, setSearchKeyword, foamTreeStore } = mosaicStore;
    const { clustersCount } = foamTreeStore;

    const [criticalError, setCriticalError] = useState<Error>();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const loginErrorMessage: string | undefined = ERROR_PARAMS_LIST.reduce((prev: string | undefined, cur: string) => {
        if (searchParams.has(cur)) {
            return `${searchParams.get(cur)}`;
        }

        return prev;
    }, undefined);

    const debouncedHandleStoreUpdate = useRef(debounce(handleStoreUpdate)).current;

    const loadMosaicData = useCallback(async () => {
        if (!mosaicStore || !mosaicStore.config?.groupBy || !mosaicStore.filters || !mosaicStore.currentView) {
            return;
        }

        try {
            runInAction(() => {
                mosaicStore.setIsDataLoading(true);
                mosaicStore.setShowInsufficientData(false);
                mosaicStore.clustersStore.setCurrentLeaf(null);
            });
            mosaicStore.setSideMenuType(undefined);

            const dataFromApi = await api.getMosaicData(
                mosaicStore.currentView.id,
                mosaicStore.config.groupBy,
                applyCustomOperators(toJS(mosaicStore.filters)),
                searchParams.get(REVISION_URL_PARAM_NAME),
                mosaicStore?.filtersLogicalOperator || DEFAULT_FILTERS_LOGICAL_OPERATOR,
                !Boolean(mosaicStore?.minClusterSizeResponse),
                mosaicStore?.selectedMinClusterSize,
            );
            if (!dataFromApi) {
                return;
            }

            foamTreeStore.setData(dataFromApi.clusters);
            runInAction(() => {
                if (dataFromApi.min_cluster_size) {
                    mosaicStore.setMinClusterSizeResponse(dataFromApi.min_cluster_size);
                }
                mosaicStore.setIsDataLoading(false);
                mosaicStore.setClustersDateRange(dataFromApi.date_range);
                const count = countClusters(dataFromApi.clusters);
                mosaicStore.setShowInsufficientData(count <= MIN_NUMBER_OF_CLUSTERS_TO_SHOW);
                mosaicStore.setViewItemsCount(dataFromApi.view_tickets_count);
            });

            if (!mosaicStore.mosaicMetadata) {
                return;
            }

            if (mosaicStore.mosaicMetadata.view.default_layout) {
                foamTreeStore.setSelectedLayout({
                    layout: mosaicStore.mosaicMetadata.view.default_layout,
                    skipMetrics: true,
                });
            } else {
                foamTreeStore.setSelectedLayout({
                    layout: ExplorerLayout.rectangular,
                    skipMetrics: true,
                });
            }
        } catch (e) {
            setCriticalError(e as Error);
        }
    }, [foamTreeStore, mosaicStore, searchParams]);

    const loadFiltersFromSearchParams = useCallback(
        (filtersFromParams: string | null) => {
            if (!filtersFromParams) {
                return;
            }

            try {
                const encodedFiltersFromParams = JSON.parse(Buffer.from(filtersFromParams, 'base64').toString());
                if (!isEqual(encodedFiltersFromParams, mosaicStore?.filters)) {
                    // We need to update the stored filters
                    mosaicStore?.setFilters(encodedFiltersFromParams);
                    loadMosaicData();
                }
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(FILTERS_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore, loadMosaicData],
    );

    const loadFiltersLogicalOperatorFromSearchParams = useCallback(
        (filtersLogicalOperatorFromParams: string | null) => {
            if (!filtersLogicalOperatorFromParams) {
                return;
            }

            try {
                const filtersLogicalOperator =
                    LogicalOperator[filtersLogicalOperatorFromParams as keyof typeof LogicalOperator];
                if (filtersLogicalOperator && filtersLogicalOperator !== mosaicStore?.filtersLogicalOperator) {
                    mosaicStore?.setFiltersLogicalOperator(filtersLogicalOperator);
                }
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(LOGICAL_OPERATOR_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore],
    );

    const loadSelectedMinClusterSizeFromSearchParams = useCallback(
        (selectedMinClusterSizeFromParams: string | null) => {
            if (!selectedMinClusterSizeFromParams) {
                return;
            }

            try {
                const selectedMinClusterSize = parseInt(selectedMinClusterSizeFromParams, 10);
                if (isNaN(selectedMinClusterSize)) {
                    throw new Error('Invalid selectedMinClusterSize in search params');
                }
                mosaicStore?.setSelectedMinClusterSize(selectedMinClusterSize);
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore],
    );

    useEffect(() => {
        debouncedHandleStoreUpdate(mosaicStore, searchParams, setSearchParams, loadMosaicData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mosaicStore,
        mosaicStore?.filters,
        mosaicStore?.currentView,
        mosaicStore?.selectedMinClusterSize,
        mosaicStore?.filtersLogicalOperator,
        setSearchParams,
        loadMosaicData,
        debouncedHandleStoreUpdate,
    ]);

    useEffect(() => {
        loadFiltersFromSearchParams(searchParams.get(FILTERS_URL_PARAM_NAME));
        loadFiltersLogicalOperatorFromSearchParams(searchParams.get(LOGICAL_OPERATOR_URL_PARAM_NAME));
        loadSelectedMinClusterSizeFromSearchParams(searchParams.get(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME));
    }, [
        searchParams,
        loadFiltersFromSearchParams,
        loadFiltersLogicalOperatorFromSearchParams,
        loadSelectedMinClusterSizeFromSearchParams,
    ]);

    const loadFilterOperators = useCallback(async () => {
        if (!mosaicStore) {
            return;
        }

        try {
            mosaicStore.startFilterOperatorsLoading();

            const data = await api.getFilterOperators();
            mosaicStore.loadFilterOperators(data);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const error = e as AxiosError;
                if (error.response?.status === 401 && !loginErrorMessage) {
                    console.warn('Got 401 from the server, moving to home page');
                    navigate(routes.home);
                    return;
                }
            }

            setCriticalError(e as Error);
        }
    }, [mosaicStore, loginErrorMessage, navigate]);

    const loadData = async () => {
        try {
            const views = await api.getCustomerView();

            if (views.length === 0) {
                console.warn('Your account does not have any views allocated - please contact support');
                navigate(routes.noViews);

                return;
            }
            mosaicStore!.setAvailableViews(views);

            // Load view from URL or the first view from API as the current view
            const firstView = views[0];
            const foundViewByUrl = views.find((v) => v.id === searchParams.get(VIEW_ID_URL_PARAM_NAME));
            const calculatedCurrentView = foundViewByUrl || firstView;
            loadViewData(calculatedCurrentView, true, false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const error = e as AxiosError;
                if (error.response?.status === 401 && !loginErrorMessage) {
                    console.warn('Got 401 from the server, moving to home page');
                    navigate(routes.home);
                    return;
                }
            }

            setCriticalError(e as Error);
        }
    };

    const resetConfig = useCallback(() => {
        if (!mosaicStore) {
            return;
        }
        mosaicStore.resetConfig();
    }, [mosaicStore]);

    const loadViewData = async (view: CustomerView, withRevision: boolean, withSetSearchParams: boolean) => {
        try {
            mosaicStore.setIsDataLoading(true);
            mosaicStore.setCurrentView(view);

            if (!searchParams.has(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME)) {
                mosaicStore.setSelectedMinClusterSize(undefined);
            }
            mosaicStore.setShowInsufficientData(false);
            const revisionName = searchParams.get(REVISION_URL_PARAM_NAME);

            const metadataFromApi = await api.getMosaicMetadata(view.id);
            if (!metadataFromApi) {
                return;
            }

            const groupByValues =
                metadataFromApi?.view?.group_by?.default ||
                [metadataFromApi?.view?.group_by?.attributes[0]].filter(Boolean);

            const defaultFilters = metadataFromApi.view.filters;
            const logicalOperator = metadataFromApi.view.filters_logical_operator || DEFAULT_FILTERS_LOGICAL_OPERATOR;

            mosaicStore?.setConfig({
                groupBy: groupByValues,
                sizeBy: {
                    attribute: metadataFromApi.view.size_by.attributes[0],
                    fn: metadataFromApi.view.size_by.fn,
                },
                colorBy: {
                    attribute: metadataFromApi.view.color_by.attributes[0],
                    fn: metadataFromApi.view.color_by.fn,
                    palette: metadataFromApi.view.color_by.palette,
                },
            });
            mosaicStore?.setMosaicMetadata(metadataFromApi);

            if (mosaicStore && mosaicStore.filtersLogicalOperator === undefined) {
                // We don't have filtersLogicalOperator, setting the default
                mosaicStore.setFiltersLogicalOperator(logicalOperator);
            }

            if (mosaicStore && mosaicStore.filters === undefined) {
                // We don't have filters, setting the default filters
                mosaicStore.setFilters(defaultFilters);
            }

            if (withSetSearchParams) {
                const str = JSON.stringify(mosaicStore.filters);

                const updatedSearchParams: URLSearchParamsInit = {
                    [FILTERS_URL_PARAM_NAME]: Buffer.from(str).toString('base64'),
                    [VIEW_ID_URL_PARAM_NAME]: view.id,
                    [LOGICAL_OPERATOR_URL_PARAM_NAME]:
                        mosaicStore.filtersLogicalOperator?.toString() || DEFAULT_FILTERS_LOGICAL_OPERATOR.toString(),
                };

                const minClusterSize = mosaicStore?.selectedMinClusterSize;
                if (minClusterSize !== undefined) {
                    updatedSearchParams[SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME] = minClusterSize.toString();
                }

                if (withRevision && revisionName) {
                    updatedSearchParams[REVISION_URL_PARAM_NAME] = revisionName;
                }

                setSearchParams(updatedSearchParams, { replace: false });
            }

            const filtersToUse = mosaicStore && mosaicStore.filters ? mosaicStore.filters : defaultFilters;
            const revision = withRevision ? revisionName : null;

            const dataFromApi = await api.getMosaicData(
                view.id,
                groupByValues,
                applyCustomOperators(toJS(filtersToUse)),
                revision,
                mosaicStore?.filtersLogicalOperator || logicalOperator,
                !Boolean(mosaicStore?.minClusterSizeResponse),
                mosaicStore?.selectedMinClusterSize,
            );

            if (!dataFromApi) {
                return;
            }

            foamTreeStore.setData(dataFromApi.clusters);

            runInAction(() => {
                if (dataFromApi.min_cluster_size) {
                    mosaicStore.setMinClusterSizeResponse(dataFromApi.min_cluster_size);
                }
                mosaicStore.setIsDataLoading(false);
                mosaicStore.setClustersDateRange(dataFromApi.date_range);
                const count = countClusters(dataFromApi.clusters);
                mosaicStore.setShowInsufficientData(count <= MIN_NUMBER_OF_CLUSTERS_TO_SHOW);
                mosaicStore.setViewItemsCount(dataFromApi.view_tickets_count);
            });

            if (metadataFromApi.view.default_layout) {
                foamTreeStore.setSelectedLayout({
                    layout: metadataFromApi.view.default_layout,
                    skipMetrics: true,
                });
            } else {
                foamTreeStore.setSelectedLayout({
                    layout: ExplorerLayout.rectangular,
                    skipMetrics: true,
                });
            }
        } catch (e) {
            setCriticalError(e as Error);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!mosaicStore?.filterOperatorsLoaded && !mosaicStore?.filterOperatorsLoaded) {
            loadFilterOperators();
        }
    }, [mosaicStore, loadFilterOperators]);

    const changeGroupBy = (index: number, value: string) => {
        mosaicStore?.setConfigWithFunction(
            (conf) => {
                if (conf === undefined) {
                    return conf;
                }

                const updatedGroupBy = [...conf.groupBy];
                if (value === '') {
                    updatedGroupBy.splice(index, 1);
                } else {
                    updatedGroupBy[index] = value;
                }

                eventLogger.log('Changed Group By', {
                    Levels: updatedGroupBy,
                    'View Name': mosaicStore?.currentView?.name,
                });

                mixpanelService.track({
                    event: MixpanelEvent.GROUP_BY_CHANGE_ATTRIBUTE,
                    meta: {
                        levels: updatedGroupBy,
                    },
                });

                return { ...conf, groupBy: updatedGroupBy };
            },
            // Reload the data AFTER we updated the store
            () => loadMosaicData(),
        );
    };

    const setSizeBy = (field: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Size By', { Attribute: field, 'View Name': mosaicStore?.currentView?.name });

            mixpanelService.track({
                event: MixpanelEvent.SIZE_BY_CHANGE_ATTRIBUTE,
                meta: {
                    size_by_function: conf.sizeBy.fn,
                    size_by_attribute: field,
                },
            });

            return { ...conf, sizeBy: { ...conf.sizeBy, attribute: field } };
        });
    };

    const setSelectedColorByField = (field: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By', {
                Attribute: field,
                Palette: conf.colorBy.palette,
                'View Name': mosaicStore?.currentView?.name,
            });

            mixpanelService.track({
                event: MixpanelEvent.COLOR_BY_CHANGE_ATTRIBUTE,
                meta: {
                    color_by_function: conf.colorBy.fn,
                    color_by_attribute: conf.colorBy.attribute,
                    color_by_palette: conf.colorBy.palette,
                },
            });

            return { ...conf, colorBy: { ...conf.colorBy, attribute: field } };
        });
    };

    const setSelectedColorFunction = (fnName: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By Function', {
                Function: fnName,
                Attribute: conf.colorBy.attribute,
                'View Name': mosaicStore?.currentView?.name,
            });

            mixpanelService.track({
                event: MixpanelEvent.COLOR_BY_CHANGE_FUNCTION,
                meta: {
                    color_by_function: conf.colorBy.fn,
                    color_by_attribute: conf.colorBy.attribute,
                    color_by_palette: conf.colorBy.palette,
                },
            });

            return { ...conf, colorBy: { ...conf.colorBy, fn: fnName } };
        });
    };

    const setSelectedSizeFunction = (fnName: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Size By Function', {
                Function: fnName,
                Attribute: conf.sizeBy.attribute,
                'View Name': mosaicStore?.currentView?.name,
            });

            mixpanelService.track({
                event: MixpanelEvent.SIZE_BY_CHANGE_FUNCTION,
                meta: {
                    color_by_function: fnName,
                    color_by_attribute: conf.sizeBy.attribute,
                },
            });

            return { ...conf, sizeBy: { ...conf.sizeBy, fn: fnName } };
        });
    };

    const setSelectedPalette = (palette: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By', {
                Attribute: conf.colorBy.attribute,
                Palette: palette,
                'View Name': mosaicStore?.currentView?.name,
            });

            mixpanelService.track({
                event: MixpanelEvent.COLOR_BY_CHANGE_PALETTE,
                meta: {
                    color_by_function: conf.colorBy.fn,
                    color_by_attribute: conf.colorBy.attribute,
                    color_by_palette: conf.colorBy.palette,
                },
            });

            return { ...conf, colorBy: { ...conf.colorBy, palette } };
        });
    };

    const changeCurrentView = (view: CustomerView) => {
        if (!mosaicStore) {
            return;
        }

        mosaicStore.setSideMenuType(undefined);

        // Update the store
        mosaicStore.clustersStore.setCurrentLeaf(null);
        mosaicStore.setCurrentView(view);
        mosaicStore.reset();

        // Update the search params
        setSearchParams({ [VIEW_ID_URL_PARAM_NAME]: view.id }, { replace: false });

        // Canceling previous API call to prevent race-condition
        api.cancelRequest('getMosaicData');

        // Re-query the data
        loadViewData(view, false, true);
    };

    const colorByAttribute = mosaicStore?.config?.colorBy.attribute;
    const formatterName = getFormatterName(mosaicStore?.mosaicMetadata, colorByAttribute);
    const colorByField =
        mosaicStore?.config && mosaicStore?.mosaicMetadata
            ? getField(mosaicStore.mosaicMetadata, mosaicStore.config.colorBy.attribute)
            : undefined;
    const sizeByField =
        mosaicStore?.config && mosaicStore?.mosaicMetadata
            ? getField(mosaicStore.mosaicMetadata, mosaicStore.config.sizeBy.attribute)
            : undefined;

    const isLoading =
        mosaicStore?.filterOperatorsIsLoading ||
        !mosaicStore?.filterOperatorsLoaded ||
        mosaicStore === null ||
        !mosaicStore.mosaicMetadata ||
        !mosaicStore.config ||
        !mosaicStore.currentView ||
        !foamTreeStore.data ||
        mosaicStore.isDataLoading;

    if (criticalError) {
        // If we got 401 - will redirect to login page
        if (axios.isAxiosError(criticalError) && criticalError.response?.status === 401) {
            return <Navigate to={routes.home} />;
        }
        throw criticalError;
    }

    const sizeByAttribute = mosaicStore?.config?.sizeBy.attribute;
    const sizeByName = mosaicStore?.config?.sizeBy.attribute;

    const sizeByDisplayName =
        (mosaicStore?.mosaicMetadata?.dataset.cluster_field_names || {})[sizeByAttribute || ''] || sizeByName;

    return (
        <div className={style.dashboardWrapper}>
            <Topbar setCurrentView={changeCurrentView} onSearch={setSearchKeyword} />
            <div style={{ display: 'flex', height: 'calc(100% - 80px)' }}>
                <TreemapLegend
                    min={foamTreeStore.min}
                    max={foamTreeStore.max}
                    isLoading={isLoading}
                    palette={mosaicStore?.config?.colorBy.palette}
                    hasUndefined={haveUndefined(mosaicStore?.config?.sizeBy.attribute, foamTreeStore.data)}
                    fnName={mosaicStore?.config?.colorBy.fn}
                    formatterName={formatterName}
                    field={colorByField}
                    attributeName={mosaicStore?.config?.colorBy.attribute}
                    clusterFieldNamesMapping={mosaicStore?.mosaicMetadata?.dataset.cluster_field_names}
                />
                <Explorer />
                <InsufficientDataBlock
                    show={mosaicStore.showInsufficientData}
                    allowHide={clustersCount > 0}
                    onHide={() => mosaicStore.setShowInsufficientData(false)}
                    numberOfClusters={clustersCount}
                    openFilters={() => mosaicStore?.setIsFilterPopoverOpen(true)}
                />
                <ExplorerBlocker show={Boolean(toJS(mosaicStore?.isFilterPopoverOpen))} />
                <SideMenu
                    filters={mosaicStore?.filters || []}
                    customerName={mosaicStore?.currentView?.name}
                    customerLogoUrl={mosaicStore?.currentView?.logo}
                    maxLevel={mosaicStore?.mosaicMetadata?.view.group_by.levels}
                    groupByFields={mosaicStore?.mosaicMetadata?.view.group_by.attributes}
                    selectedGroupByFields={mosaicStore?.config?.groupBy}
                    changeGroupBy={changeGroupBy}
                    sizeByFields={mosaicStore?.mosaicMetadata?.view.size_by.attributes}
                    selectedSizeByField={toJS(mosaicStore?.config?.sizeBy.attribute)}
                    setSizeBy={setSizeBy}
                    selectedColorByField={toJS(mosaicStore?.config?.colorBy.attribute)}
                    colorByFields={toJS(mosaicStore?.mosaicMetadata?.view.color_by.attributes)}
                    setSelectedColorByField={setSelectedColorByField}
                    selectedPalette={toJS(mosaicStore?.config?.colorBy.palette)}
                    setSelectedPalette={setSelectedPalette}
                    currentView={mosaicStore?.currentView}
                    selectedColorFunction={toJS(mosaicStore?.config?.colorBy.fn)}
                    setSelectedColorFunction={setSelectedColorFunction}
                    selectedSizeFunction={toJS(mosaicStore?.config?.sizeBy.fn)}
                    setSelectedSizeFunction={setSelectedSizeFunction}
                    fieldsMetadata={toJS(mosaicStore?.mosaicMetadata?.dataset.fields_metadata)}
                    initialAppliedFilter={toJS(mosaicStore?.mosaicMetadata?.view.filters)}
                    dataLoading={Boolean(toJS(mosaicStore?.isDataLoading))}
                    sizeByField={sizeByField}
                    sizeByFieldName={toJS(mosaicStore?.config?.sizeBy.attribute)}
                    sizeByFieldDisplayName={toJS(sizeByDisplayName)}
                    menuType={sideMenuType}
                    setMenuType={mosaicStore.switchMenuType}
                    resetConfig={resetConfig}
                    clusterFieldNamesMapping={mosaicStore?.mosaicMetadata?.dataset.cluster_field_names}
                    revision={searchParams.get(REVISION_URL_PARAM_NAME)}
                />
            </div>
        </div>
    );
});

export default Dashboard;
