import axios, { AxiosInstance } from 'axios';

import refreshToken from '../auth/refreshToken';
import { CLUSTER_SIZE_FILTER_NAME } from '../helper/consts';
import {
    AnnotateDirectAnswerRequestParams,
    AppliedFilter,
    AutocompleteResponse,
    ChangesOverTimeResponse,
    ClusterItemsRequestParams,
    ClusterItemsResponse,
    ClustersResponse,
    CustomerView,
    DirectAnswer,
    FilterOperators,
    FiltersClusterHierarchy,
    GetDirectAnswerRequestParams,
    IEditMarkedEventPointParams,
    IDeleteMarkedEventPointParams,
    LogicalOperator,
    MetricExtraData,
    MetricType,
    MosaicMetadata,
    UsageMetricBody,
    UserDetails,
} from '../types';

import withCancel from './withCancel';

const RETRY_HEADER_NAME = 'X-Refresh-Token';

export class ApiClient {
    private apiClient: AxiosInstance;
    public abortControllersMap = new Map<string, AbortController>();

    constructor() {
        this.apiClient = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
        });

        // Refresh token interceptor in case the response is 401
        this.apiClient.interceptors.response.use(
            (response) => response,
            (error) => {
                // If we got 401 and we didn't try to refresh the token yet
                if (error.response?.status === 401 && !error.config.headers[RETRY_HEADER_NAME]) {
                    return refreshToken().then((updatedToken: string) => {
                        // We got a new token! Updating the token and retrying the request
                        this.setToken(updatedToken);
                        error.config.headers['Authorization'] = `Bearer ${updatedToken}`;
                        error.config.headers[RETRY_HEADER_NAME] = 'true';

                        return this.apiClient.request(error.config);
                    });
                }

                return Promise.reject(error);
            },
        );
    }

    /**
     * Send usage metrics
     *
     * @param metricType
     * @param extra
     */
    public async sendMetric(metricType: MetricType, extra?: MetricExtraData) {
        const requestBody: UsageMetricBody = {
            client_type: 'mosaic_ui',
            client_version: process.env.REACT_APP_VERSION || '',
            event_type: metricType,
        };

        if (extra) {
            requestBody.data = extra;
        }

        await this.apiClient.post('/v1/usage_metric', requestBody);
    }

    /**
     * Get customer's views
     *
     * @returns
     */
    async getCustomerView(): Promise<CustomerView[]> {
        const res = await this.apiClient.get(`/views`);
        return res.data;
    }

    /**
     * Get the mosaic metadata
     *
     * @param viewId
     * @returns
     */
    @withCancel()
    async getMosaicMetadata(viewId: string, abortController?: AbortController): Promise<MosaicMetadata> {
        const res = await this.apiClient.get(`/views/${viewId}/metadata`, { signal: abortController?.signal });
        return res.data;
    }

    /**
     * Get the mosaic data (with applied filters)
     *
     * @param viewId
     * @param groupBy
     * @param filters
     * @param revision
     * @returns
     */
    @withCancel()
    async getMosaicData(
        viewId: string,
        groupBy: string[],
        filters: AppliedFilter[],
        revision: string | null,
        filtersLogicalOperator: LogicalOperator,
        shouldSendMinClusterSize: boolean,
        selectedMinClusterSize?: number,
        abortController?: AbortController,
    ): Promise<ClustersResponse> {
        const filtersWithoutClusterSize = filters.filter((f) => f.field !== CLUSTER_SIZE_FILTER_NAME);
        let url = `/views/${viewId}/clusters`;
        if (revision) {
            url += `?revision=${revision}`;
        }

        const res = await this.apiClient.post<ClustersResponse>(
            url,
            {
                group_by: groupBy.filter(Boolean),
                min_cluster_size: selectedMinClusterSize,
                filters: filtersWithoutClusterSize,
                filters_logical_operator: filtersLogicalOperator,
                should_send_min_cluster_size: shouldSendMinClusterSize,
            },
            { signal: abortController?.signal },
        );

        // Debug print if the headers exists
        if (
            res.headers['x-total-num-items'] !== undefined &&
            res.headers['x-total-leftovers-items'] !== undefined &&
            res.headers['x-shown-items'] !== undefined
        ) {
            const presentOfItemShown = (+res.headers['x-shown-items'] / +res.headers['x-total-num-items']) * 100;
            const presentOfLeftovers =
                (+res.headers['x-total-leftovers-items'] / +res.headers['x-total-num-items']) * 100;
            const presentOfItemShownNoLeftovers =
                ((+res.headers['x-shown-items'] - +res.headers['x-total-leftovers-items']) /
                    +res.headers['x-total-num-items']) *
                100;

            const logMessage = [
                `Total number of items: ${res.headers['x-total-num-items']}`,
                `Total number of leftovers added: ${res.headers['x-total-leftovers-items']}`,
                `Total shown items: ${res.headers['x-shown-items']}`,
                `% of items shown: ${presentOfItemShown.toFixed(2)}%`,
                `% of leftover items: ${presentOfLeftovers.toFixed(2)}%`,
                `% of items shown w/o leftovers: ${presentOfItemShownNoLeftovers.toFixed(2)}%`,
                `Min cluster size: ${selectedMinClusterSize || res.data.min_cluster_size?.default}`,
            ];
            console.log(logMessage.join(' | '));
        }

        return res.data;
    }

    async getAutocompleteSuggestion(
        viewId: string,
        field: string,
        value: string,
        revision: string | null,
    ): Promise<AutocompleteResponse> {
        let url = `/views/${viewId}/autocomplete`;
        if (revision) {
            url += `?revision=${revision}`;
        }
        const res = await this.apiClient.post(url, {
            field,
            value,
        });
        return res.data;
    }

    /**
     * Get allowed filter operators
     * @returns
     */
    async getFilterOperators(): Promise<FilterOperators> {
        const res = await this.apiClient.get(`/operators`);
        return res.data;
    }

    /**
     * Get user details
     * @returns
     */
    async getUserDetails(): Promise<UserDetails> {
        const res = await this.apiClient.get(`/auth/me`);
        return res.data;
    }

    /**
     * Get cluster's items
     * @params ClusterItemsRequestParams
     * @returns
     */
    async getClusterItems(params: ClusterItemsRequestParams): Promise<ClusterItemsResponse> {
        const { viewId, itemIds, leftoverItemIds, filtersClusterHierarchy, filters, revision, sortBy } = params;

        let url = `/views/${viewId}/clusters/items`;
        if (revision) {
            url += `?revision=${revision}`;
        }

        const res = await this.apiClient.post(url, {
            ids: itemIds,
            filters_cluster_hierarchy: filtersClusterHierarchy,
            leftover_ids: leftoverItemIds,
            filters,
            sort_by: sortBy,
        });
        return res.data;
    }

    /**
     * Get data for the Changes Over Time chart
     *
     * @param view_id
     * @param filters_cluster_hierarchy
     * @param filters
     * @param revision
     *
     * @param abortController
     * @returns
     */
    @withCancel()
    async getChangesOverTime(
        view_id: string,
        filters_cluster_hierarchy: FiltersClusterHierarchy,
        filters: AppliedFilter[],
        revision: string,
        abortController?: AbortController,
    ): Promise<ChangesOverTimeResponse> {
        let url = `/views/${view_id}/clusters/dates_histogram`;
        if (revision) {
            url += `?revision=${revision}`;
        }
        const filtersWithoutClusterSize = filters.filter((f) => f.field !== CLUSTER_SIZE_FILTER_NAME);

        const res = await this.apiClient.post<ChangesOverTimeResponse>(
            url,
            {
                filters_cluster_hierarchy,
                filters: filtersWithoutClusterSize,
            },
            { signal: abortController?.signal },
        );

        return res.data;
    }

    /**
     * Creates a marked event point for changes over time chart
     *
     * @param payload
     */
    async createMarkedEventPoint(payload: IEditMarkedEventPointParams): Promise<void> {
        const { viewId, cluster, marker } = payload;
        const url = `/views/${viewId}/clusters/dates_histogram/markers`;

        await this.apiClient.post(url, {
            cluster,
            ...marker,
        });
    }

    /**
     * Updates the given marked event point for changes over time chart
     *
     * @param payload
     */
    async updateMarkedEventPoint(payload: IEditMarkedEventPointParams): Promise<void> {
        const { viewId, cluster, marker } = payload;
        const url = `/views/${viewId}/clusters/dates_histogram/markers`;

        await this.apiClient.put(url, {
            cluster,
            ...marker,
        });
    }

    /**
     * Delete the given marked event point for changes over time chart
     *
     * @param payload
     */
    async deleteMarkedEventPoint(payload: IDeleteMarkedEventPointParams): Promise<void> {
        const { viewId, cluster, marker } = payload;
        const url = `/views/${viewId}/clusters/dates_histogram/markers`;

        await this.apiClient.delete(url, {
            data: {
                cluster,
                ...marker,
            },
        });
    }

    /**
     * Sends annotate data to the direct answers service.
     *
     * @param payload object contains the annotation data
     *
     * @returns boolean
     */
    async annotateDirectAnswer(payload: AnnotateDirectAnswerRequestParams) {
        const { customerProjectId, questionId, annotationType, feedback } = payload;
        const body = {
            annotationType,
            feedback,
        };

        const url = `${process.env.REACT_APP_DIRECT_ANSWER_API_URL}/direct_answers/${customerProjectId}/${questionId}/annotate`;
        const res = await this.apiClient.post<boolean>(url, body);

        return res.data;
    }

    /**
     * Get the direct answer by http request.
     *
     * @param payload object contains questionId and customerProjectId
     *
     * @returns directAnswer
     */
    async getDirectAnswer(payload: GetDirectAnswerRequestParams) {
        const { customer_project_id, question_id } = payload;

        const url = `${process.env.REACT_APP_DIRECT_ANSWER_API_URL}/direct_answers/${customer_project_id}/${question_id}`;
        const res = await this.apiClient.get<DirectAnswer>(url);

        return res.data;
    }

    /**
     * Set the token for future requests
     *
     * @param token
     */
    setToken(token: string) {
        this.apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    /**
     * Get token
     * @returns
     */
    getToken() {
        return this.apiClient.defaults.headers.common['Authorization'];
    }

    /**
     * Cancel given request
     *
     * @param requestName
     */
    cancelRequest(requestName: string) {
        if (this.abortControllersMap.has(requestName)) {
            this.abortControllersMap.get(requestName)?.abort();
            this.abortControllersMap.delete(requestName);
        }
    }
}

const instance = new ApiClient();
export default instance;
