import { Field } from '../types';

import { formatters } from './consts';

/**
 * Formatting a value using the given field
 *
 * @param value
 * @param field
 * @returns
 */
const formatValue = (value: number, field: Field) => {
    return formatters[field.formatter](value, field);
};

export default formatValue;
