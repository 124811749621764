import { runInAction } from 'mobx';

import hashSha256 from '../../../helper/hashSha256';
import {
    ExplorerConfig,
    isLeafMosaicRecord,
    isMosaicRecord,
    LeafMosaicRecord,
    MosaicMetadata,
    MosaicRecord,
    WeightedLeafMosaicRecord,
} from '../../../types';

export const highlightClusters = (foamtree: any | null, clusters: WeightedLeafMosaicRecord[]) => {
    if (!foamtree || !foamtree.get('dataObject')) {
        return;
    }

    if (clusters.length === 0) {
        foamtree.expose(null);
        return;
    }

    foamtree.set({
        groupExposureScale: 1.015,
        groupExposureShadowSize: 100,
        groupExposureShadowColor: 'rgba(0, 0, 0, 0.2)',
        groupExposureZoomMargin: 0.01,
        exposure: {
            groups: clusters,
            exposed: true,
        },
    });
};

/**
 * Deleting the details pane items so the generateLastRenderInfo will not take into account the items
 *
 * @param records
 * @returns
 */
const deleteDetailPaneItems = (records: (MosaicRecord | LeafMosaicRecord)[]): (MosaicRecord | LeafMosaicRecord)[] => {
    return records.map((child) => {
        if (isLeafMosaicRecord(child)) {
            delete child.cluster.detailPaneItems;
        } else if (isMosaicRecord(child)) {
            runInAction(() => {
                child.groups = deleteDetailPaneItems(child.groups ?? []);
            });
        }
        return child;
    });
};

/**
 * Generate a uniq string that represents the data, config and metadata
 *
 * The uniq string will be used for verifying if the data/config/metadata were changed
 * @param data
 * @param config
 * @param metadata
 * @returns
 */
export const generateLastRenderInfo = async (
    data: MosaicRecord[],
    config: ExplorerConfig,
    metadata: MosaicMetadata,
) => {
    const lastRenderInfo =
        JSON.stringify(deleteDetailPaneItems(data)) + JSON.stringify(config) + JSON.stringify(metadata);
    const hash = await hashSha256(lastRenderInfo);
    return hash;
};
