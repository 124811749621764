import moment from 'moment/moment';

import { ChangesOverTimeRecord, ClustersDateRangeResponse } from '../../../types';

import { DAYS_TOKEN } from './aggregations';

const getMissingDatesBefore = (data: ChangesOverTimeRecord[], start?: string): ChangesOverTimeRecord[] => {
    if (start === undefined) {
        return [];
    }

    const rangeStart = moment(start);
    const dateStart = moment(data[0].date);
    const startDiff = rangeStart.diff(dateStart, 'days');

    if (startDiff >= 0) {
        return [];
    }

    return Array(Math.abs(startDiff))
        .fill('')
        .map((_, index) => ({
            date: rangeStart.clone().add(index, 'days').format(DAYS_TOKEN),
            count: 0,
        }));
};
const getMissingDatesAfter = (data: ChangesOverTimeRecord[], end?: string): ChangesOverTimeRecord[] => {
    if (!end) {
        return [];
    }

    const rangeEnd = moment(end);
    const dateEnd = moment(data[data.length - 1].date);
    const endDiff = rangeEnd.diff(dateEnd, 'days');

    if (endDiff <= 0) {
        return [];
    }

    return Array(Math.abs(endDiff))
        .fill('')
        .map((_, index) => ({
            date: dateEnd
                .clone()
                .add(index + 1, 'days')
                .format(DAYS_TOKEN),
            count: 0,
        }));
};

export const normalizeRange = (
    data: ChangesOverTimeRecord[],
    range?: ClustersDateRangeResponse,
): ChangesOverTimeRecord[] => {
    return [...getMissingDatesBefore(data, range?.start), ...data, ...getMissingDatesAfter(data, range?.end)];
};
