import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';

import styles from './BaseCard.module.scss';

interface IBaseCardCollapsibleProps {
    children: React.ReactNode;
    title: React.ReactNode;
    collapsed?: boolean;
    onCollapseStateChange?: (collapsed: boolean) => void;
    className?: string;
}

const BaseCardCollapsible = React.forwardRef<HTMLDivElement, IBaseCardCollapsibleProps>((props, ref) => {
    const { children, title, collapsed = false, onCollapseStateChange = () => false, className } = props;

    const [opened, setOpened] = useState<boolean>(!collapsed);

    useEffect(() => {
        setOpened(!collapsed);
    }, [collapsed]);

    const handleCollapseClick = () => {
        setOpened(!opened);
        onCollapseStateChange(opened);
    };

    return (
        <Card ref={ref} variant="widget" className={classNames(styles.wrapper, className)}>
            <div className={styles.header}>
                <div className={styles.collapse} onClick={handleCollapseClick}>
                    <IconButton
                        size="collapse"
                        sx={{
                            color: opened ? 'neutralSelected.main' : 'darkGray.main',
                        }}
                        className={classNames({ [styles.opened]: opened }, styles.arrow)}
                    >
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                    <CardHeader
                        disableTypography
                        sx={{
                            color: opened ? 'neutralSelected.main' : 'darkGray.main',
                        }}
                        title={title}
                        className={styles.title}
                    />
                </div>
            </div>
            <Collapse in={opened} className={styles.body}>
                {children}
            </Collapse>
        </Card>
    );
});

export interface IBaseCardProps extends IBaseCardCollapsibleProps {
    collapsible?: boolean;
}

export const BaseCard = React.forwardRef<HTMLDivElement, IBaseCardProps>((props, ref) => {
    const {
        children,
        title,
        collapsible = false,
        collapsed = false,
        onCollapseStateChange = () => false,
        className,
    } = props;

    if (collapsible) {
        return (
            <BaseCardCollapsible
                ref={ref}
                title={title}
                collapsed={collapsed}
                onCollapseStateChange={onCollapseStateChange}
                className={className}
            >
                {children}
            </BaseCardCollapsible>
        );
    }

    return (
        <Card ref={ref} variant="widget" className={classNames(styles.wrapper, className)}>
            <div className={styles.header}>
                <CardHeader disableTypography title={title} />
            </div>
            <div className={styles.body}>{children}</div>
        </Card>
    );
});
