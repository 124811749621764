import Lottie from 'lottie-react';

import animation from './animation.json';

import style from './PreloadingAnumation.module.scss';

const PreloadingAnimation = () => {
    return (
        <div className={style.wrapper}>
            <Lottie animationData={animation} loop className={style.animation} />
        </div>
    );
};

export default PreloadingAnimation;
