import EChartsReactCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

export const getCursorGridCoordinates = (
    chart: EChartsReactCore | null,
    params: echarts.ElementEvent,
): [number, number] | undefined => {
    if (!chart) {
        return undefined;
    }

    const chartInstance = chart.getEchartsInstance();
    const { offsetX, offsetY } = params;

    const gridPoint = chartInstance.convertFromPixel('grid', [offsetX, offsetY]);

    return [gridPoint[0], gridPoint[1]];
};
