import isEqual from 'react-fast-compare';
import { URLSearchParamsInit } from 'react-router-dom';

import { Buffer } from 'buffer';

import { DEFAULT_FILTERS_LOGICAL_OPERATOR } from '../../helper/consts';
import flatClusters from '../../helper/flatClusters';
import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { getClusterMeta } from '../../services/mixpanel/utils';
import MosaicStore from '../../stores/MosaicStore';
import {
    isLeafMosaicRecord,
    isMosaicRecord,
    LeafMosaicRecord,
    MosaicRecord,
    SideMenuType,
    WeightedLeafMosaicRecordWithParent,
} from '../../types';
import {
    FILTERS_URL_PARAM_NAME,
    LOGICAL_OPERATOR_URL_PARAM_NAME,
    REVISION_URL_PARAM_NAME,
    SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME,
    VIEW_ID_URL_PARAM_NAME,
} from '../consts';

/**
 * Find min and max values
 *
 * @param sizeByAttribute
 * @param data
 * @returns
 */
export const calculateMinMax = (sizeByAttribute: string | undefined, data: MosaicRecord[] | undefined) => {
    if (!sizeByAttribute || !data) {
        return {
            min: 0,
            max: 0,
        };
    }

    const values = flatClusters(data)
        .map((r) => +(r as LeafMosaicRecord).cluster.dynamicAttributes[sizeByAttribute])
        .sort((a, b) => a - b);

    return {
        min: values[0] || 0,
        max: values[values.length - 1] || 0,
    };
};

/**
 * Check if we have undefined value
 *
 * @param sizeByAttribute
 * @param data
 * @returns
 */
export const haveUndefined = (
    sizeByAttribute: string | undefined,
    data: (LeafMosaicRecord | MosaicRecord)[] | undefined,
) => {
    if (data === undefined || sizeByAttribute === undefined) {
        return true;
    }

    return flatClusters(data).some(
        (r) =>
            (r as LeafMosaicRecord).cluster.dynamicAttributes[sizeByAttribute] === null ||
            (r as LeafMosaicRecord).cluster.dynamicAttributes[sizeByAttribute] === undefined,
    );
};

export const handleStoreUpdate = (
    mosaicStore: MosaicStore | null,
    searchParams: URLSearchParams,
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: any;
              }
            | undefined,
    ) => void,
    loadMosaicData: () => Promise<void>,
) => {
    if (!mosaicStore || !mosaicStore.filters || !mosaicStore.currentView) {
        return;
    }
    const str = JSON.stringify(mosaicStore.filters);

    const updatedSearchParams: URLSearchParamsInit = {
        [FILTERS_URL_PARAM_NAME]: Buffer.from(str).toString('base64'),
        [VIEW_ID_URL_PARAM_NAME]: mosaicStore.currentView?.id,
        [LOGICAL_OPERATOR_URL_PARAM_NAME]:
            mosaicStore.filtersLogicalOperator?.toString() || DEFAULT_FILTERS_LOGICAL_OPERATOR.toString(),
    };

    // Add revision if set
    const revision = searchParams.get(REVISION_URL_PARAM_NAME);
    if (revision) {
        updatedSearchParams[REVISION_URL_PARAM_NAME] = revision;
    }

    // Add if minClusterSize is set
    const minClusterSize = mosaicStore?.selectedMinClusterSize;
    if (minClusterSize !== undefined) {
        updatedSearchParams[SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME] = minClusterSize.toString();
    }

    if (!isEqual(updatedSearchParams, Object.fromEntries(searchParams))) {
        const shouldLoad =
            (searchParams.has(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME) &&
                SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME in updatedSearchParams &&
                searchParams.get(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME) !==
                    updatedSearchParams[SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME]) ||
            updatedSearchParams[FILTERS_URL_PARAM_NAME] !== searchParams.get(FILTERS_URL_PARAM_NAME) ||
            updatedSearchParams[LOGICAL_OPERATOR_URL_PARAM_NAME] !== searchParams.get(LOGICAL_OPERATOR_URL_PARAM_NAME);

        setSearchParams(updatedSearchParams, { replace: false });
        if (shouldLoad) {
            loadMosaicData();
        }
    }
};

/**
 * Count the number of clusters
 *
 * @param data
 * @returns
 */
export const countClusters = (data: MosaicRecord[] | undefined) => {
    if (data === undefined || data.length === 0) {
        return 0;
    }

    return data.reduce((prev, curr) => {
        if (curr.groups) {
            prev += curr.groups.filter((c) => isLeafMosaicRecord(c)).length;
            const subChilds = curr.groups.filter((c) => isMosaicRecord(c));
            if (subChilds.length > 0) {
                prev += countClusters(subChilds as MosaicRecord[]);
            }
        } else if (isLeafMosaicRecord(curr)) {
            prev += 1;
        }
        return prev;
    }, 0);
};

interface ITrackDetailsPanelStateData {
    prevState: SideMenuType | undefined;
    nextState: SideMenuType | undefined;
    item: WeightedLeafMosaicRecordWithParent | null;
    selectedGroupByFields: string[];
}
export const trackSideMenuState = (data: ITrackDetailsPanelStateData) => {
    const { prevState, nextState, item, selectedGroupByFields } = data;

    const menuType = nextState ?? prevState;

    switch (menuType) {
        case SideMenuType.Config:
            const panelSettingsEvent = !nextState
                ? MixpanelEvent.SETTINGS_PANEL_CLOSE
                : MixpanelEvent.SETTINGS_PANEL_OPEN;

            mixpanelService.track({ event: panelSettingsEvent });

            break;
        case SideMenuType.DetailsPane:
            if (!item) {
                return;
            }

            const clusterDetailsEvent = !nextState ? MixpanelEvent.DETAIL_PANEL_CLOSE : MixpanelEvent.DETAIL_PANEL_OPEN;

            mixpanelService.track({
                event: clusterDetailsEvent,
                meta: getClusterMeta(item, selectedGroupByFields),
            });
            break;
    }
};
