import { useCallback } from 'react';

import { api } from '@frontegg/rest-api';

import { useRootStore } from './root-store';

export const useLogoutUser = () => {
    const mosaicStore = useRootStore();

    const logoutUser = useCallback(() => {
        mosaicStore.logout();

        api.auth.logout().finally(() => window.location.reload());
    }, [mosaicStore]);

    return { logoutUser };
};
