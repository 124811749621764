import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ReactComponent as PlaceholderIcon } from './feedback-success.svg';

import styles from './FeedbackForm.module.scss';

export type SuccessDialogProps = {
    open: boolean;
    onClose: () => void;
};

export const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={styles.title}>{t('directAnswer.feedbackHeader')}</DialogTitle>
            <Divider className={styles.divider} />
            <DialogContent className={styles.successContent}>
                <PlaceholderIcon />
                <Typography marginY="12px" align="center" variant="h6" color="darkGray.main">
                    {t('directAnswer.feedbackSuccessHeader')}
                </Typography>
                <Typography marginY="4px" align="center" color="midGray.main">
                    {t('directAnswer.feedbackSuccessText')}
                </Typography>
            </DialogContent>
            <Divider className={styles.divider} />
            <DialogActions className={styles.actions}>
                <Button variant="feedback-secondary" onClick={onClose}>
                    {t('directAnswer.feedbackCancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
