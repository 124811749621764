import { stringify } from 'csv-stringify/browser/esm';

import { DetailPaneItem } from '../types';

import capitalizeEachWord from './capitalizeEachWord';

export interface IConvertItemsToCSVParams {
    delimiter?: string;
    keysToSkip?: string[];
}

export const convertItemsToCSV = async (
    items: DetailPaneItem[],
    params: IConvertItemsToCSVParams = {},
): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (items.length === 0) {
            reject(new Error('The are no items to export')); // This error for developers only in case they try to pass an empty array
        }

        const { delimiter = ',', keysToSkip = [] } = params;

        const columns = Object.keys(items[0])
            .filter((key) => !keysToSkip.includes(key))
            .map(capitalizeEachWord);
        const rows: Array<string[]> = items.map((item) =>
            Object.entries(item)
                .filter(([key]) => !keysToSkip.includes(key))
                .map(([, value]) => value),
        );

        stringify(rows, { delimiter, columns, header: true }, (error, result) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });
};
