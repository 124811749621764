import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useFlag } from '@unleash/proxy-client-react';
import { observer } from 'mobx-react-lite';

import { AppToggle } from '../../../../clents/unleash/app-toggle-names';
import { useRootStore } from '../../../../hooks/root-store';
import { mixpanelService } from '../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { getClusterMeta, getItemMeta } from '../../../../services/mixpanel/utils';
import { AppliedFilter, CustomerView, DetailPaneItem, Field } from '../../../../types';

import CustomScrollBar from './CustomScrollbar';
import ItemCard from './ItemCard';
import ItemCardSkeleton from './ItemCardSkeleton';
import { ItemsHeader } from './ItemsHeader/ItemsHeader';
import { ChangesOverTimeWidget } from './Widgets/ChangesOverTime/ChangesOverTimeWidget';
import { ExecutiveSummaryWidget } from './Widgets/ExecutiveSummary/ExecutiveSummaryWidget';
import { StatsWidget } from './Widgets/Stats/StatsWidget';

import style from './DetailsPane.module.scss';

interface DetailsPaneProps {
    sizeByField: Field | undefined;
    sizeByFieldName: string | undefined;
    sizeByFieldDisplayName: string | undefined;
    filters: AppliedFilter[] | null;
    currentView: CustomerView | undefined;
    selectedGroupByFields: string[] | undefined;
    revision: string | null;
    shouldDisplayChangeOverTime: boolean;
}

const DetailsPane = (props: DetailsPaneProps) => {
    const {
        sizeByField,
        sizeByFieldName,
        sizeByFieldDisplayName,
        filters,
        currentView,
        selectedGroupByFields,
        revision,
        shouldDisplayChangeOverTime,
    } = props;
    const { clustersStore } = useRootStore();
    const { currentLeaf, itemsLoading, selectedClusterItemsCount } = clustersStore;
    const shouldDisplayExecutiveSummaryWidget = useFlag(AppToggle.EXECUTIVE_SUMMARY_WIDGET);

    if (!currentLeaf || !sizeByField || !sizeByFieldName || !currentView || !sizeByFieldDisplayName) {
        return null;
    }

    const handleItemCollapseChange = (item: DetailPaneItem, collapsed: boolean) => {
        mixpanelService.track({
            event: collapsed ? MixpanelEvent.DETAIL_PANEL_COLLAPSE_ITEM : MixpanelEvent.DETAIL_PANEL_EXPAND_ITEM,
            meta: {
                ...getClusterMeta(currentLeaf, selectedGroupByFields ?? []),
                ...getItemMeta(item),
            },
        });
    };

    return (
        <Box sx={{ backgroundColor: 'lightGray.main', height: '100%', overflowY: 'auto' }}>
            <Scrollbars renderTrackVertical={CustomScrollBar}>
                <Box className={style.header}>
                    <StatsWidget
                        item={currentLeaf}
                        sizeByField={sizeByField}
                        sizeByFieldName={sizeByFieldName}
                        sizeByFieldDisplayName={sizeByFieldDisplayName}
                    />
                    <Divider variant="widget" />
                    {shouldDisplayExecutiveSummaryWidget && (
                        <ExecutiveSummaryWidget
                            filters={filters}
                            currentView={currentView}
                            detailsPanelItem={currentLeaf}
                            selectedGroupByFields={selectedGroupByFields}
                            executiveSummaryRequestId={currentLeaf.cluster.executiveSummaryRequestId}
                            executiveSummaryCustomerName={currentLeaf.cluster.executiveSummaryCustomerName}
                        />
                    )}
                    {shouldDisplayChangeOverTime && (
                        <ChangesOverTimeWidget
                            currentView={currentView}
                            detailsPanelItem={currentLeaf}
                            selectedGroupByFields={selectedGroupByFields}
                            filters={filters}
                            revision={revision}
                        />
                    )}
                </Box>

                <ItemsHeader />

                <Box sx={{ p: 1 }}>
                    {!itemsLoading && currentLeaf.cluster.detailPaneItems && selectedClusterItemsCount ? (
                        currentLeaf.cluster.detailPaneItems.map((item) => (
                            <ItemCard
                                item={item}
                                key={item._id}
                                allOpen={!clustersStore.itemsCollapsed}
                                onCollapsedChange={(collapsed) => handleItemCollapseChange(item, collapsed)}
                            />
                        ))
                    ) : (
                        <>
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                        </>
                    )}
                </Box>
            </Scrollbars>
        </Box>
    );
};

export default observer(DetailsPane);
