import React from 'react';
import { useTranslation } from 'react-i18next';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import api from '../../../../../../../api';
import { useRootStore } from '../../../../../../../hooks/root-store';
import { mixpanelService } from '../../../../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../../../../services/mixpanel/types';
import { AnnotateDirectAnswerRequestParams, DirectAnnotationType, DirectAnswer } from '../../../../../../../types';
import { StyledSnackbar } from '../../../../../../common/StyledSnackbar/StyledSnackbar';

import { ActionButton } from './ActionButton';
import { FeedbackForm } from './FeedbackForm/FeedbackForm';
import { ReactComponent as ThumbDownIcon } from './icons/thumb-down-icon.svg';
import { ReactComponent as ThumbUpIcon } from './icons/thumb-up-icon.svg';
import { AlertMessageType } from './types';

const anchorOrigin = { vertical: 'bottom' as const, horizontal: 'center' as const };

export type ThumbActionProps = {
    variant: 'up' | 'down';
    questionId: string;
    customerProjectId: string;
    answer: DirectAnswer;
};

export const ThumbAction = observer(
    ({ variant, questionId, answer: directAnswer, customerProjectId }: ThumbActionProps) => {
        const { t } = useTranslation();
        const [feedBackFormOpen, setFeedBackFormOpen] = React.useState(false);
        const [message, setMessage] = React.useState<AlertMessageType | null>(null);
        const mosaicStore = useRootStore();
        const { clustersStore } = mosaicStore;

        const isThumbUp = variant === 'up';

        const annotatePayload: AnnotateDirectAnswerRequestParams = {
            customerProjectId: customerProjectId,
            questionId: questionId,
            annotationType: isThumbUp ? DirectAnnotationType.ThumbsUp : DirectAnnotationType.ThumbsDown,
        };

        const sendMetrics = React.useCallback(
            (feedbackMessage?: string) => {
                mixpanelService.track({
                    event: isThumbUp ? MixpanelEvent.DIRECT_ANSWER_THUMB_UP : MixpanelEvent.DIRECT_ANSWER_THUMB_DOWN,
                    meta: {
                        direct_answer: directAnswer.answer,
                        question_id: questionId,
                        customer_project_id: customerProjectId,
                        view_id: toJS(mosaicStore.currentView?.id),
                        cluster_name: clustersStore.selectedClusterName,
                        items_count: clustersStore.selectedClusterItemsCount,
                        feedback: (!isThumbUp && feedbackMessage) || undefined,
                    },
                });
            },
            [
                isThumbUp,
                directAnswer,
                questionId,
                customerProjectId,
                mosaicStore,
                clustersStore.selectedClusterName,
                clustersStore.selectedClusterItemsCount,
            ],
        );

        const handleClose = React.useCallback(() => {
            setFeedBackFormOpen(false);
        }, [setFeedBackFormOpen]);

        const onSuccess = React.useCallback(() => {
            const text = isThumbUp ? t('directAnswer.feedbackSavedThumbUp') : t('directAnswer.feedbackSavedThumbDown');
            setMessage({ type: 'success', text });
            sendMetrics();
        }, [t, setMessage, isThumbUp, sendMetrics]);

        const onFail = React.useCallback(() => {
            setMessage({ type: 'error', text: t('directAnswer.feedbackSaveError') });
        }, [t, setMessage]);

        const annotate = React.useCallback(
            (payload: AnnotateDirectAnswerRequestParams) => {
                api.annotateDirectAnswer(payload).then(onSuccess).catch(onFail).finally(handleClose);
            },
            [onSuccess, onFail, handleClose],
        );

        const handleClick = () => {
            if (isThumbUp) {
                annotate(annotatePayload);
            } else {
                setFeedBackFormOpen(true);
            }
        };

        const translationKey = isThumbUp ? 'directAnswer.thumbUpAction' : 'directAnswer.thumbDownAction';
        const title = t(translationKey);

        return (
            <>
                <ActionButton tooltipTitle={title} onClick={handleClick}>
                    {isThumbUp ? <ThumbUpIcon title={title} /> : <ThumbDownIcon title={title} />}
                </ActionButton>
                {feedBackFormOpen && (
                    <FeedbackForm
                        open
                        onClose={handleClose}
                        annotatePayload={annotatePayload}
                        onSuccess={sendMetrics}
                    />
                )}
                {!!message && (
                    <StyledSnackbar
                        open
                        autoHideDuration={2500}
                        anchorOrigin={anchorOrigin}
                        onClose={() => setMessage(null)}
                        message={message.text}
                        severity={message.type}
                    />
                )}
            </>
        );
    },
);
