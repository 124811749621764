import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { styled, Theme } from '@mui/material/styles';

interface StyledSnackbarProps extends SnackbarProps {
    severity?: 'success' | 'error';
}

const success = (theme: Theme) => ({
    '& .MuiSnackbarContent-message': {
        color: theme.palette.mainBlue.main,
    },
});

const error = (theme: Theme) => ({
    '& .MuiSnackbarContent-message': {
        color: theme.palette.error.main,
    },
});

export const StyledSnackbar = styled(Snackbar, {
    shouldForwardProp: (prop) => prop !== 'severity',
})<StyledSnackbarProps>(({ severity = 'success', theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        padding: '2px 26px',
        height: '54px',
        minWidth: '310px',
        textAlign: 'center',
        '.MuiSnackbarContent-message': {
            width: '100%',
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    ...(severity === 'success' && success(theme)),
    ...(severity === 'error' && error(theme)),
}));
