import React, { useContext, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FieldArray, Form, Formik } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { CLUSTER_SIZE_FILTER_NAME, DEFAULT_FILTERS_LOGICAL_OPERATOR } from '../../../../helper/consts';
import { mixpanelService } from '../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { MosaicStoreContext } from '../../../../stores/MosaicStore';
import {
    AllowedFilter,
    AppliedFilter,
    LogicalOperator,
    OperatorsPerSemanticType,
    SemanticType,
} from '../../../../types';
import { generateFilterValidations } from '../../filtersHelper';

import FilterRow from './FilterRow';
import FiltersViewButton from './FiltersViewButton';
import FormObserver from './FormObserver';

import style from './FiltersView.module.scss';

export interface FilterFormData {
    filters: AppliedFilter[];
    filters_logical_operator: LogicalOperator;
}

interface FiltersViewProps {
    forceLock?: boolean;
}

const FiltersView = observer((props: FiltersViewProps) => {
    const { forceLock } = props;
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mosaicStore = useContext(MosaicStoreContext);

    const fieldsMetadata = mosaicStore?.mosaicMetadata?.dataset.fields_metadata;
    const initialAppliedFilter = mosaicStore?.mosaicMetadata?.view.filters;
    const [operatorsBySemanticType, setOperatorsBySemanticType] = useState<OperatorsPerSemanticType>();
    const [allowedFilters, setAllowedFilters] = useState<AllowedFilter[]>([]);

    const validationSchema = generateFilterValidations(fieldsMetadata, t, allowedFilters);
    const onSubmit = async (data: FilterFormData) => {
        if (!mosaicStore) {
            console.warn('No store detected, exiting');
            return;
        }

        const updatedFilters = [...data.filters].map((f) => {
            f.should_intersect_on_multiple_values =
                fieldsMetadata && fieldsMetadata[f.field]?.semantic_type === SemanticType.ARRAY;
            return f;
        });

        if (!isEqual(updatedFilters, toJS(mosaicStore.filters))) {
            mosaicStore.setSelectedMinClusterSize(undefined);
            mosaicStore.setMinClusterSizeResponse(undefined);
        }
        mosaicStore.setFilters(updatedFilters);
        mosaicStore.setFiltersLogicalOperator(data.filters_logical_operator);
        mosaicStore.setIsFilterPopoverOpen(false);
        mixpanelService.track({
            event: MixpanelEvent.FILTERS_APPLY_FILTER,
            meta: {
                filters: updatedFilters,
            },
        });
    };

    useEffect(() => {
        if (
            !mosaicStore ||
            mosaicStore.filterOperatorsIsLoading ||
            !mosaicStore.filterOperatorsLoaded ||
            !mosaicStore.filterOperators
        ) {
            return;
        }

        // Mapping between semantic type and operators
        const newOperatorsBySemanticType = {} as OperatorsPerSemanticType;
        for (const [operatorName, config] of Object.entries(mosaicStore.filterOperators)) {
            for (const semanticType of config.semantic_types) {
                if (!newOperatorsBySemanticType[semanticType]) {
                    newOperatorsBySemanticType[semanticType] = [];
                }
                newOperatorsBySemanticType[semanticType].push(operatorName);
            }
        }

        setOperatorsBySemanticType(newOperatorsBySemanticType);
    }, [
        mosaicStore,
        mosaicStore?.filterOperatorsIsLoading,
        mosaicStore?.filterOperatorsLoaded,
        mosaicStore?.filterOperators,
    ]);

    useEffect(() => {
        if (!mosaicStore) {
            return;
        }

        // Constructing the filters (without the minCluster)
        const updatedFilters = (mosaicStore.allowedFilters || [])
            .map((f) => toJS(f))
            .filter((f) => f.field !== CLUSTER_SIZE_FILTER_NAME);
        if (!isEqual(allowedFilters, updatedFilters)) {
            setAllowedFilters(updatedFilters);
        }
    }, [mosaicStore, allowedFilters, initialAppliedFilter]);

    const handleOnResetAllClick = () => {
        mixpanelService.track({
            event: MixpanelEvent.FILTERS_CLEAR_ALL_FILTERS,
            meta: {
                filters: mosaicStore?.filters,
            },
        });
    };

    const initialValues: FilterFormData = {
        filters: (mosaicStore?.filters || initialAppliedFilter || []).filter(
            (f) => f.field !== CLUSTER_SIZE_FILTER_NAME,
        ),
        filters_logical_operator: mosaicStore?.filtersLogicalOperator || DEFAULT_FILTERS_LOGICAL_OPERATOR,
    };

    if (forceLock) {
        return <FiltersViewButton ref={buttonRef} onClick={() => {}} disabled isOpen={false} numOfFilters={0} />;
    }

    return (
        <>
            <FiltersViewButton
                ref={buttonRef}
                onClick={() => mosaicStore?.setIsFilterPopoverOpen(true)}
                disabled={Boolean(toJS(mosaicStore?.isDataLoading))}
                isOpen={Boolean(toJS(mosaicStore?.isFilterPopoverOpen))}
                numOfFilters={initialValues.filters.length}
            />

            <Popover
                open={Boolean(toJS(mosaicStore?.isFilterPopoverOpen))}
                anchorEl={buttonRef.current}
                onClose={() => mosaicStore?.setIsFilterPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {/* TODO: Change to a component */}
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({ values, errors, touched, submitForm, isValid, setValues, setFieldValue }) => (
                        <>
                            <FormObserver
                                values={values}
                                fieldsMetadata={toJS(mosaicStore?.mosaicMetadata?.dataset.fields_metadata)}
                                setValues={setValues}
                            />
                            <Form className={style.filtersWrapper}>
                                <div className={style.titleWrapper}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="darkGray.main"
                                        className={style.title}
                                    >
                                        {t('filters.popoverTitle')}
                                    </Typography>

                                    <Button
                                        variant="text"
                                        color="neutral"
                                        disabled={mosaicStore?.isDataLoading}
                                        className={style.resetAll}
                                        onClick={() => {
                                            handleOnResetAllClick();
                                            // Removing all values: if we want to revert back - we'll need to reset the form
                                            setValues((v) => ({
                                                ...v,
                                                filters: [],
                                            }));
                                        }}
                                    >
                                        <RestartAltIcon sx={{ fontSize: 20 }} />
                                        <span className={style.resetChanges}>{t('filters.clearAll')}</span>
                                    </Button>
                                </div>
                                <Divider sx={{ margin: '8px -16px 32px -16px' }} />

                                <Grid container spacing={1}>
                                    <FieldArray name="filters">
                                        {() =>
                                            values.filters.map((filter, i) => {
                                                const wasTouched = Boolean(touched.filters && touched.filters[i]);
                                                const valueError: string | undefined = (
                                                    ((errors.filters?.length &&
                                                        errors.filters[i] &&
                                                        errors.filters[i]) ||
                                                        {}) as any
                                                ).value;

                                                return (
                                                    <FilterRow
                                                        key={`${filter.field}.${i}`}
                                                        i={i}
                                                        error={Boolean(valueError) && wasTouched}
                                                        errorMessage={valueError}
                                                        field={filter.field}
                                                        operator={filter.operator}
                                                        allowedFilters={allowedFilters}
                                                        operatorsBySemanticType={operatorsBySemanticType}
                                                        fieldsMetadata={toJS(
                                                            mosaicStore?.mosaicMetadata?.dataset.fields_metadata,
                                                        )}
                                                        onDelete={() => {
                                                            setValues((v) => ({
                                                                ...v,
                                                                filters: v.filters.filter(
                                                                    (_f, deleteI) => deleteI !== i,
                                                                ),
                                                            }));
                                                        }}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                );
                                            })
                                        }
                                    </FieldArray>
                                </Grid>

                                <Button
                                    variant="text"
                                    color="neutral"
                                    disabled={mosaicStore?.isDataLoading}
                                    onClick={() => {
                                        // Adding new filter
                                        setValues((v) => ({
                                            ...v,
                                            filters: [...v.filters, { field: '', operator: '', value: '' }],
                                        }));
                                    }}
                                >
                                    <AddBoxOutlinedIcon sx={{ fontSize: 20 }} />
                                    <span className={style.resetChanges}>{t('filters.addFilter')}</span>
                                </Button>
                                <Divider sx={{ margin: '8px -16px 8px -16px' }} />
                                <div className={style.endButtonsWrapper}>
                                    <Button
                                        variant="contained"
                                        color="buttonGray"
                                        onClick={() => mosaicStore?.setIsFilterPopoverOpen(false)}
                                        className={style.closeButton}
                                    >
                                        {t('filters.close')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="mainBlue"
                                        className={style.applyButton}
                                        disabled={mosaicStore?.isDataLoading || !isValid}
                                        onClick={() => {
                                            submitForm();
                                        }}
                                    >
                                        {t('filters.submit')}
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </Popover>
        </>
    );
});

export default FiltersView;
