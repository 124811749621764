export const defaultCarrotSearchFoamTreeParams = (clearSearch: () => void) => ({
    id: 'visualization',
    pixelRatio: window.devicePixelRatio || 1,

    relaxationQualityThreshold: 2.5,

    // Use flattened view by default
    stacking: 'flattened',

    // Disable animations
    fadeDuration: 500,
    rolloutDuration: 0,
    pullbackDuration: 0,

    // Use the custom web font for FoamTree texts.
    groupLabelFontFamily: 'DM Sans',

    // Customize borders, fill and strokes
    groupBorderWidth: 2,
    groupInsetWidth: 4,
    groupBorderRadius: 0.1,
    groupBorderRadiusCorrection: 1,

    groupSelectionOutlineWidth: 3.5,

    groupFillType: 'gradient',
    groupFillGradientRadius: 3,
    groupFillGradientCenterLightnessShift: 20,

    groupStrokeWidth: 0.33,
    groupStrokeType: 'plain',
    groupStrokePlainLightnessShift: 0,

    groupUnexposureSaturationShift: -100,
    groupUnexposureLightnessShift: 200,

    // Allow some more time to draw
    finalCompleteDrawMaxDuration: 1000,
    finalIncrementalDrawMaxDuration: 1000,

    // Make the line spacing and the total height of the
    // label smaller than the default to make space for
    // the coverage value display
    groupLabelLineHeight: 1.0,

    groupLabelDarkColor: 'rgba(0, 0, 0, 0.8)',
    descriptionGroupMinHeight: 30,
    descriptionGroupSize: 0.15,
    //groupLabelHorizontalPadding: 0.0,
    //groupLabelVerticalPadding: 0.0,
    groupLabelColorThreshold: 0.6,
    groupLabelMaxTotalHeight: 1,
    //relaxationQualityThreshold: 3.2,
    layoutByWeightOrder: false,
    //groupBorderRadius: 0.5,
    //groupLabelFontFamily: "Tahoma",
    //groupLabelMinFontSize: 28,
    rainbowLightnessShift: 19,
    //groupFillGradientCenterSaturationShift: 100,
    //rainbowLightnessShiftCenter: 0.3,
    //groupLabelLineHeight: 1,
    layout: 'relaxed',

    // Use custom label padding for the description group
    groupLabelLayoutDecorator: function (opts: any, params: any, vars: any) {
        if (params.description) {
            vars.verticalPadding = 0.1;
            vars.maxTotalTextHeight = 1.0;
            vars.fontFamily = 'DM Sans';
            vars.fontWeight = '700';
        }
    },

    // Use custom group coloring
    groupColorDecorator: function (opts: any, props: any, vars: any) {
        var group = props.group;
        if (group && group.color) {
            vars.groupColor = group.color;
        } else {
            vars.groupColor = '#f0f0f0';
            vars.labelColor = '#000';
        }
    },

    onGroupDoubleClick: function () {
        clearSearch();
        // @ts-ignore
        this.set({
            groupExposureScale: CarrotSearchFoamTree.defaults.groupExposureScale,
            groupExposureShadowSize: CarrotSearchFoamTree.defaults.groupExposureShadowSize,
            groupExposureShadowColor: CarrotSearchFoamTree.defaults.groupExposureShadowColor,
            groupExposureZoomMargin: CarrotSearchFoamTree.defaults.groupExposureZoomMargin,
        });
    },

    onGroupExposureChanged: function (info: any) {
        if (info.groups.length === 0) {
            clearSearch();
        }
    },

    wireframeLabelDrawing: 'always',
    titleBarDecorator: function (opts: any, props: any, vars: any) {
        if (props.group.company) {
            vars.titleBarShown = false;
        }
    },
});
