import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import Divider from '@mui/material/Divider';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../hooks/root-store';
import {
    CustomerView,
    FieldsMetadata,
    AppliedFilter,
    Field,
    SideMenuType,
    ClusterFieldNamesMapping,
} from '../../../types';
import ExplorerConfigPanel from '../ExplorerConfigPanel';

import DetailsPane from './DetailsPane';
import MenuItemButton from './MenuItemButton';

import style from './SideMenu.module.scss';

interface SideMenuProps {
    customerName: string | undefined;
    customerLogoUrl: string | undefined;
    maxLevel: number | undefined;
    groupByFields: string[] | undefined;
    selectedGroupByFields: string[] | undefined;
    selectedSizeByField: string | undefined;
    sizeByFields: string[] | undefined;
    selectedSizeFunction: string | undefined;
    selectedColorByField: string | undefined;
    colorByFields: string[] | undefined;
    selectedPalette: string | undefined;
    selectedColorFunction: string | undefined;
    currentView: CustomerView | undefined;
    fieldsMetadata: FieldsMetadata | undefined;
    initialAppliedFilter: AppliedFilter[] | undefined;
    dataLoading: boolean;
    sizeByField: Field | undefined;
    sizeByFieldName: string | undefined;
    sizeByFieldDisplayName: string | undefined;
    menuType: SideMenuType | undefined;
    setSizeBy: (field: string) => void;
    changeGroupBy: (index: number, value: string) => void;
    setSelectedColorByField: (field: string) => void;
    setSelectedPalette: (palette: string) => void;
    setSelectedColorFunction: (fnName: string) => void;
    setSelectedSizeFunction: (fnName: string) => void;
    resetConfig: () => void;
    setMenuType: (type: SideMenuType) => void;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
    revision: string | null;
    filters: AppliedFilter[] | null;
}

const SideMenu = (props: SideMenuProps) => {
    const {
        customerName,
        customerLogoUrl,
        maxLevel,
        groupByFields,
        selectedGroupByFields,
        selectedSizeByField,
        sizeByFields,
        selectedColorByField,
        colorByFields,
        selectedPalette,
        currentView,
        selectedColorFunction,
        selectedSizeFunction,
        fieldsMetadata,
        initialAppliedFilter,
        dataLoading,
        menuType,
        sizeByField,
        sizeByFieldName,
        sizeByFieldDisplayName,
        clusterFieldNamesMapping,
        revision,
        changeGroupBy,
        setSizeBy,
        setSelectedColorByField,
        setSelectedPalette,
        setSelectedColorFunction,
        setSelectedSizeFunction,
        resetConfig,
        setMenuType,
        filters,
    } = props;
    const { t } = useTranslation();
    const mosaicStore = useRootStore();
    const { clustersStore } = mosaicStore;

    const contentStyle = useSpring({
        width: Boolean(menuType) ? 560 : 0,
        height: '100%',
        onRest: (result) => {
            const { width } = result.value;

            if (width !== 0) {
                mosaicStore.changesOverTimeStore.setVisible(true);
            }
        },
        onStart: (result) => {
            const { width } = result.value;

            if (width !== 0) {
                mosaicStore.changesOverTimeStore.setVisible(false);
            }
        },
    });

    const isLoading =
        maxLevel === undefined ||
        groupByFields === undefined ||
        selectedGroupByFields === undefined ||
        selectedSizeByField === undefined ||
        sizeByFields === undefined ||
        selectedColorByField === undefined ||
        selectedColorFunction === undefined ||
        selectedSizeFunction === undefined ||
        colorByFields === undefined ||
        selectedPalette === undefined ||
        customerName === undefined ||
        customerLogoUrl === undefined ||
        currentView === undefined ||
        fieldsMetadata === undefined ||
        initialAppliedFilter === undefined;

    const shouldDisplayChangeOverTime = Boolean(mosaicStore.shouldDisplayChangeOverTime);

    return (
        <div className={style.wrapper}>
            <animated.div style={contentStyle} className={style.menu}>
                {menuType === SideMenuType.Config && (
                    <ExplorerConfigPanel
                        dataLoading={dataLoading}
                        customerName={customerName}
                        customerLogoUrl={customerLogoUrl}
                        maxLevel={maxLevel}
                        groupByFields={groupByFields}
                        selectedGroupByFields={selectedGroupByFields}
                        changeGroupBy={changeGroupBy}
                        sizeByFields={sizeByFields}
                        selectedSizeByField={selectedSizeByField}
                        setSizeBy={setSizeBy}
                        selectedColorByField={selectedColorByField}
                        colorByFields={colorByFields}
                        setSelectedColorByField={setSelectedColorByField}
                        selectedPalette={selectedPalette}
                        setSelectedPalette={setSelectedPalette}
                        currentView={currentView}
                        selectedColorFunction={selectedColorFunction}
                        setSelectedColorFunction={setSelectedColorFunction}
                        selectedSizeFunction={selectedSizeFunction}
                        setSelectedSizeFunction={setSelectedSizeFunction}
                        fieldsMetadata={fieldsMetadata}
                        initialAppliedFilter={initialAppliedFilter}
                        resetConfig={resetConfig}
                        clusterFieldNamesMapping={clusterFieldNamesMapping}
                    />
                )}
                {menuType === SideMenuType.DetailsPane && (
                    <DetailsPane
                        sizeByField={sizeByField}
                        sizeByFieldName={sizeByFieldName}
                        sizeByFieldDisplayName={sizeByFieldDisplayName}
                        filters={filters}
                        currentView={currentView}
                        selectedGroupByFields={selectedGroupByFields}
                        revision={revision}
                        shouldDisplayChangeOverTime={shouldDisplayChangeOverTime}
                    />
                )}
            </animated.div>

            <Divider orientation="vertical" flexItem />

            <div className={style.menuItems}>
                <MenuItemButton
                    tooltipText={t('explorerConfigPanel.title')}
                    Icon={TuneIcon}
                    isSelected={menuType === SideMenuType.Config}
                    disabled={isLoading}
                    onClick={() => setMenuType(SideMenuType.Config)}
                />
                <MenuItemButton
                    tooltipText={t('detailsPanel.title')}
                    Icon={TextSnippetOutlinedIcon}
                    isSelected={menuType === SideMenuType.DetailsPane}
                    disabled={isLoading || !Boolean(clustersStore.currentLeaf)}
                    onClick={() => setMenuType(SideMenuType.DetailsPane)}
                />
            </div>
        </div>
    );
};

export default observer(SideMenu);
