import { action, computed, flow, makeObservable, observable, IObservableArray } from 'mobx';
import moment from 'moment';

import api from '../../api';
import { IAPIMarkedEvent, IMosaicStore } from '../../types';

import { DAYS_TOKEN, MONTHS_TOKEN } from './helpers/aggregations';
import { convertToAPIMarkedEvent } from './helpers/marked-event';
import { IDeleteMarkedEventData, IMarkedEventData, IMarkedEventFormData } from './types';

export class MarkedEventsStore {
    rootStore: IMosaicStore;

    @observable
    currentMarkedEvent: IMarkedEventData | null = null;

    @observable
    markedEventPoints: IObservableArray<IMarkedEventData> = observable.array([]);

    @computed
    get isModalOpen(): boolean {
        return this.currentMarkedEvent !== null;
    }

    @action.bound
    closeModal() {
        this.currentMarkedEvent = null;
    }

    @action
    createNewEvent(date: string) {
        this.currentMarkedEvent = {
            isNew: true,
            description: '',
            date,
        };
    }

    @action
    editEvent(date: string) {
        const event = this.markedEventPoints.find((point) => moment(point.date).format(MONTHS_TOKEN) === date);

        if (!event) {
            return;
        }

        this.currentMarkedEvent = event;
    }

    @action
    setData(data: IAPIMarkedEvent[]) {
        this.markedEventPoints = observable.array(
            data.map((event) => {
                const date = moment(`${event.year}-${event.month}-${event.day}`);

                return {
                    date: date.format(DAYS_TOKEN),
                    description: event.description,
                    isNew: false,
                };
            }),
        );
    }

    getMarkedEventDescription = (date: string): string => {
        const event = this.markedEventPoints.find((point) => moment(point.date).format(MONTHS_TOKEN) === date);

        return event?.description ?? '';
    };

    createNewEventFlow = flow(function* (this: MarkedEventsStore, data: IMarkedEventFormData) {
        const { viewId, cluster, description, day } = data;

        const currentDate = moment(this.currentMarkedEvent?.date);
        currentDate.set('date', day);

        const marker = convertToAPIMarkedEvent(currentDate, description);

        try {
            yield api.createMarkedEventPoint({
                viewId,
                cluster,
                marker,
            });

            this.markedEventPoints.push({
                date: currentDate.format(DAYS_TOKEN),
                description,
                isNew: false,
            });

            this.rootStore.clustersStore.setCurrentLeafMarked(true);
        } catch (e) {
        } finally {
            this.currentMarkedEvent = null;
        }
    });

    updateEventFlow = flow(function* (this: MarkedEventsStore, data: IMarkedEventFormData) {
        const { viewId, cluster, description, day } = data;
        const event = this.markedEventPoints.find((point) => point === this.currentMarkedEvent);

        if (event) {
            const date = moment(event.date).set('date', day);
            const marker = convertToAPIMarkedEvent(date, description);

            try {
                yield api.updateMarkedEventPoint({
                    viewId,
                    cluster,
                    marker,
                });

                event.description = description;
                event.date = date.format(DAYS_TOKEN);
            } catch (e) {}
        }

        this.currentMarkedEvent = null;
    });

    deleteEventFlow = flow(function* (this: MarkedEventsStore, data: IDeleteMarkedEventData) {
        const { viewId, cluster } = data;
        const event = this.markedEventPoints.find((point) => point === this.currentMarkedEvent);

        this.currentMarkedEvent = null;

        if (event) {
            const date = moment(event.date);
            const marker = convertToAPIMarkedEvent(date, '');

            try {
                this.markedEventPoints.remove(event);
                yield api.deleteMarkedEventPoint({
                    viewId,
                    cluster,
                    marker,
                });

                if (this.markedEventPoints.length === 0) {
                    this.rootStore.clustersStore.setCurrentLeafMarked(false);
                }
            } catch (e) {}
        }
    });

    constructor(rootStore: IMosaicStore) {
        this.rootStore = rootStore;

        makeObservable(this);
    }
}
