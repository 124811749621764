import { TextField, styled, alpha } from '@mui/material';

import { fontFamily } from '../../../theme';

export const StyledInput = styled(TextField)(({ theme }) => ({
    label: {
        transform: 'translate(0, -21px)',
        color: theme.palette.darkGray.main,
        fontWeight: 500,
    },
    '&:has(label)': {
        marginTop: '16px',
    },
    '.MuiInputBase-root': {
        '&:before': {
            display: 'none',
        },
        '&:after': {
            display: 'none',
        },
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        borderColor: alpha(theme.palette.base['50'] ?? '', 0.2),
        color: theme.palette.darkGray.main,
        fontSize: '14px',
        width: '100%',
        minWidth: 300,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily,
    },
    '& .MuiInputBase-inputMultiline': {
        border: `1px solid ${alpha(theme.palette.base['50'] ?? '', 0.2)}`,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.base['50'] ?? '', 0.2),
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
        height: '40px',
        top: 0,

        '& legend': {
            display: 'none',
        },
    },

    width: '100%',
}));
