import React, { useRef, useState, useEffect } from 'react';

import { debounce } from 'lodash';

import { functionsOrder } from '../../../helper/consts';
import CustomSliderComponent from '../../common/CustomSlider';

interface MathFunctionSliderProps {
    selectedFunction: string;
    setSelectedFunction: (newFunction: string) => void;
    disabled?: boolean;
}

const MathFunctionSlider = (props: MathFunctionSliderProps) => {
    const { selectedFunction, disabled, setSelectedFunction } = props;
    const [value, setValue] = useState(functionsOrder.findIndex((f) => f === selectedFunction));
    const onFunctionChange = useRef(
        debounce((newValue: number) => {
            const newFunction = functionsOrder[Array.isArray(newValue) ? newValue[0] : newValue];
            setSelectedFunction(newFunction);
        }, 300),
    ).current;

    useEffect(() => {
        setValue(functionsOrder.findIndex((f) => f === selectedFunction));
    }, [selectedFunction]);

    return (
        <CustomSliderComponent
            aria-label="Function"
            value={value}
            marks
            min={0}
            max={functionsOrder.length - 1}
            onChange={(_e: Event, newValue: number | number[]) => {
                const updatedValue = Array.isArray(newValue) ? newValue[0] : newValue;
                setValue(updatedValue);
                onFunctionChange(updatedValue);
            }}
            disabled={disabled}
        />
    );
};

export default MathFunctionSlider;
