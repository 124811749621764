import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { RecordHeader, RecordHeaderType } from '../../../../../../../types';
import LightTooltip from '../../../../../../common/LightTooltip';

import InfoIcon from './info-icon.svg';

import style from './Subheader.module.scss';

interface SubheaderProps {
    header: RecordHeader;
}

const Subheader: React.FC<SubheaderProps> = (props) => {
    const { header } = props;

    if (header.type !== RecordHeaderType.KeyValue) {
        return null;
    }

    const tooltipTitle = (header.values.description || header.values.key).trim();

    return (
        <Grid item xs zeroMinWidth className={style.gridItem}>
            <div className={style.tooltipWrapper}>
                <LightTooltip title={tooltipTitle} arrow placement="top">
                    <img src={InfoIcon} alt={tooltipTitle} />
                </LightTooltip>
            </div>

            <Typography variant="h6" component="div" color="neutralSelected.main" className={style.value}>
                {header.values.value}
            </Typography>

            <Typography variant="subtitle1" component="div" color="neutralSelected.main" className={style.key}>
                {header.values.key.trim()}
            </Typography>
        </Grid>
    );
};

export default Subheader;
