import { generateFiltersClusterHierarchy } from '../../components/Dashboard/SideMenu/DetailsPane/helper';
import { DetailPaneItem, WeightedLeafMosaicRecordWithParent } from '../../types';

export const getClusterMeta = (
    item: WeightedLeafMosaicRecordWithParent,
    selectedGroupByFields: string[],
    currentViewId?: string,
) => {
    return {
        cluster_name: item.label,
        cluster_stats: [
            ...Object.entries(item.cluster.dynamicAttributes).map(([key, value]) => ({
                key,
                value,
            })),
            ...item.cluster.headers.map((header) => ({
                key: header.values.key,
                value: header.values.value,
            })),
        ],
        filters_cluster_hierarchy: generateFiltersClusterHierarchy(item, selectedGroupByFields),
        view_id: currentViewId,
        items_count: item.cluster.itemsCount,
    };
};

export const getItemMeta = (item: DetailPaneItem) => {
    const { _id, ...rest } = item;

    return {
        item_id: item._id,
        ...rest,
    };
};
