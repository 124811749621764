import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';

import { SKELETON_COLOR } from '../../../../consts';

const ItemCardSkeleton = () => {
    return (
        <Card variant="outlined" sx={{ m: 1 }}>
            <CardHeader
                disableTypography
                title={<Skeleton animation="wave" variant="text" sx={{ backgroundColor: SKELETON_COLOR }} />}
            ></CardHeader>
            <CardContent sx={{ pt: 0 }}>
                <Skeleton animation="wave" variant="text" sx={{ width: 150, backgroundColor: SKELETON_COLOR }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 200, backgroundColor: SKELETON_COLOR }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 100, backgroundColor: SKELETON_COLOR }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 150, backgroundColor: SKELETON_COLOR }} />
            </CardContent>
        </Card>
    );
};

export default ItemCardSkeleton;
