import React from 'react';
import { createRoot } from 'react-dom/client';

import { FlagProvider } from '@unleash/proxy-client-react';

import App from './App';
import { unleashClientConfig } from './clents/unleash/unleash-client-config';
import './i18n';
import reportWebVitals from './reportWebVitals';

// Generic site style
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <FlagProvider startClient config={unleashClientConfig}>
        <App />
    </FlagProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
