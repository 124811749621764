import { isLeafMosaicRecord, LeafMosaicRecord, MosaicRecord } from '../types';

const extractGroups = <MR extends MosaicRecord, LMR extends LeafMosaicRecord, R extends LeafMosaicRecord>(
    child: MR | LMR,
    groups: R[],
) => {
    if (isLeafMosaicRecord(child)) {
        groups.push(child as unknown as R);

        return;
    } else if (child.groups) {
        for (const subChild of child.groups) {
            extractGroups(subChild, groups);
        }
    }
};

export const getClusterGroups = <T extends MosaicRecord, R extends LeafMosaicRecord>(root?: Pick<T, 'groups'>): R[] => {
    if (!root) {
        return [];
    }

    if (isLeafMosaicRecord(root)) {
        return [root as unknown as R];
    }

    if (!root.groups) {
        return [];
    }

    const leafs: R[] = [];

    for (const child of root.groups) {
        extractGroups(child, leafs);
    }

    return leafs;
};
