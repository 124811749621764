import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { ClusterFieldNamesMapping } from '../../../types';

import GroupTitle from './GroupTitle';
import MathFunctionSlider from './MathFunctionSlider';
import { BASE_SX } from './consts';

import style from './common.module.scss';

interface SizeBySelectorProps {
    currentField: string;
    fields: string[];
    setSizeBy: (sizeBy: string) => void;
    selectedFunction: string;
    setSelectedFunction: (fnName: string) => void;
    disabled: boolean;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
}

const SizeBySelector = (props: SizeBySelectorProps) => {
    const {
        currentField,
        fields,
        selectedFunction,
        disabled,
        clusterFieldNamesMapping,
        setSizeBy,
        setSelectedFunction,
    } = props;
    const { t } = useTranslation();

    return (
        <div className={style.commonWrapper}>
            <GroupTitle>{t('explorerConfigPanel.configHeaders.sizeBy')}</GroupTitle>

            <Box>
                <FormControl size="small">
                    <Select
                        defaultValue={currentField}
                        disabled={disabled}
                        onChange={(e) => setSizeBy(e.target.value)}
                        sx={{ minWidth: 100, ...BASE_SX }}
                        autoWidth
                    >
                        {fields.map((f) => {
                            const displayValue = clusterFieldNamesMapping ? clusterFieldNamesMapping[f] || f : f;
                            return (
                                <MenuItem key={f} value={f} sx={BASE_SX}>
                                    {displayValue}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <MathFunctionSlider
                    setSelectedFunction={setSelectedFunction}
                    selectedFunction={selectedFunction}
                    disabled={disabled}
                />
            </Box>
        </div>
    );
};

export default SizeBySelector;
