import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import api from '../../../../../../../api';
import { useRootStore } from '../../../../../../../hooks/root-store';
import { mixpanelService } from '../../../../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../../../../services/mixpanel/types';
import { DirectAnnotationType, DirectAnswer as DirectAnswerType, DirectAnswerStatus } from '../../../../../../../types';
import { FakeTypewriter } from '../../../../../../common/FakeTypewriter/FakeTypewriter';
import { StyledSnackbar } from '../../../../../../common/StyledSnackbar/StyledSnackbar';
import { AlertMessageType } from '../Actions/types';

import styles from './DirectAnswer.module.scss';

const CURSOR = '|';
const MAX_DIRECT_ANSWER_LENGTH = 275;
const anchorOrigin = { vertical: 'bottom' as const, horizontal: 'center' as const };

export interface DirectAnswerProps {
    answer?: DirectAnswerType;
    actions: React.ReactNode;
    questionId: string;
    customerProjectId: string;
}

export const DirectAnswer = observer(
    ({ answer: directAnswer, actions, questionId, customerProjectId }: DirectAnswerProps) => {
        const { t } = useTranslation();
        const prevAnswerStatus = useRef<undefined | string>();
        const [realTypewriterMode, setRealTypewriterMode] = useState<boolean>(true);
        const [collapsed, setCollapsed] = useState<boolean>(true);
        const [typewriterIsDone, setTypewriterIsDone] = useState(false);
        const typeWriterOnDone = useCallback(() => setTypewriterIsDone(true), [setTypewriterIsDone]);
        const [message, setMessage] = React.useState<AlertMessageType | null>(null);
        const mosaicStore = useRootStore();
        const {clustersStore} = mosaicStore;

        useLayoutEffect(() => {
            if (directAnswer?.status === DirectAnswerStatus.FAILED) {
                setMessage({ type: 'error', text: t('directAnswer.statusFailed') });
            }
            if (!prevAnswerStatus.current && directAnswer?.status === DirectAnswerStatus.RUNNING) {
                setRealTypewriterMode(true);
            } else if (
                (!prevAnswerStatus.current && directAnswer?.status === DirectAnswerStatus.DONE) ||
                (prevAnswerStatus.current === DirectAnswerStatus.PENDING &&
                    directAnswer?.status === DirectAnswerStatus.DONE)
            ) {
                setRealTypewriterMode(false);
            }
            prevAnswerStatus.current = directAnswer?.status;
        }, [directAnswer, setRealTypewriterMode, t]);

        const handleShowMore = useCallback(() => {
            setCollapsed((prevState) => {
                if (directAnswer) {
                    mixpanelService.track({
                        event: prevState
                            ? MixpanelEvent.SHOW_MORE_IN_DIRECT_ANSWER
                            : MixpanelEvent.SHOW_LESS_IN_DIRECT_ANSWER,
                        meta: {
                            direct_answer: directAnswer.answer,
                            question_id: questionId,
                            customer_project_id: customerProjectId,
                          view_id: toJS(mosaicStore.currentView?.id),
                          cluster_name: clustersStore.selectedClusterName,
                            items_count: clustersStore.selectedClusterItemsCount,
                        },
                    });
                }

                if (directAnswer && prevState) {
                    const payload = {
                        annotationType: DirectAnnotationType.ShowMore,
                        customerProjectId,
                        questionId,
                    };

                    api.annotateDirectAnswer(payload).catch(() => {
                        // handle an error silent
                        console.warn(`Failed to annotate direct answer ${payload.annotationType}`);
                    });
                }

                return !prevState;
            });
        }, [setCollapsed, directAnswer, customerProjectId, questionId, mosaicStore, clustersStore.selectedClusterName, clustersStore.selectedClusterItemsCount]);

        // show cursor if no answer status known yet
        if (!directAnswer) {
            return (
                <div className={cx(styles.directAnswer, { [styles.collapsed]: collapsed })}>
                    <div className={styles.answer}>
                        <span className={styles.cursor}>{CURSOR}</span>
                    </div>
                </div>
            );
        }

        const answerToRender: string | undefined = collapsed
            ? directAnswer.answer.substring(0, MAX_DIRECT_ANSWER_LENGTH)
            : directAnswer.answer;
        const isCollapseVisible = directAnswer.answer.length >= MAX_DIRECT_ANSWER_LENGTH;
        const isStatusDone = directAnswer.status === DirectAnswerStatus.DONE;

        const renderShowMore = () => (
            <span className={styles.collapse} onClick={handleShowMore}>
                {collapsed ? t('directAnswer.descriptionMore') : t('directAnswer.descriptionLess')}
            </span>
        );

        const renderRealtimeTypewriter = () => {
            const isCursorVisible = !isStatusDone && !isCollapseVisible;

            return (
                <>
                    <div className={styles.answer}>
                        {answerToRender}
                        {isCollapseVisible && renderShowMore()}
                        {isCursorVisible && <span className={styles.cursor}>{CURSOR}</span>}
                    </div>
                    {isStatusDone && <div className={styles.actions}>{actions}</div>}
                </>
            );
        };

        const renderFakeTypewriter = () => {
            const isActionsVisible = typewriterIsDone && isStatusDone;

            return (
                <>
                    <div className={styles.answer}>
                        {typewriterIsDone ? (
                            answerToRender
                        ) : (
                            <FakeTypewriter delay={4} text={answerToRender || ''} onDone={typeWriterOnDone} />
                        )}
                        {typewriterIsDone && isCollapseVisible && renderShowMore()}
                    </div>
                    {isActionsVisible && <div className={styles.actions}>{actions}</div>}
                </>
            );
        };

        return (
            <>
                <div className={cx(styles.directAnswer, { [styles.collapsed]: collapsed })}>
                    {realTypewriterMode ? renderRealtimeTypewriter() : renderFakeTypewriter()}
                </div>
                {message && (
                    <StyledSnackbar
                        autoHideDuration={2500}
                        anchorOrigin={anchorOrigin}
                        open={!!message}
                        onClose={() => setMessage(null)}
                        message={message.text}
                        severity={message.type}
                    />
                )}
            </>
        );
    },
);
