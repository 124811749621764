export const welcome = 'Welcome to Ask-AI';
export const retrievePassword = 'Retrieve your password';
export const defaultErrorMessage = 'Something went wrong, please try again later';
export const credentialsErrorMessage = 'Invalid credentials';
export const nextButton = 'Next';
export const logInButton = 'Log In';
export const resetButton = 'Reset password';
export const email = 'Email';
export const password = 'Password';
export const back = 'Back';
export const forgotPassword = 'Forgot Password?';
export const checkInbox = 'Check your inbox';
export const emailUnrecognized = 'Email Not Recognized';
export const emailResult = {
    success: 'Please check your inbox for further instructions.',
    unrecognized: "Please make sure to log in with a valid email address. If you believe there's as error - please",
    contactSupport: 'contact support',
};
