import React from 'react';

import Fade from '@mui/material/Fade';

import style from './ExplorerBlocker.module.scss';

interface ExplorerBlockerProps {
    show: boolean;
}

const ExplorerBlocker = (props: ExplorerBlockerProps) => {
    const { show } = props;

    return (
        <Fade in={show}>
            <div className={style.box}></div>
        </Fade>
    );
};

export default ExplorerBlocker;
