import { ApiClient } from '.';

import axios from 'axios';

/**
 * Add auto-cancel to an API request
 * @returns
 */
export default function withCancel() {
    return function (_target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        const oldFunc = descriptor.value;
        const safeKey = String(key);
        descriptor.value = async function (...args: any[]) {
            const abortControllersMap = (this as ApiClient).abortControllersMap;
            if (abortControllersMap.has(safeKey)) {
                abortControllersMap.get(safeKey)?.abort();
            }

            try {
                // Setting the new abort controller
                const abortController = new AbortController();
                abortControllersMap.set(safeKey, abortController);
                const result = await oldFunc.apply(this, [...args, abortController]);
                abortControllersMap.delete(safeKey);
                return result;
            } catch (e) {
                if (axios.isCancel(e)) {
                    console.warn(`Request for the following method was canceled: ${safeKey}`);
                    return;
                }
                throw e;
            }
        };
        return descriptor;
    };
}
