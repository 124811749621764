import React from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Field } from 'formik';

import { LogicalOperator } from '../../../../../types';

interface LogicalOperatorSelectorProps {
    i: number;
}

const LogicalOperatorSelector = (props: LogicalOperatorSelectorProps) => {
    const { i } = props;
    const { t } = useTranslation();

    return (
        <Field as={Select} name="filters_logical_operator" fullWidth disabled={i === 0}>
            {Object.keys(LogicalOperator).map((logicalOpKey) => (
                <MenuItem key={logicalOpKey} value={LogicalOperator[logicalOpKey as keyof typeof LogicalOperator]}>
                    {t(`filters.logicalOperators.${logicalOpKey}`)}
                </MenuItem>
            ))}
        </Field>
    );
};

export default LogicalOperatorSelector;
