import React, { useCallback, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { FronteggProvider } from '@frontegg/react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFlagsStatus, useUnleashClient } from '@unleash/proxy-client-react';
import { debounce } from 'lodash';
import LogRocket from 'logrocket';

import AuthProvider from './components/AuthProvider';
import ErrorBoundary from './components/ErrorBoundary';
import PreloadingAnimation from './components/PreloadingAnimation';
import PrivateRoute from './components/PrivateRoute';
import UnleashUserContextSupervisor from './components/UnleashUserContextSupervisor';
import { basenameForRoutes, routes } from './components/consts';
import './i18n';
import DashboardPage from './routes/DashboardPage';
import ForbiddenPage from './routes/ForbiddenPage';
import NoMatchPage from './routes/NoMatchPage';
import NoViewsPage from './routes/NoViewsPage';
import { accessMosaicRoles } from './routes/roles';
import { MosaicStoreContextProvider } from './stores/MosaicStore';
import theme from './theme';


const contextOptions = {
    baseUrl: process.env.REACT_APP_FRONTEGG_BASE_URL || 'https://auth-dev.ask-ai.co',
    clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID || '9857bef1-5217-40d4-9a65-fb2befc57f01',
};
const authOptions = {
    keepSessionAlive: true,
};

const App = () => {
    const [isFronteggLoading, setIsFronteggLoading] = useState(true);
    const [isUnleashFailed, setIsUnleashFailed] = useState(false);
    const [isAuthProviderLoading, setIsAuthProviderLoading] = useState(true);
    const { flagsReady, flagsError } = useFlagsStatus();
    const client = useUnleashClient();

    client.on('error', (err: any) => {
        LogRocket.captureException(err);
        console.error('Unleash client error', err);
        setIsUnleashFailed(true);
    });
    client.on('ready', () => {
        if (isUnleashFailed) {
            setIsUnleashFailed(false);
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnHandelCompleted = useCallback(
        debounce(() => {
            setIsAuthProviderLoading(false);
        }, 10),
        [setIsAuthProviderLoading],
    );

    // Unleash is ready when flags are ready or when there is an error
    const isUnleashReady = flagsReady || flagsError || isUnleashFailed;

    const showLoadingState = isFronteggLoading || isAuthProviderLoading || !isUnleashReady;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CssBaseline />
                    <ErrorBoundary>
                        <BrowserRouter basename={basenameForRoutes}>
                            <FronteggProvider
                                contextOptions={contextOptions}
                                authOptions={authOptions}
                                hostedLoginBox={true}
                                customLoader={setIsFronteggLoading}
                            >
                                <AuthProvider onHandelCompleted={debouncedOnHandelCompleted}>
                                    <MosaicStoreContextProvider>
                                        <UnleashUserContextSupervisor />
                                        {!showLoadingState && (
                                            <Routes>
                                                <Route
                                                    path={routes.home}
                                                    element={<PrivateRoute roles={accessMosaicRoles} />}
                                                >
                                                    <Route path={routes.home} element={<DashboardPage />} />
                                                </Route>
                                                <Route
                                                    path={routes.noViews}
                                                    element={<PrivateRoute roles={accessMosaicRoles} />}
                                                >
                                                    <Route path={routes.noViews} element={<NoViewsPage />} />
                                                </Route>

                                                <Route path={routes.forbidden} element={<ForbiddenPage />} />
                                                <Route path="*" element={<NoMatchPage />} />
                                            </Routes>
                                        )}
                                    </MosaicStoreContextProvider>
                                </AuthProvider>
                            </FronteggProvider>
                        </BrowserRouter>
                        {showLoadingState && <PreloadingAnimation />}
                    </ErrorBoundary>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
