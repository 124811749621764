import { api } from '@frontegg/rest-api';

/**
 * Try to refresh the user access token
 *
 * @returns
 */
const refreshToken = async (): Promise<string> => {
    const token = await api.auth.silentOAuthRefreshToken();

    return token.accessToken;
};

export default refreshToken;
