import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import logo from '../common/logo.png';

import style from './NoViews.module.scss';

const NoViews = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className={style.logoWrapper}>
                <img src={logo} alt="Logo" width="195" />
                <Typography variant="h4" color="error.main">
                    {t('noViews.title')}
                </Typography>
                <Typography variant="h6" color="error.main">
                    {t('noViews.subTitle')}
                </Typography>
                <Link to="/">{t('noViews.home')}</Link>
            </div>
        </div>
    );
};

export default NoViews;
