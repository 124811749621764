import React from 'react';

import { useAuthUserOrNull, useLoginWithRedirect } from '@frontegg/react';

import api from '../../api';
import eventLogger from '../../eventLogger';
import errorLogger from '../ErrorBoundary/errorLogger';

interface AuthProviderProps {
    onHandelCompleted: () => void;
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const { onHandelCompleted, children } = props;
    const user = useAuthUserOrNull();
    const loginWithRedirect = useLoginWithRedirect();

    const handleAuthedUser = (userId: string, token: string) => {
        eventLogger.setUserId(userId);
        errorLogger.setUserId(userId);
        api.setToken(token);
    };

    if (!user) {
        loginWithRedirect();

        return null;
    }

    handleAuthedUser(user.id, user.accessToken);
    // We're done handling the auth, will notify the parent and render the children
    onHandelCompleted();

    return <>{children}</>;
};

export default AuthProvider;
