import React from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import LightTooltip from '../../../../../../common/LightTooltip';

export type ActionButtonProps = {
    tooltipTitle?: string;
} & IconButtonProps;

export const ActionButton: React.FC<ActionButtonProps> = ({
    tooltipTitle,
    size = 'small',
    children,
    ...iconButtonProps
}) => {
    if (tooltipTitle) {
        return (
            <LightTooltip title={tooltipTitle} arrow placement="top">
                <IconButton size={size} {...iconButtonProps}>
                    {children}
                </IconButton>
            </LightTooltip>
        );
    } else {
        return (
            <IconButton size={size} {...iconButtonProps}>
                {children}
            </IconButton>
        );
    }
};
