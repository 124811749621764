import React from 'react';

import { mixpanelService } from '../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../services/mixpanel/types';
import ErrorBlock from '../Dashboard/ErrorBlock';
import SideMenu from '../Dashboard/SideMenu';
import TopbarSkeleton from '../Dashboard/Topbar/TopbarSkeleton';

import errorLogger from './errorLogger';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        errorLogger.captureException(error, errorInfo);
        this.setState({ hasError: true });
        mixpanelService.track({ event: MixpanelEvent.DISPLAY_NO_DATA_TO_DISPLAY });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div>
                    <TopbarSkeleton />
                    <div style={{ display: 'flex', height: 'calc(100% - 80px)' }}>
                        <ErrorBlock />
                        <SideMenu
                            customerName={undefined}
                            customerLogoUrl={undefined}
                            maxLevel={undefined}
                            groupByFields={undefined}
                            selectedGroupByFields={undefined}
                            changeGroupBy={() => {}}
                            sizeByFields={undefined}
                            selectedSizeByField={undefined}
                            setSizeBy={() => {}}
                            selectedColorByField={undefined}
                            colorByFields={undefined}
                            setSelectedColorByField={() => {}}
                            selectedPalette={undefined}
                            setSelectedPalette={() => {}}
                            currentView={undefined}
                            selectedColorFunction={undefined}
                            setSelectedColorFunction={() => {}}
                            selectedSizeFunction={undefined}
                            setSelectedSizeFunction={() => {}}
                            fieldsMetadata={undefined}
                            initialAppliedFilter={undefined}
                            dataLoading={true}
                            sizeByField={undefined}
                            sizeByFieldName={undefined}
                            sizeByFieldDisplayName={undefined}
                            menuType={undefined}
                            setMenuType={() => {}}
                            resetConfig={() => {}}
                            clusterFieldNamesMapping={undefined}
                            revision={null}
                            filters={[]}
                        />
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
