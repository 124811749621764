import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import formatValue from '../../../../../../helper/formatValue';
import { Field, RecordHeader, RecordHeaderType, WeightedLeafMosaicRecordWithParent } from '../../../../../../types';
import { BaseCard } from '../../Cards/BaseCard/BaseCard';
import style from '../../DetailsPane.module.scss';

import Subheader from './Subheader/Subheader';

import styles from './StatsWidget.module.scss';

interface IStatsWidgetProps {
    item: WeightedLeafMosaicRecordWithParent;
    sizeByField: Field;
    sizeByFieldName: string;
    sizeByFieldDisplayName: string;
}

export const StatsWidget: React.FC<IStatsWidgetProps> = ({
    item,
    sizeByField,
    sizeByFieldName,
    sizeByFieldDisplayName,
}) => {
    const { t } = useTranslation();

    const sizeByVal = +item.cluster.dynamicAttributes[sizeByFieldName];
    const ofTotalHeader: RecordHeader = {
        type: RecordHeaderType.KeyValue,
        values: {
            key: sizeByFieldDisplayName,
            value: formatValue(sizeByVal, sizeByField),
            description: t('detailsPanel.ofTotalDescription'),
        },
    };

    return (
        <BaseCard
            title={
                <Typography variant="h5" gutterBottom component="div" color="darkGray.main" className={style.title}>
                    {item.label}
                </Typography>
            }
            className={styles.wrapper}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="nowrap"
                gap="12px"
                sx={{ marginTop: '10px' }}
            >
                <Subheader header={ofTotalHeader} />
                {item.cluster.headers.flatMap((h) => (
                    <Subheader header={h} key={JSON.stringify(h)} />
                ))}
            </Grid>
        </BaseCard>
    );
};
