export const itemsHeader = 'Example Items';
export const subHeaders = {
    ofTotal: 'of Total',
};
export const ofTotalDescription = '% of all tickets in the mosaic (after filtering)';

export const title = 'Items';
export const totalItems = 'Showing a sample of items';

export const executiveSummaryTitle = 'High level summary';

export const exportToCSVButton = 'Export to CSV';

export const exportToCSVError = 'Something went wrong during the export';

export const ticketsMatches = 'Matches {{clusterTicketsCount}} out of {{viewTicketsCount}} items shown';

export const sortBy = 'Sort by: {{order}}';
export const sortBy_relevance = {
    text: 'Relevance',
    tooltip: 'Sorting cluster items by their relevance to the cluster topic',
};
export const sortBy_date = {
    text: 'Creation time',
    tooltip: 'Sorting cluster items by their creation date',
};
