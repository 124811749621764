import React from 'react';
import { useTranslation } from 'react-i18next';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../../../hooks/root-store';
import { mixpanelService } from '../../../../../../../services/mixpanel/mixpanel-service';
import { MixpanelEvent } from '../../../../../../../services/mixpanel/types';
import { DirectAnswer } from '../../../../../../../types';
import { StyledSnackbar } from '../../../../../../common/StyledSnackbar/StyledSnackbar';

import { ActionButton } from './ActionButton';
import { ReactComponent as CopyIcon } from './icons/copy-icon.svg';

export type CopyActionProps = {
    answer: DirectAnswer;
    questionId: string;
    customerProjectId: string;
};

const anchorOrigin = { vertical: 'bottom' as const, horizontal: 'center' as const };

const copy = async (text: string) => {
    await navigator.clipboard.writeText(text);
};

export const CopyAction = observer(({ answer: directAnswer, questionId, customerProjectId }: CopyActionProps) => {
    const { t } = useTranslation();
    const [message, setMessage] = React.useState<string>('');
    const mosaicStore = useRootStore();
    const { clustersStore } = mosaicStore;

    const title = t('directAnswer.copyAction');
    const successMessage = t('directAnswer.feedbackOnCopy');

    const handleClick = React.useCallback(() => {
        copy(directAnswer.answer).then(() => {
            setMessage(successMessage);

            mixpanelService.track({
                event: MixpanelEvent.DIRECT_ANSWER_COPY,
                meta: {
                    direct_answer: directAnswer.answer,
                    question_id: questionId,
                    customer_project_id: customerProjectId,
                    view_id: toJS(mosaicStore.currentView?.id),
                    cluster_name: clustersStore.selectedClusterName,
                    items_count: clustersStore.selectedClusterItemsCount,
                },
            });
        });
    }, [directAnswer, questionId, customerProjectId, successMessage, mosaicStore, clustersStore.selectedClusterName, clustersStore.selectedClusterItemsCount]);

    return (
        <>
            <ActionButton tooltipTitle={title} onClick={handleClick}>
                <CopyIcon title={title} />
            </ActionButton>
            <StyledSnackbar
                autoHideDuration={2500}
                anchorOrigin={anchorOrigin}
                open={!!message}
                onClose={() => setMessage('')}
                message={message}
            />
        </>
    );
});
