/**
 * Generate SHA256 hash from a given string
 *
 * @param string
 * @returns
 */
const hashSha256 = async (string: string): Promise<string> => {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

export default hashSha256;
