import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { observer } from 'mobx-react-lite';

import api from '../../../api';
import { SKELETON_COLOR } from '../../../consts';
import eventLogger from '../../../eventLogger';
import { mixpanelService } from '../../../services/mixpanel/mixpanel-service';
import { MosaicStoreContext } from '../../../stores/MosaicStore';
import { UserDetails } from '../../../types';
import errorLogger from '../../ErrorBoundary/errorLogger';
import { routes } from '../../consts';

import UserAvatar from './UserAvatar';

import style from './UserAvatarWrapper.module.scss';

const UserAvatarWrapper = observer(() => {
    const mosaicStore = useContext(MosaicStoreContext);
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [criticalError, setCriticalError] = useState<Error>();

    useEffect(() => {
        if (!mosaicStore) {
            return;
        }

        if (mosaicStore.userDetails) {
            setUserDetails(mosaicStore.userDetails);
            return;
        }

        api.getUserDetails()
            .then((data) => {
                mosaicStore.setUserDetails(data);
                eventLogger.setUserExtraDetails(data.user_org, data.name);
                errorLogger.setUserExtraDetails(data.user_org, data.name);
                setUserDetails(data);

                mixpanelService.updateUserProfile(data);
            })
            .catch((e) => setCriticalError(e));
    }, [mosaicStore, mosaicStore?.userDetails]);

    if (criticalError) {
        // If we got 401 - will redirect to login page
        if (axios.isAxiosError(criticalError) && criticalError.response?.status === 401) {
            return <Navigate to={routes.home} />;
        }
        throw criticalError;
    }

    let element = (
        <Skeleton variant="circular" height={40} width={40} animation="wave" sx={{ backgroundColor: SKELETON_COLOR }} />
    );

    if (userDetails) {
        element = <UserAvatar userName={userDetails.name} email={userDetails.email} />;
    }

    return <Box className={style.avatarWrapper}>{element}</Box>;
});

export default UserAvatarWrapper;
