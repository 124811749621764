/**
 * Capitalize word
 *
 * @param str
 * @returns
 */
const capitalizeWord = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Capitalize each word of the string
 *
 * @param str
 * @returns
 */
const capitalizeEachWord = (str: string) => {
    return str.split(' ').map(capitalizeWord).join(' ');
};

export default capitalizeEachWord;
