import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuthUserOrNull } from '@frontegg/react';

import { routes } from './consts';

interface PrivateRouteProps {
    roles: string[];
}

const PrivateRoute: FC<PrivateRouteProps> = ({ roles }) => {
    const user = useAuthUserOrNull();
    if (user) {
        const userHasRole = user.roles.some((userRole) => roles.includes(userRole.key));
        if (!userHasRole) return <Navigate to={routes.forbidden} />;

        return <Outlet />;
    }

    return <Navigate to={routes.home} />;
};

export default PrivateRoute;
