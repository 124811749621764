import React from 'react';

import Box from '@mui/material/Box';
import { useFlag } from '@unleash/proxy-client-react';

import { AppToggle } from '../../../../../clents/unleash/app-toggle-names';
import { CollapseAll } from '../Buttons/CollapseAll/CollapseAll';
import { CSVExportButton } from '../Buttons/Export/CSVExportButton';
import { SortByFilter } from '../Filters/SortBy/SortByFilter';

import styles from './ItemsHeader.module.scss';

export const ItemsHeader: React.FC = () => {
    const isExportAllowed = useFlag(AppToggle.DOWNLOAD_SAMPLE_CSV);
    const isSortingAllowed = useFlag(AppToggle.CLUSTER_ITEMS_SORTING);

    return (
        <div className={styles.wrapper}>
            <CollapseAll />
            <Box className={styles.buttons}>
                {isSortingAllowed && <SortByFilter />}
                {isExportAllowed && <CSVExportButton />}
            </Box>
        </div>
    );
};
